import { AnyAction } from 'redux';
import { bidPlacementActions, pushDataActions } from '../constants';
import { BidError, EditBidState } from '../types/state/BrokerDealerBiddingState';

export const bidPlacementReducer = (state: { [positionId: number]: EditBidState } = {}, action: AnyAction) => {
    switch (action.type) {
        case bidPlacementActions.BID_PLACEMENT_SET_BID_CAN_SUBMIT:
            return {
                ...state,
                [action.payload.positionId]: {
                    ...state[action.payload.positionId],
                    canSubmit: action.payload.canSubmit
                }
            };
        case bidPlacementActions.BID_PLACEMENT_BID_CHANGE:
            return {
                ...state,
                [action.positionId]: {
                    ...state[action.positionId],
                    value: action.bid,
                    pass: false
                }
            };
        case bidPlacementActions.BID_PLACEMENT_AXED_CHANGE:
            return {
                ...state,
                [action.positionId]: {
                    ...state[action.positionId],
                    axed: action.axed
                }
            };
        case bidPlacementActions.BID_PLACEMENT_FINAL_CHANGE:
            return {
                ...state,
                [action.positionId]: {
                    ...state[action.positionId],
                    final: action.final
                }
            };
        case bidPlacementActions.BID_PLACEMENT_PASS_CHANGE:
            return {
                ...state,
                [action.positionId]: {
                    ...state[action.positionId],
                    pass: action.pass,
                    value: undefined,
                    axed: undefined,
                    final: undefined,
                    errors: undefined
                }
            };
        case bidPlacementActions.BID_PLACEMENT_SET_BID_ERRORS: {
            const copy = { ...state };

            action.errors.forEach((e: { positionId: number, errors?: BidError }) => {
                copy[e.positionId].errors = e.errors;
            });

            return copy;
        }
        case bidPlacementActions.REVERT: {
            if(!state[action.payload.positionId]){
                return state;
            }

            return {
                ...state,
                [action.payload.positionId]: {
                    ...state[action.payload.positionId],
                    canSubmit: false,
                    value: undefined,
                    axed: undefined,
                    final: undefined,
                    pass: undefined,
                    errors: undefined
                }
            };
        }
        case bidPlacementActions.REVERT_ALL:
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2:
            return {};
        case bidPlacementActions.COMMISSION_CHANGE:
            return {
                ...state,
                [action.payload.positionId]: {
                    ...state[action.payload.positionId],
                    commission: action.payload.commission,
                    commissionError: action.payload.error
                }
            };

        default:
            return state;
    }
}

