import { pushDataActions as actionTypes } from '../constants';
import { biddingUtils } from '../utils';

export const biddingPushDataReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.PUSH_DATA_QUICK_FEEDBACK:
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, feedback } = action;

            return {
                ...state,
                securities: state.securities && state.securities.map(s => s.id === positionId
                    ? {
                        ...s,
                        bids: s.bids && s.bids.map(b => biddingUtils.getUniqueBidId(b) === biddingUtils.getUniqueBidIdFromFeedback(feedback)
                            ? { ...b, feedback: feedback.feedback, feedbackCreatedUtc: action.feedback.feedbackCreatedUtc }
                            : b
                        )
                    }
                    : s)
            };
        case actionTypes.PUSH_DATA_TRADE: {
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, trade } = action;

            return {
                ...state,
                securities: state.securities.map(s => s.id === positionId
                    ? { ...s, trade, isTradedAway: false }
                    : s
                )
            };
        };
        case actionTypes.PUSH_DATA_TRADED_AWAY: {
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, isTradedAway } = action;
            const editBidState = { ...state.editBid };
            delete editBidState[positionId];

            return {
                ...state,
                editBid: editBidState,
                securities: state.securities.map(s => s.id === positionId
                    ? { ...s, isTradedAway }
                    : s
                )
            };
        };
        case actionTypes.PUSH_DATA_NEW_PX_TALK: {
            if (!shouldHandlePushData(state, action)) return state;

            const { priceTalk } = action;

            return {
                ...state,
                securities: state.securities && state.securities.map(s => s.id === priceTalk.positionId
                    ? {
                        ...s,
                        pxTalks: (s.pxTalks || []).some(p => equals(p, priceTalk))
                            ? priceTalk.isDeleted
                                ? s.pxTalks.filter(p => !equals(p, priceTalk))
                                : s.pxTalks.map(p => equals(p, priceTalk) ? priceTalk : p)
                            : (s.pxTalks || []).concat(priceTalk),
                    }
                    : s)
            };

            function equals(priceTalk1, priceTalk2) { return priceTalk1.company.id === priceTalk2.company.id; }
        };
        default:
            return state;
    }
}

export function shouldHandlePushData(state, action) {
    return (
        state.securities.length &&
        state.securities.some(s => s.id === (action.positionId || action.payload.positionId))
    );
}

