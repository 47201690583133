import { editCompanyActions as actionTypes } from '../constants';
import { AnyAction } from 'redux';
import { EditCompanyState } from '../types/state/EditCompanyState';

const initialState: EditCompanyState = {
    loading: true,
    company: undefined,
    cities: [],
    countries: [],
    states: [],
    salesRepresentatives: [],
    saved: false,
    saving: false
};

export const editCompany = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.EDIT_COMPANY_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload.status
            }
        case actionTypes.COMPANY:
            return {
                ...state,
                company: action.company,
            };
        case actionTypes.COUNTRIES:
            return {
                ...state,
                countries: action.countries || []
            };
        case actionTypes.STATES:
            return {
                ...state,
                states: action.states || []
            };
        case actionTypes.CITIES:
            return {
                ...state,
                cities: action.cities
            };
        case actionTypes.SALES_REPRESENTATIVES:
            return {
                ...state,
                salesRepresentatives: action.salesRepresentatives
            };
        case actionTypes.SAVING:
            return {
                ...state,
                saving: action.isSaving
            };
        case actionTypes.SAVED:
            return {
                ...state,
                saved: true
            };
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};
