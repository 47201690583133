import { getType } from 'typesafe-actions';
import { ActionType } from '../types/ActionType';
import { DealerListFilter } from '../types/state/DealerListState';
import { DealerFilterOptions } from '../components/dealer-list/DealerFilterOptions';
import { dealersActions } from '../actions';
import { newBwicDealersActions } from '../actions/new-bwic-dealers.actions';

export const initialDealerListFilter: DealerListFilter = {
    filterOptions: [
        { value: DealerFilterOptions.Favorite, text: 'Favorite', selected: false },
        { value: DealerFilterOptions.OffPlatform, text: 'Off-platform', selected: false },
        { value: DealerFilterOptions.Platform, text: 'Platform', selected: false },
    ],
    searchTerm: ''
};

export const dealerListFilterReducer = (state: DealerListFilter, action: ActionType<any>): DealerListFilter => {
    switch (action.type) {
        case getType(newBwicDealersActions.updateFilterOptions):
        case getType(dealersActions.updateFilterOptions):
            return {
                ...state,
                filterOptions: state.filterOptions.map(option => {
                    if (action.payload.filterOption.value === option.value) {
                        return {
                            ...option,
                            selected: !option.selected
                        }
                    }
                    return option;
                })
            };
        case getType(newBwicDealersActions.setSearchTerm):
        case getType(dealersActions.setSearchTerm):
            return {
                ...state,
                searchTerm: action.payload.searchTerm
            };
        case getType(newBwicDealersActions.clearFilterOptions):
        case getType(dealersActions.clearFilterOptions):
            return {
                ...state,
                filterOptions: [...initialDealerListFilter.filterOptions]
            };
        default:
            return state;
    }
};
