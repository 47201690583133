import { combineReducers } from 'redux';
import { BwicFilterType } from '../types/filters/FilterState';
import { createFilter } from './filter.reducer';

export const filters = combineReducers({
    portfolio: createFilter(BwicFilterType.Portfolio),
    inventory: createFilter(BwicFilterType.Inventory),
    bdInventory: createFilter(BwicFilterType.BDInventory),
    bwicMonitor: createFilter(BwicFilterType.BwicMonitor),
    bwicMonitorSell: createFilter(BwicFilterType.BwicMonitorSell),
    bwicMonitorBuy: createFilter(BwicFilterType.BwicMonitorBuy)
})
