import { AnyAction } from "redux";
import { getType } from 'typesafe-actions';
import { amrPipelineCommonActions } from '../actions';
import { RequestState } from "../constants/request-state";
import { PipelineCommonState } from '../types/state/PipelineCommonState';

const initialState: PipelineCommonState = {
    userCompany: undefined,
    requestState: RequestState.none,
};

export const amrPipelineCommon = (
    state: PipelineCommonState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case getType(amrPipelineCommonActions.loadUserCompanyRequest):
        case getType(amrPipelineCommonActions.registerUser):
            return {
                ...state,
                requestState: RequestState.request,
            }
        case getType(amrPipelineCommonActions.loadUserCompanyResponse):
            return {
                ...state,
                userCompany: action.payload.userCompany,
                requestState: RequestState.success,
            };
        case getType(amrPipelineCommonActions.loadUserCompanyError):
            return {
                ...state,
                requestState: RequestState.failure,
            }
        case getType(amrPipelineCommonActions.reset):
            return initialState;
        default:
            return state;
    }
};
