import {
    allBWICSActions as actionTypes,
    pushDataActions,
    sellerBiddingActions
} from '../constants';
import { biddingUtils } from '../utils';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { getType } from 'typesafe-actions';
import { pushBwicActions } from '../actions';
import { BwicMonitorSortByOptions } from '../types/state/AllBwicsState';
import { RequestState } from '../constants/request-state';

const pagingInitialState = {
    currentPageNumber: 1,
    pageSize: 50,
    totalRecordNumber: 0
};

const initialState = {
    bwics: [],
    searchInProgress: RequestState.none,
    activeBwicReferenceName: null,
    ...pagingInitialState,
    isNextPageRequesting: false,
    isRequestingExportBwics: false,
    myBwicsOnly: false,
    isAllToAll: false,
    sortBy: BwicMonitorSortByOptions.NewestFirst,
    advancedFilterBlocked: false,
    summary: undefined
};

export function allBWICS(state = initialState, action) {
    switch (action.type) {
        case actionTypes.MY_BWICS: return { ...state, myBwicsOnly: action.myBwicsOnly };
        case actionTypes.ALL_TO_ALL: return { ...state, isAllToAll: action.isAllToAll };
        case actionTypes.BWICS_SEARCH_PROGRESS: return { ...state, searchInProgress: action.status };
        case actionTypes.BWICS_SEARCH_COMPLETE: {
            const currentBwicList = (action.isNextPageRequesting ? state.bwics : []) || [];
            const requestedBwics = action.bwics.map(b => ({ ...b, collapsed: !!state.collapseAll }));

            return {
                ...state,
                bwics: currentBwicList.concat(requestedBwics),
                searchInProgress: RequestState.success,
                currentPageNumber: action.isNextPageRequesting ? state.currentPageNumber + 1 : pagingInitialState.currentPageNumber,
                totalRecordNumber: action.totalRecordNumber,
                summary: action.summary ?? state.summary
            };
        }
        case actionTypes.ALL_BWICS_SET_EXPORTING_STATUS:
            return {
                ...state,
                isRequestingExportBwics: action.payload.status
            };
        case actionTypes.SET_ACTIVE_BWIC:
            return {
                ...state,
                activeBwicReferenceName: action.bwicReferenceName
            };
        case actionTypes.SET_BWIC_COLLAPSED_STATE:
            return {
                ...state,
                bwics: state.bwics.map(b =>
                    b.referenceName === action.bwic.referenceName
                        ? { ...b, collapsed: action.bwic.collapsed }
                        : b)
            };
        case actionTypes.SET_COLLAPSE_ALL:
            return {
                ...state,
                collapseAll: action.collapseAll,
                bwics: state.bwics.map(b => b.collapsed === action.collapseAll
                    ? b
                    : { ...b, collapsed: action.collapseAll })
            };
        case actionTypes.ALL_BWICS_NEXT_PAGE_REQUESTING:
            return {
                ...state,
                isNextPageRequesting: action.isRequesting
            };
        case actionTypes.ALL_BWICS_RESET_PAGING:
            return {
                ...state,
                ...pagingInitialState
            };
        case actionTypes.LIVE_BIDDING_STAGE2_EXPIRED:
            return {
                ...state,
                bwics: [...state.bwics]
            };
        case actionTypes.ADVANCED_FILTER_BLOCKED:
            return {
                ...state,
                advancedFilterBlocked: action.blocked
            };
        case actionTypes.RESET_SUMMARY:
            return {
                ...state,
                summary: undefined
            };
        case actionTypes.RESET: return { ...initialState };
        case sellerBiddingActions.MARK_BIDS_SHOWN:
            if (state.myBwicsOnly &&
                state.bwics &&
                state.bwics.length &&
                state.bwics.some(b => b.referenceName === action.bwicReferenceName)) {
                return {
                    ...state,
                    bwics: state.bwics.map(bwic => bwic.referenceName === action.bwicReferenceName
                        ? {
                            ...bwic,
                            securities: bwic.securities.map(s =>
                                s.id === action.positionId
                                    ? {
                                        ...s,
                                        bids: (s.bids || []).map(b => action.bidList.some(bid => biddingUtils.getUniqueBidId(bid) === biddingUtils.getUniqueBidId(b))
                                            ? { ...b, isNew: false }
                                            : b)
                                    }
                                    : s
                            )
                        }
                        : bwic
                    )
                };
            }

            return state;
        case actionTypes.ADD_POSITION_BIDS:
            return {
                ...state,
                bwics: state.bwics.map(bwic => bwic.referenceName === action.bwicReferenceName
                    ? {
                        ...bwic,
                        securities: bwic.securities.map(s =>
                            s.id === action.positionId
                                ? {
                                    ...s,
                                    bids: action.bids
                                }
                                : s
                        )
                    } : bwic
                )
            }
        case actionTypes.SORTING_CHANGED:
            return {
                ...state,
                sortBy: action.sortBy
            }
        // Push data actions
        case pushDataActions.PUSH_DATA_COLOR_DISTRIBUTION: {
            if (!shouldHandlePushData(state, action, true)) return state;
            const { bwicReferenceName, isColorDistribution } = action;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === bwicReferenceName
                    ? { ...b, isColorDistribution }
                    : b
                )
            };
        }
        case pushDataActions.PUSH_DATA_TRADED_AWAY: {
            if (!shouldHandlePushData(state, action, true)) return state;

            const { bwicReferenceName, positionId, isTradedAway } = action;

            return {
                ...state,
                bwics: state.bwics.map(bwic => bwic.referenceName === bwicReferenceName
                    ? {
                        ...bwic,
                        securities: bwic.securities.map(s => s.id === positionId
                            ? { ...s, isTradedAway, trade: isTradedAway ? null : s.trade }
                            : s
                        )
                    }
                    : bwic)
            };
        }
        case pushDataActions.PUSH_DATA_TRADE: {
            if (!shouldHandlePushData(state, action, true)) return state;

            const { bwicReferenceName, positionId, trade } = action;

            return {
                ...state,
                bwics: state.bwics.map(bwic => bwic.referenceName === bwicReferenceName
                    ? {
                        ...bwic,
                        securities: bwic.securities.map(s => s.id === positionId
                            ? { ...s, isTradedAway: false, trade }
                            : s
                        )
                    }
                    : bwic)
            };
        }
        case pushDataActions.PUSH_DATA_NEW_BID: {
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, bid, bwicReferenceName } = action;
            const newBid = { ...bid, isNew: true };

            return {
                ...state,
                bwics: state.bwics.map(bwic => bwic.referenceName === bwicReferenceName
                    ? {
                        ...bwic,
                        securities: bwic.securities.map(s => {
                            if (s.id === positionId) {
                                return {
                                    ...s,
                                    bids: s.bids?.some(b => biddingUtils.getBidCompanyIdentifier(b) === biddingUtils.getBidCompanyIdentifier(bid))
                                        ? s.bids.map(b => biddingUtils.getBidCompanyIdentifier(b) === biddingUtils.getBidCompanyIdentifier(bid) ? newBid : b)
                                        : (s.bids || []).concat(newBid)
                                }
                            }
                            return s;
                        })
                    }
                    : bwic)
            };
        }
        case pushDataActions.PUSH_DATA_AXED_FINAL_CHANGE: {
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, bidId, bwicReferenceName, axed, final, company } = action;

            return {
                ...state,
                bwics: state.bwics.map(bwic => bwic.referenceName === bwicReferenceName
                    ? {
                        ...bwic,
                        securities: bwic.securities.map(s => s.id === positionId ? {
                            ...s,
                            bids: s.bids?.map(b =>
                                biddingUtils.getUniqueBidId(b) === biddingUtils.getUniqueBidId({ id: bidId, company })
                                    ? { ...b, axed, final, isNew: true } : b)
                        }
                            : s
                        )
                    }
                    : bwic)
            };
        }
        case pushDataActions.PUSH_DATA_NEW_PX_TALK: {
            if (!shouldHandlePushData(state, action, true)) return state;

            const { priceTalk, bwicReferenceName } = action;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === bwicReferenceName
                    ? {
                        ...b,
                        securities: b.securities && b.securities.map(s => s.id === priceTalk.positionId
                            ? {
                                ...s,
                                pxTalks: s.pxTalks && s.pxTalks.some(p => p.company.id === priceTalk.company.id)
                                    ? priceTalk.isDeleted
                                        ? s.pxTalks.filter(p => p.company.id !== priceTalk.company.id)
                                        : s.pxTalks.map(p => p.company.id === priceTalk.company.id ? priceTalk : p)
                                    : (s.pxTalks || []).concat(priceTalk)
                            }
                            : s)
                    }
                    : b
                )
            };
        }
        case pushDataActions.PUSH_DATA_PUBLIC_COLORS: {
            if (!shouldHandlePushData(state, action, true)) return state;

            const { bwicReferenceName, colors } = action;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === bwicReferenceName
                    ? {
                        ...b,
                        securities: b.securities && b.securities.map(s =>
                            colors.some(c => c.positionId === s.id)
                                ? { ...s, color: colors.find(c => c.positionId === s.id).color }
                                : s
                        )
                    }
                    : b
                )
            };
        }
        case pushDataActions.PUSH_DATA_BWIC_STATUS_CHANGE: {
            if (!shouldHandlePushData(state, action, true)) return state;

            const { bwicReferenceName, bwicStatus } = action;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === bwicReferenceName
                    ? { ...b, status: bwicStatus, isColorDistribution: false }
                    : b
                )
            };
        }
        case pushDataActions.PUSH_DATA_QUICK_FEEDBACK: {
            if (!shouldHandlePushData(state, action)) return state;

            const bwics = state.bwics.map(b => b.referenceName === action.bwicReferenceName
                ? {
                    ...b,
                    securities: b.securities && b.securities.map(s => s.id === action.positionId
                        ? {
                            ...s,
                            feedback: action.feedback.feedback,
                            feedbackCreatedUtc: action.feedback.feedbackCreatedUtc,
                            bids: s.bids?.map(b => biddingUtils.getUniqueBidId(b) === biddingUtils.getUniqueBidIdFromFeedback(action.feedback)
                                ? { ...b, feedback: action.feedback.feedback, feedbackCreatedUtc: action.feedback.feedbackCreatedUtc }
                                : b)
                        }
                        : s
                    )
                }
                : b
            );

            return { ...state, bwics };
        }
        case getType(pushBwicActions.bidRequest): {
            if (!shouldHandlePushData(state, action)) return state;

            const bwics = state.bwics.map(b => b.referenceName === action.bwicReferenceName
                ? {
                    ...b,
                    securities: b.securities && b.securities.map(s => s.id === action.positionId
                        ? { ...s, bidRequest: action.bidRequest }
                        : s
                    )
                }
                : b
            );

            return { ...state, bwics };

        }
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_START_STAGE2: {
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === action.bwicReferenceName
                    ? {
                        ...b,
                        // Update process
                        process: {
                            ...b.process,
                            stagedBiddingStatus: OpenBiddingStatus.stage1Ended,
                            jumpBall: b.process.jumpBall == null
                                ? b.process.jumpBall
                                : {
                                    ...b.process.jumpBall,
                                    stage1EndDateUtc: action.stage1EndDateUtc
                                },
                            topX: b.process.topX == null
                                ? b.process.topX
                                : {
                                    ...b.process.topX,
                                    stage1EndDateUtc: action.stage1EndDateUtc
                                }
                        },
                        // Update isStage2Participant flag
                        securities: b.securities?.map(s => {
                            const canBidOnStage2 = action.canDealerBidOnPositions?.find(x => s.id === x.positionId)?.canBidOnStage2 ?? false;

                            return { ...s, isStage2Participant: canBidOnStage2, canBidOnStage2 };
                        })
                    }
                    : b
                )
            };
        }
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2: {
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === action.bwicReferenceName
                    ? {
                        ...b,
                        process: {
                            ...b.process,
                            stagedBiddingStatus: OpenBiddingStatus.improvementRoundEnded,
                        },
                    }
                    : b
                )
            };
        }
        case pushDataActions.PUSH_DATA_OPEN_BIDDING_STAGE2_LEVEL:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                bwics: state.bwics.map(b => b.referenceName === action.bwicReferenceName
                    ? {
                        ...b,
                        securities: b.securities?.map(s => s.id === action.positionId
                            ? {
                                ...s,
                                latestBidSubmission: action.sentDateUtc,
                            }
                            : s)
                    } : b
                )
            };
        // End push data actions
        default:
            return state;
    }
}

function shouldHandlePushData(state, action, allBwicsPushEnabled = false) {
    return (
        (state.myBwicsOnly || allBwicsPushEnabled) &&
        state.bwics &&
        state.bwics.length &&
        state.bwics.some(b => b.referenceName === (action.bwicReferenceName || action.payload.bwicReferenceName))
    );
}
