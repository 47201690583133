import { Action, Reducer } from "redux";
import { filterActions } from 'redux-ignore';
import { user } from '../user/user';

export const filterActionsReducer = (
    reducer: Reducer<any>,
    roles?: string[],
    filter?: (action: Action) => boolean) => {
    return filterActions(
        reducer,
        action => hasRoles(roles) && (filter == null || filter(action))
    );
}

const hasRoles = (roles?: string[]) => (roles && roles.length) ? user.hasRoles(...roles) : true
