import { directBiddingDisclaimerActions } from '../actions/direct-bidding-agreement-disclaimer.actions';
import { RequestState } from '../constants/request-state';
import { ActionType, getType } from 'typesafe-actions';
import { DirectBiddingDisclaimerState } from '../types/state/DirectBiddingDisclaimerState';

const initialState: DirectBiddingDisclaimerState = {
    confirmRequestState: RequestState.none,
    downloadPdfRequestState: RequestState.none,
    visible: false,
    confirmed: undefined,
    blockerId: '',
};

export const directBiddingDisclaimer = (
    state = initialState,
    action: ActionType<typeof directBiddingDisclaimerActions[keyof typeof directBiddingDisclaimerActions]>
): DirectBiddingDisclaimerState => {
    switch (action.type) {
        case getType(directBiddingDisclaimerActions.show):
            return {
                ...state,
                blockerId: action.payload.blockerId,
                confirmed: false,
                visible: true
            };
        case getType(directBiddingDisclaimerActions.hide):
            return {
                ...state,
                confirmed: action.payload.confirmed,
                visible: false
            };
        case getType(directBiddingDisclaimerActions.confirmRequest):
            return {
                ...state,
                confirmRequestState: RequestState.request
            };
        case getType(directBiddingDisclaimerActions.confirmSuccess):
            return {
                ...state,
                confirmRequestState: RequestState.success
            };
        case getType(directBiddingDisclaimerActions.confirmFailure):
            return {
                ...state,
                confirmRequestState: RequestState.failure
            };
        case getType(directBiddingDisclaimerActions.downloadPdfRequest):
            return {
                ...state,
                downloadPdfRequestState: RequestState.request
            };
        case getType(directBiddingDisclaimerActions.downloadPdfSuccess):
            return {
                ...state,
                downloadPdfRequestState: RequestState.success
            };
        case getType(directBiddingDisclaimerActions.downloadPdfFailure):
            return {
                ...state,
                downloadPdfRequestState: RequestState.failure
            };
        case getType(directBiddingDisclaimerActions.reset):
            return {
                ...initialState
            };
        default:
            return state;
    }
};
