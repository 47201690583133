import { getType } from "typesafe-actions";
import { Company } from "../../types/amr-pipeline/models/Company";
import { RequestState } from "../../constants/request-state";
import {
    TPartiesActionType,
    partiesActions,
} from "../../actions/entities/parties.actions";
import { PartiesState } from "../../types/state/entities/PartiesState";

const sortCompany = (a: Company, b: Company) =>
    a.legalName.localeCompare(b.legalName);

export const partiesInitialState: PartiesState = {
    items: undefined,
    requestState: RequestState.none,
};

export function partiesReducer(
    state = partiesInitialState,
    action: TPartiesActionType
): PartiesState {
    switch (action.type) {
        case getType(partiesActions.request):
            return {
                items: undefined,
                requestState: RequestState.request,
            };
        case getType(partiesActions.requestResult):
            const { items, requestState } = action.payload;
            return {
                requestState,
                items: items
                    ? {
                          arrangerCompanies:
                              items.arrangerCompanies.sort(sortCompany),
                          trusteeCompanies:
                              items.trusteeCompanies.sort(sortCompany),
                          collateralManagerCompanies:
                              items.collateralManagerCompanies.sort(
                                  sortCompany
                              ),
                          majorityEquityCompanies:
                              items.majorityEquityCompanies.sort(sortCompany),
                          amrAgentCompanies:
                              items.amrAgentCompanies.sort(sortCompany),
                      }
                    : undefined,
            };
        default:
            return state;
    }
}

