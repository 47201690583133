import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { ContactSalesState } from "../types/state/ContactSalesState";
import { contactSalesActions } from '../actions';

const initialState: ContactSalesState = {
    popupVisible: false,
    isRequestSending: false
}

export const contactSales = (state = initialState, action: AnyAction): ContactSalesState => {
    switch (action.type) {
        case (getType(contactSalesActions.show)):
            return {
                ...state,
                popupVisible: true
            };
        case (getType(contactSalesActions.hide)):
            return {
                ...state,
                popupVisible: false
            };
        case (getType(contactSalesActions.requestSendingFlag)):
            return {
                ...state,
                isRequestSending: action.payload.isSending
            };
        default: return state;
    }
}
