import { getType } from 'typesafe-actions';
import moment from 'moment';
import { RequestState } from '../constants/request-state';
import {
    dashboardInit,
    dashboardInitSuccess,
    dashboardInitFailure,
    dashboardFetchBwics,
    dashboardFetchPortfolioExist,
    dashboardFetchPortfolioSecurities,
    dashboardFetchColorRates,
    dashboardFetchDntRates,
    dashboardFetchSameDayPeriods,
    dashboardFetchTradingStats,
    dashboardFetchBidRequestsAsSeller,
    dashboardSetFilter,
    dashboardSetFilterActive,
    dashboardSetNotAppliedFilter,
    dashboardResetNotAppliedFilter,
    dashboardReset,
    dashboardSetSearchTermItem,
    dashboardFetchSecurityHistoryRequest,
    dashboardFetchSecurityHistorySuccess,
    dashboardFetchSecurityHistoryFailure,
    dashboardSetRequestStatusExportTradingStats,
    dashboardPushUpdateTradingStats,
    dashboardPushUpdateMostTradedTickers,
    dashboardFetchPriceLevel,
    dashboardPushUpdatePriceLevel,
    dashboardFetchDtcSellingBuying,
    dashboardFetchDtcIgHy,
    dashboardFetchAAAPriceLevel,
    dashboardFetchMostTradedTickers,
    dashboardFetchBiggestMovers,
    dashboardFetchPxTalkDeviation,
    dashboardFetchBwicPercentOfTrace,
    dashboardFetchKWatchNews,
    dashboardFetchTopIssuersResult,
    dashboardFetchTopArrangersResult,
    dashboardFetchTopTrusteesResult,
    dashboardFetchTopStaticDealsResult,
    dashboardFetchTopDebutDealsResult,
    dashboardPushBiggestMovers,
    dashboardClearFilter,
    dashboardIssueTransactionVolumeResult,
    dashboardFetchKIndicator,
    dashboardUsBslEsgNewIssueResult,
    dashboardUsBslEuCompliantDealsResult,
    dashboardGetPortfolioInventorySecuritiesSuccess,
    dashboardSetPortfolioInventoryCurrentPage,
    dashboardGetPortfolioInventorySecuritiesRequest,
    dashboardGetPortfolioInventorySecuritiesFailure,
    dashboardPortfolioInventoryReset,
    dashboardPortfolioInventorySortParamsChanged,
    dashboardShortLongDatedDealsResult,
    dashboardIssuanceSpreadResult,
    dashboardNewIssueWeeklySpreadsResult,
    dashboardSetRequestStatusExportTopIssuers,
    dashboardSetRequestStatusExportTopArrangers,
    dashboardSetRequestStatusExportTopTrustees,
    dashboardSetRequestStatusExportTopStaticDeals,
    dashboardSetRequestStatusExportTopDebutDeals,
} from '../actions/dashboard.actions';
import { BidRequestStatus } from '../types/bid-as-dealer/BidRequestStatus';
import { numericUtils } from '../utils/numeric.utils';
import { ratingWithGroupValues } from '../types/dashboard/RatingGroup';
import { Currencies } from '../types/enums/Currency';
import { BwicStatus } from '../types/enums/BwicStatus';
import { ShortOrLongDated } from '../types/amr-pipeline/enums/ShortOrLongDated';

const initialFilterState = {
    selectedRatings: [],
    selectedCurrencies: [],
};

const initialState = {
    isMounted: false,
    bwics: [],
    myBwics: [],
    portfolioExist: false,
    portfolioSecurities: [],
    portfolioInventory: {
        currentPage: 1,
        totalRecordNumber: 0,
        requestStateGetSecurities: RequestState.none,
        securities: {},
        sortField: 'lastUpdateDate',
        sortOrder: 'desc',
    },
    filter: { ...initialFilterState },
    notAppliedFilter: { ...initialFilterState },
    filterActive: false,
    selectedSecurityHistory: [],
    searchTermItem: '',
    colorRates: [],
    dntRates: [],
    sameDayPeriods: [],
    tradingStats: {
        periodName: '',
        tradingStatsData: []
    },
    priceLevel: {
        periodName: '',
        priceLevelData: []
    },
    bidAsDealerRequests: [],
    dtcSellingBuying: [],
    aaaPriceLevel: [],
    dtcIgHy: [],
    mostTradedTickers: {
        mostTradedTickers: [],
        name: '',
        startDate: new Date(),
        endDate: new Date()
    },
    biggestMovers: {
        details: [],
        name: '',
        startDate: new Date(),
        endDate: new Date()
    },
    pxTalkDeviation: [],
    bwicPercentOfTrace: [],
    kWatchNews: [],
    topIssuers: [],
    topArrangers: [],
    topTrustees: [],
    topStaticDeals: [],
    topDebutDeals: [],
    issueTransactionVolume: [],
    usBslEsgNewIssue: {
        esgLanguageDeals: [],
        usBslNewIssue: [],
    },
    usBslEuCompliantDeals: {
        usBslEuCompliantDeals: [],
        usBslDeals: [],
    },
    shortLongDatedDeals: [],
    issuanceSpread: {
        issuanceSpreads: [],
        median: 0
    },
    kIndicator: [],
    usBslNewIssueSpreads: [],
    requestStateInitDashboard: RequestState.none,
    requestStateFetchPortfolioExist: RequestState.none,
    requestStateFetchPortfolioSecurities: RequestState.none,
    requestStateFetchColorRates: RequestState.none,
    requestStateFetchDntRates: RequestState.none,
    requestStateFetchSameDayPeriods: RequestState.none,
    requestStateFetchTradingStats: RequestState.none,
    requestStateFetchSecurityHistory: RequestState.none,
    requestStateFetchPriceLevel: RequestState.none,
    requestStateFetchPxTalkDeviation: RequestState.none,
    requestStateFetchBwicPercentOfTrace: RequestState.none,
    requestStateFetchKWatchNews: RequestState.none,
    requestStateFetchAAAPriceLevel: RequestState.none,
    requestStateBidAsDealerRequests: RequestState.none,
    requestStateDtcSellingBuying: RequestState.none,
    requestStateMostTradedTickers: RequestState.none,
    requestStateFetchBiggestMovers: RequestState.none,
    requestStateExportTradingStats: false,
    requestStateDtcInvestmentGrade: RequestState.none,
    requestStateFetchTopIssuers: RequestState.none,
    requestStateFetchTopArrangers: RequestState.none,
    requestStateFetchTopTrustees: RequestState.none,
    requestStateFetchTopStaticDeals: RequestState.none,
    requestStateFetchTopDebutDeals: RequestState.none,
    requestStateFetchKIndicator: RequestState.none,
    requestStateIssueTransactionVolume: RequestState.none,
    requestStateUsBslEsgNewIssue: RequestState.none,
    requestStateUsBslEuCompliantDeals: RequestState.none,
    requestStateShortLongDatedDeals: RequestState.none,
    requestStateIssuanceSpread: RequestState.none,
    requestStateUsBslNewIssueSpreads: RequestState.none,
    requestStateExportTopIssuers: RequestState.none,
    requestStateExportTopArrangers: RequestState.none,
    requestStateExportTopTrustees: RequestState.none,
    requestStateExportStaticDeals: RequestState.none,
    requestStateExportDebutManagers: RequestState.none,
    requestStateFetchBwics: RequestState.none
};

function getSortedTopCompanyData(data) {
    if(!data) {
        return null;
    }
    return data.sort(
        (a, b) =>
            b.numberOfTransactions - a.numberOfTransactions || a.company.legalName.localeCompare(b.company.legalName)
    )
}

export function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case getType(dashboardInit):
            return {
                ...state,
                requestStateInitDashboard: RequestState.request,
                isMounted: true,
            };
        case getType(dashboardInitSuccess):
            return {
                ...state,
                requestStateInitDashboard: RequestState.success,
            };
        case getType(dashboardInitFailure):
            return {
                ...state,
                requestStateInitDashboard: RequestState.failure
            };
        case getType(dashboardGetPortfolioInventorySecuritiesRequest):
            return {
                ...state,
                portfolioInventory: {
                    ...state.portfolioInventory,
                    requestStateGetSecurities: RequestState.request
                }
            }
        case getType(dashboardGetPortfolioInventorySecuritiesSuccess):
            return {
                ...state,
                portfolioInventory: {
                    ...state.portfolioInventory,
                    totalRecordNumber: action.payload.totalRecordNumber,
                    requestStateGetSecurities: RequestState.success,
                    securities: {
                        ...state.portfolioInventory.securities,
                        [action.payload.pageNumber]: action.payload.securities
                    }
                },
            }
        case getType(dashboardGetPortfolioInventorySecuritiesFailure):
            return {
                ...state,
                portfolioInventory: {
                    ...state.portfolioInventory,
                    requestStateGetSecurities: RequestState.failure
                }
            }
        case getType(dashboardSetPortfolioInventoryCurrentPage):
            return {
                ...state,
                portfolioInventory: {
                    ...state.portfolioInventory,
                    currentPage: action.payload.currentPage
                }
            }
        case getType(dashboardPortfolioInventorySortParamsChanged):
            return {
                ...state,
                portfolioInventory: {
                    ...state.portfolioInventory,
                    sortField: action.payload.sortField,
                    sortOrder: action.payload.sortOrder,
                    securities: {},
                }
            }
        case getType(dashboardPortfolioInventoryReset):
            return {
                ...state,
                portfolioInventory: { ...initialState.portfolioInventory }
            }
        case getType(dashboardFetchBwics): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                bwics: data ? filterCanceledBwics(data) : state.bwics,
                myBwics: data ? getMyBwics(data) : state.bwics,
                requestStateFetchBwics: requestState
            };
        }
        case getType(dashboardFetchPortfolioExist):
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchPortfolioExist: requestState,
                portfolioExist: data || false,
            };
        case getType(dashboardFetchPortfolioSecurities): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchPortfolioSecurities: requestState,
                portfolioSecurities: data ? filterCanceledPortfolioSecurities(data) : state.portfolioSecurities
            };
        }
        case getType(dashboardFetchColorRates): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchColorRates: requestState,
                colorRates: data || state.colorRates
            };
        }
        case getType(dashboardFetchDntRates): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchDntRates: requestState,
                dntRates: data || state.dntRates
            };
        }
        case getType(dashboardFetchSameDayPeriods): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchSameDayPeriods: requestState,
                sameDayPeriods: data || state.sameDayPeriods
            };
        }
        case getType(dashboardFetchTradingStats): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchTradingStats: requestState,
                tradingStats: data || state.tradingStats
            };
        }
        case getType(dashboardFetchPriceLevel): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchPriceLevel: requestState,
                priceLevel: data || state.priceLevel
            };
        }
        case getType(dashboardFetchBidRequestsAsSeller): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                bidAsDealerRequests: processingBidAsDealerRequests(state.bidAsDealerRequests, data?.result || []),
                requestStateBidAsDealerRequests: requestState,
            };
        }
        case getType(dashboardFetchDtcSellingBuying): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                dtcSellingBuying: data || state.dtcSellingBuying,
                requestStateDtcSellingBuying: requestState
            };
        }

        case getType(dashboardFetchAAAPriceLevel): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                aaaPriceLevel: data || state.aaaPriceLevel,
                requestStateFetchAAAPriceLevel: requestState
            }
        }
        case getType(dashboardFetchMostTradedTickers): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                mostTradedTickers: data || state.mostTradedTickers,
                requestStateMostTradedTickers: requestState,
            };
        }
        case getType(dashboardFetchBiggestMovers): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                biggestMovers: data ? biggestMoversCalculations(data) : state.biggestMovers,
                requestStateFetchBiggestMovers: requestState,
            };
        }
        case getType(dashboardFetchKIndicator): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                kIndicator: data || state.kIndicator,
                requestStateFetchKIndicator: requestState
            }
        }
        case getType(dashboardPushBiggestMovers):
            return {
                ...state,
                biggestMovers: biggestMoversCalculations(action.payload.biggestMovers)
            };
        case getType(dashboardFetchDtcIgHy): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                dtcIgHy: data || state.dtcIgHy,
                requestStateDtcInvestmentGrade: requestState
            };
        }
        case getType(dashboardFetchPxTalkDeviation): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchPxTalkDeviation: requestState,
                pxTalkDeviation: data || state.pxTalkDeviation
            };
        }
        case getType(dashboardFetchBwicPercentOfTrace): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchBwicPercentOfTrace: requestState,
                bwicPercentOfTrace: data || state.bwicPercentOfTrace
            };
        }
        case getType(dashboardFetchKWatchNews): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchKWatchNews: requestState,
                kWatchNews: data || state.kWatchNews
            };
        }
        case getType(dashboardFetchTopIssuersResult): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchTopIssuers: requestState,
                topIssuers: getSortedTopCompanyData(data) || state.topIssuers,
            };
        }
        case getType(dashboardSetRequestStatusExportTopIssuers): {
            return {
                ...state,
                requestStateExportTopIssuers: action.payload.requestState,
            }
        }
        case getType(dashboardFetchTopArrangersResult): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchTopArrangers: requestState,
                topArrangers: getSortedTopCompanyData(data) || state.topArrangers,
            };
        }
        case getType(dashboardSetRequestStatusExportTopArrangers): {
            return {
                ...state,
                requestStateExportTopArrangers: action.payload.requestState,
            }
        }
        case getType(dashboardFetchTopTrusteesResult): {
            const { data, requestState } = action.payload;
            return {
                ...state,
                requestStateFetchTopTrustees: requestState,
                topTrustees: getSortedTopCompanyData(data) || state.topTrustees,
            };
        }
        case getType(dashboardSetRequestStatusExportTopTrustees): {
            return {
                ...state,
                requestStateExportTopTrustees: action.payload.requestState,
            }
        }
        case getType(dashboardFetchTopStaticDealsResult): {
            const { data, requestState } = action.payload;
            const topStaticDeals = data
                ? data.sort((a, b) => a.dealLegalName.localeCompare(b.dealLegalName))
                : state.topStaticDeals;

            return {
                ...state,
                requestStateFetchTopStaticDeals: requestState,
                topStaticDeals
            };
        }
        case getType(dashboardSetRequestStatusExportTopStaticDeals): {
            return {
                ...state,
                requestStateExportStaticDeals: action.payload.requestState,
            }
        }
        case getType(dashboardFetchTopDebutDealsResult): {
            const { data, requestState } = action.payload;
            const topDebutDeals = data
                ? data.sort((a, b) => a.dealLegalName.localeCompare(b.dealLegalName))
                : state.topDebutDeals;

            return {
                ...state,
                requestStateFetchTopDebutDeals: requestState,
                topDebutDeals,
            };
        }
        case getType(dashboardSetRequestStatusExportTopDebutDeals): {
            return {
                ...state,
                requestStateExportDebutManagers: action.payload.requestState,
            }
        }
        case getType(dashboardPushUpdatePriceLevel): {
            return {
                ...state,
                priceLevel: action.payload.priceLevel
            };
        }
        case getType(dashboardPushUpdateMostTradedTickers): {
            return {
                ...state,
                mostTradedTickers: action.payload.mostTradedTickers
            };
        }
        case getType(dashboardPushUpdateTradingStats):
            return {
                ...state,
                tradingStats: action.payload.tradingStats
            };
        case getType(dashboardSetFilter):
            return {
                ...state,
                filter: {
                    ...action.payload.filter,
                    selectedRatings: action.payload.filter.selectedRatings
                        .sort((a, b) => (ratingWithGroupValues.indexOf(a) - ratingWithGroupValues.indexOf(b))),
                    selectedCurrencies: action.payload.filter.selectedCurrencies
                        .sort((a, b) => Currencies.indexOf(a) - Currencies.indexOf(b))
                },
                filterActive: false
            };
        case getType(dashboardSetNotAppliedFilter):
            return {
                ...state,
                notAppliedFilter: action.payload.filter
            };
        case getType(dashboardResetNotAppliedFilter):
            return {
                ...state,
                notAppliedFilter: { ...state.filter },
                filterActive: false
            }
        case getType(dashboardClearFilter):
            return {
                ...state,
                filter: {...initialFilterState},
                notAppliedFilter: {...initialFilterState},
                filterActive: false
            };
        case getType(dashboardSetFilterActive):
            return {
                ...state,
                filterActive: action.payload.active
            };
        case getType(dashboardSetSearchTermItem):
            return {
                ...state,
                searchTermItem: action.payload.text
            };
        case getType(dashboardFetchSecurityHistoryRequest):
            return {
                ...state,
                requestStateFetchSecurityHistory: RequestState.request
            };
        case getType(dashboardFetchSecurityHistorySuccess):
            return {
                ...state,
                selectedSecurityHistory: action.payload.securityHistory,
                requestStateFetchSecurityHistory: RequestState.success
            };
        case getType(dashboardFetchSecurityHistoryFailure):
            return {
                ...state,
                requestStateFetchSecurityHistory: RequestState.failure
            };
        case getType(dashboardSetRequestStatusExportTradingStats):
            return {
                ...state,
                requestStateExportTradingStats: action.payload.status
            };
        case getType(dashboardIssueTransactionVolumeResult): {
            const { data, requestState } = action.payload;

            return {
                ...state,
                issueTransactionVolume: data,
                requestStateIssueTransactionVolume: requestState
            };
        }
        case getType(dashboardUsBslEsgNewIssueResult): {
            const { requestState, esgLanguageDeals, usBslNewIssue } = action.payload;

            return {
                ...state,
                usBslEsgNewIssue: {
                    ...state.usBslEsgNewIssue,
                    esgLanguageDeals: esgLanguageDeals
                        ? esgLanguageDeals.sort(compareVolumeCountRows)
                        : esgLanguageDeals,
                    usBslNewIssue: usBslNewIssue
                        ? usBslNewIssue.sort(compareVolumeCountRows)
                        : usBslNewIssue,
                },
                requestStateUsBslEsgNewIssue: requestState
            };
        }
        case getType(dashboardUsBslEuCompliantDealsResult): {
            const { requestState, usBslEuCompliantDeals, usBslDeals } = action.payload;

            return {
                ...state,
                usBslEuCompliantDeals: {
                    ...state.usBslEuCompliantDeals,
                    usBslEuCompliantDeals,
                    usBslDeals,
                },
                requestStateUsBslEuCompliantDeals: requestState
            };
        }
        case getType(dashboardShortLongDatedDealsResult): {
            const { requestState, data } = action.payload;

            if (!data) {
                return {
                    ...state,
                    requestStateShortLongDatedDeals: requestState,
                };
            }

            const shortLongDatedDeals = data.filter(({ shortOrLong }) => shortOrLong !== ShortOrLongDated.None);

            return {
                ...state,
                shortLongDatedDeals,
                requestStateShortLongDatedDeals: requestState
            };
        }
        case getType(dashboardIssuanceSpreadResult): {
            const { requestState, data } = action.payload;

            if (!data) {
                return {
                    ...state,
                    requestStateIssuanceSpread: requestState,
                };
            }

            return {
                ...state,
                issuanceSpread: data,
                requestStateIssuanceSpread: requestState
            };
        }
        case getType(dashboardNewIssueWeeklySpreadsResult): {
            const { requestState, data } = action.payload;

            if (!data) {
                return {
                    ...state,
                    requestStateUsBslNewIssueSpreads: requestState,
                };
            }

            return {
                ...state,
                usBslNewIssueSpreads: data,
                requestStateUsBslNewIssueSpreads: RequestState.success,
            };
        }
        case getType(dashboardReset):
            return initialState;
        default:
            return state;
    }
}

function filterCanceledBwics(bwics) {
    return bwics.filter((b) =>
        b.status === BwicStatus.scheduled
        || b.status === BwicStatus.bidding
        || b.status === BwicStatus.finished);
}

function getMyBwics(bwics) {
    return bwics.filter((b) => b.isMy || b.isParticipant);
}

function filterCanceledPortfolioSecurities(portfolios) {
    return portfolios.filter((s) => s.status !== BwicStatus.cancelled);
}

function processingBidAsDealerRequests(stateBidAsDealerRequests, newBidAsDealerRequests) {
    const filtered = newBidAsDealerRequests.filter(r => r.bidAsDealerRequest?.status !== BidRequestStatus.canceled) || stateBidAsDealerRequests;
    return filtered.map(r => ({ ...r, isinCusip: r.position.isinCusip, ticker: r.position.ticker }));
}

function biggestMoversCalculations(biggestMovers) {
    return {
        ...biggestMovers,
        details: biggestMovers.details.map(data => {
            const absDifference = Math.abs(data.difference);
            const daysBetweenTrade = (data.lastDate && data.prevDate)
                ? moment(data.lastDate).diff(moment(data.prevDate), 'days')
                : 0;
            return {
                ...data,
                absDifference,
                daysBetweenTrade,
                priceVelocity: numericUtils.divideSafe(data.difference, daysBetweenTrade) || 0
            };
        })
    };
}

function compareVolumeCountRows(rowA, rowB) {
    const rowADate = moment([rowA.year, rowA.month - 1]);
    const rowBDate = moment([rowB.year, rowB.month - 1]);

    return rowADate.unix() - rowBDate.unix();
}
