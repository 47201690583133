import { combineReducers } from "redux";
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { amrArrangerPipeline } from "./amr-arranger-pipeline.reducer";
import { createAmrDealsWithNamedData } from './amr-deals.reducer';
import { createSearchTransactionsNamedData } from './search-transactions.reducer';
import { createFilter } from "./filter.reducer";

export const createAmrPipelineWithNamedData = (pipelineType: PipelineType) => combineReducers({
    deals: pipelineType !== PipelineType.ArrangerPipeline ? createAmrDealsWithNamedData(pipelineType) : amrArrangerPipeline,
    filter: createFilter(pipelineType),
    searchTransactions: createSearchTransactionsNamedData(pipelineType),
});
