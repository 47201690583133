import { ActionType, getType } from "typesafe-actions";
import { apiOperationActions } from "../actions";
import { RequestState } from "../constants/request-state";
import { ApiOperation } from "../types/api-operation/ApiOperation";
import { ApiOperationResult } from "../types/api-operation/ApiOperationResult";
import { ApiOperationState } from "../types/state/ApiOperationState";

const initialState: ApiOperationState = {
    requests: [],
    responses: []
}

export function apiOperation(
    state = initialState, 
    action: ActionType<typeof apiOperationActions[keyof typeof apiOperationActions]>): ApiOperationState {
    switch (action.type) {
        case getType(apiOperationActions.waitResult):
            return {
                ...state,
                requests: state.requests.concat(...action.payload.requests)
            };
        case getType(apiOperationActions.result):
            return {
                ...state,
                responses: state.responses.concat(action.payload.result)
            }
        case getType(apiOperationActions.success):
            return {
                ...state,
                requests: updateOperationState(state.requests, action.payload.result, RequestState.success),
                responses: state.responses.filter(r => r.token !== action.payload.result.token)
            };
        case getType(apiOperationActions.error):
            return {
                ...state,
                requests: updateOperationState(state.requests, action.payload.result, RequestState.failure),
                responses: state.responses.filter(r => r.token !== action.payload.result.token)
            };
        case getType(apiOperationActions.remove):
            return {
                ...state,
                requests: state.requests.filter(r => r.token !== action.payload.token),
                responses: state.responses.filter(r => r.token !== action.payload.token)
            };
        case getType(apiOperationActions.resetEvent): 
            return {
                ...state,
                requests: state.requests.filter(r => r.event !== action.payload.event),
                responses: state.responses.filter(r => r.event !== action.payload.event)
            };
        case getType(apiOperationActions.reset):
            return { ...initialState };
        default: return state;
    }
}

function updateOperationState(requests: ApiOperation[], result: ApiOperationResult, state: RequestState) {
    return requests.map(r => r.token === result.token && r.event === result.event
        ? { ...r, state, result }
        : r
    );
}
