import { getType, ActionType } from "typesafe-actions";
import { dealerListPanelActions } from "../actions/dealer-list-panel.actions";
import { BidderCompanySlim } from "../types/company/BidderCompanySlim";
import { CompanyHiddenState, DealerListPanelState, DisabledCompaniesState } from "../types/state/DealerListPanelState";
import { apiUtils } from "../utils";

const initialState: DealerListPanelState = {
    panelVisible: false,
    companyHiddenState: {},
    isDefaultVisibilityApplied: false,
    allFavoriteCompaniesVisible: false,
    allPlatformCompaniesVisible: true,
    allOffPlatformCompaniesVisible: false,
    allSettlmentsClientsVisible: false,
    bidOnlyFlag: false,
    isStage2ParticipantsOnly: false,
    disabledCompanies: {},
    lastSelectedCompanyIdentifier: '',
};

type TAction = ActionType<typeof dealerListPanelActions[keyof typeof dealerListPanelActions]>;

export const dealerListPanel = (state = initialState, action: TAction): DealerListPanelState => {
    switch (action.type) {
        case getType(dealerListPanelActions.show):
            return {
                ...state,
                panelVisible: true
            };
        case getType(dealerListPanelActions.hide):
            return {
                ...state,
                panelVisible: false
            };
        case getType(dealerListPanelActions.toggleCompanyVisible):
            return {
                ...state,
                companyHiddenState: {
                    ...state.companyHiddenState,
                    [action.payload.identifier]: !state.companyHiddenState[action.payload.identifier]
                },
                lastSelectedCompanyIdentifier:
                    !action.payload.setLastSelectedCompanyIdentifier ||
                    !state.companyHiddenState[action.payload.identifier] ||
                    state.lastSelectedCompanyIdentifier === action.payload.identifier
                    ? ''
                    : action.payload.identifier
            };
        case getType(dealerListPanelActions.toggleAllFavoriteCompaniesVisible): {
            const visible = !state.allFavoriteCompaniesVisible;
            return {
                ...state,
                allFavoriteCompaniesVisible: visible,
                companyHiddenState: setCompaniesHiddenState(!visible, state.companyHiddenState, action.payload.companies, state.disabledCompanies),
                lastSelectedCompanyIdentifier: ''
            }
        }
        case getType(dealerListPanelActions.toggleAllPlatformVisible): {
            const visible = action.payload.visible ?? !state.allPlatformCompaniesVisible;

            return {
                ...state,
                allPlatformCompaniesVisible: visible,
                companyHiddenState: setCompaniesHiddenState(!visible, state.companyHiddenState, action.payload.companies, state.disabledCompanies),
                lastSelectedCompanyIdentifier: '',
            };
        }
        case getType(dealerListPanelActions.toggleAllSettlmentsClientsVisible): {
            const visible = action.payload.visible ?? !state.allSettlmentsClientsVisible;

            return {
                ...state,
                allSettlmentsClientsVisible: visible,
                companyHiddenState: setCompaniesHiddenState(!visible, state.companyHiddenState, action.payload.companies, state.disabledCompanies),
                lastSelectedCompanyIdentifier: '',
            };
        }
        case getType(dealerListPanelActions.toggleAllOffPlatformVisible): {
            const visible = action.payload.visible ?? !state.allOffPlatformCompaniesVisible;

            return {
                ...state,
                allOffPlatformCompaniesVisible: visible,
                companyHiddenState: setCompaniesHiddenState(!visible, state.companyHiddenState, action.payload.companies, state.disabledCompanies),
                lastSelectedCompanyIdentifier: '',
            };
        }
        case getType(dealerListPanelActions.toggleAllFavoriteVisibleFlagOnly):
            return { ...state, allFavoriteCompaniesVisible: !state.allFavoriteCompaniesVisible };
        case getType(dealerListPanelActions.toggleAllPlatformVisibleFlagOnly):
            return { ...state, allPlatformCompaniesVisible: !state.allPlatformCompaniesVisible };
        case getType(dealerListPanelActions.toggleAllSettlmentsClientsFlagOnly):
            return { ...state, allSettlmentsClientsVisible: !state.allSettlmentsClientsVisible };
        case getType(dealerListPanelActions.toggleAllOffPlatformVisibleFlagOnly):
            return { ...state, allOffPlatformCompaniesVisible: !state.allOffPlatformCompaniesVisible };
        case getType(dealerListPanelActions.setDisabledCompanies): {
            const disabledCompanies = apiUtils.normalize(action.payload.disabledCompanies, c => c.identifier, () => true);
            return { ...state, disabledCompanies }
        }
        case getType(dealerListPanelActions.setDefaultVisibility): {
            return {
                ...state,
                companyHiddenState: setCompaniesHiddenState(
                    true,
                    {},
                    action.payload.companiesToHide,
                    state.disabledCompanies),
                isDefaultVisibilityApplied: true,
            };
        }
        case getType(dealerListPanelActions.reset):
            return { ...initialState };
        case getType(dealerListPanelActions.setBidOnlyFlag):
            return {
                ...state,
                bidOnlyFlag: action.payload.bidOnlyFlag
            }
        case getType(dealerListPanelActions.stage2ParticipantsFlagChange):
            return {
                ...state,
                isStage2ParticipantsOnly: action.payload.isStage2participantsOnly
            }
        default: return state;
    }
}


function setCompaniesHiddenState(
    hidden: boolean,
    state: CompanyHiddenState,
    identifiers: BidderCompanySlim[],
    disabledCompanies: DisabledCompaniesState) {
    const stateCopy = { ...state };
    identifiers.forEach(c => stateCopy[c.identifier] = hidden);
    return { ...stateCopy, ...disabledCompanies };
}
