import { ActionType, getType } from "typesafe-actions";
import { pushBwicActions } from "../actions";
import { bidOnBehalfParseActions } from "../actions/bid-on-behalf-parse.actions";
import { bidOnBehalfActions } from "../actions/bid-on-behalf.actions";
import { BidOnBehalfState, getEditKey, parseKey } from "../types/state/BidOnBehalfState";

const initialState: BidOnBehalfState = {
    isRequesting: false,
    isSubmitting: false,
    companies: [],
    editState: {},
    conflicts: [],
    isConflictsPpopupVisible: false,
    focusedBid: undefined,
}
type TAction =
    ActionType<typeof bidOnBehalfActions[keyof typeof bidOnBehalfActions]> |
    ActionType<typeof pushBwicActions[keyof typeof pushBwicActions]> |
    ActionType<typeof bidOnBehalfParseActions[keyof typeof bidOnBehalfParseActions]>;

export const bidOnBehalf = (state = initialState, action: TAction): BidOnBehalfState => {
    switch (action.type) {
        case getType(bidOnBehalfActions.setCompainesRequesting):
            return {
                ...state,
                isRequesting: action.payload.isRequesting
            };
        case getType(bidOnBehalfActions.submitProgress):
            return {
                ...state,
                isSubmitting: action.payload.inProgress
            };
        case getType(bidOnBehalfActions.storeCompanies):
            return {
                ...state,
                companies: action.payload.companies
            };
        case getType(bidOnBehalfActions.editBid): {
            const key = getEditKey(action.payload.positionId, action.payload.companyId);

            return {
                ...state,
                editState: {
                    ...state.editState,
                    [key]: {
                        ...state.editState[key],
                        editing: !action.payload.editCompleted,
                        value: action.payload.value,
                        error: action.payload.error,
                        pass: undefined
                    }
                }
            };
        }
        case getType(bidOnBehalfActions.resetBidEdit): {
            const key = getEditKey(action.payload.positionId, action.payload.companyId);
            const editState = { ...state.editState };
            delete editState[key];

            return {
                ...state,
                editState
            };
        };
        case getType(bidOnBehalfActions.resetCompanyBidsEdit): {
            const editState = { ...state.editState };
            const companyId: number = action.payload.companyId;

            Object
                .keys(editState)
                .forEach((key: string) => {
                    const parsed = parseKey(key);
                    if (parsed.companyId === companyId) {
                        delete editState[key];
                    }
                })

            return { ...state, editState };
        };
        case getType(bidOnBehalfActions.resetEdit):
            return { ...initialState, companies: state.companies };
        case getType(bidOnBehalfActions.pass): {
            const key = getEditKey(action.payload.positionId, action.payload.companyId);

            return {
                ...state,
                editState: {
                    ...state.editState,
                    [key]: {
                        ...state.editState[key],
                        value: undefined,
                        error: undefined,
                        editing: undefined,
                        axed: false,
                        final: false,
                        pass: true
                    }
                }
            };
        };
        case getType(bidOnBehalfActions.axed): {
            const key = getEditKey(action.payload.positionId, action.payload.companyId);

            return {
                ...state,
                editState: {
                    ...state.editState,
                    [key]: {
                        ...state.editState[key],
                        axed: action.payload.axed
                    }
                }
            };
        };
        case getType(bidOnBehalfActions.final): {
            const key = getEditKey(action.payload.positionId, action.payload.companyId);

            return {
                ...state,
                editState: {
                    ...state.editState,
                    [key]: {
                        ...state.editState[key],
                        final: action.payload.final
                    }
                }
            };
        };
        case getType(bidOnBehalfActions.storeConflicts):
            return {
                ...state,
                conflicts: state.conflicts.concat(action.payload.conflicts)
            };
        case getType(bidOnBehalfActions.resetConflicts):
            return {
                ...state,
                conflicts: []
            };
        case getType(bidOnBehalfActions.conflictsPopupVisible):
            return {
                ...state,
                isConflictsPpopupVisible: action.payload.visible
            };
        case getType(bidOnBehalfActions.setNextBidIdentifiers):
            return {
                ...state,
                focusedBid: {
                    companyId: action.payload.companyId,
                    positionId: action.payload.positionId
                }
            };
        case getType(bidOnBehalfActions.resetNextBidIdentifiers):
            return {
                ...state,
                focusedBid: undefined
            };
        case getType(pushBwicActions.tradeAction): {
            if (state.focusedBid && action.positionId === state.focusedBid.positionId) {
                return {
                    ...state,
                    focusedBid: undefined
                };
            }

            return state;
        }
        case getType(pushBwicActions.stagedBiddingStartStage2):
        case getType(pushBwicActions.stagedBiddingFinishStage2):
            return {
                ...state,
                editState: {},
                focusedBid: undefined
            };
        case getType(bidOnBehalfActions.reset):
            return { ...initialState };

        default: return state;
    }
}
