import { AnyAction } from "redux";
import { getType } from 'typesafe-actions';
import { amrTransactionHistoryActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { PipelineHistoryItem, PipelineHistoryState } from '../types/state/PipelineHistoryState';
import { sortCollateralQualityTests, sortSyndicateContacts } from "../utils/amr-pipeline.utils";

const initialState: PipelineHistoryState = {
    isLoading: true,
    searchTerm: '',
    lastUpdatedFields: {
        isLoading: false,
    },
};

export const amrPipelineHistory = (
    state: PipelineHistoryState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case getType(amrTransactionHistoryActions.transactionHistoryRequest):
            return {
                ...state,
                isLoading: true,
            };
        case getType(amrTransactionHistoryActions.transactionHistoryResponse):
            const { requestState, transactionsHistory, allHistoryClassesList } = action.payload;

            if (requestState === RequestState.failure) {
                return {
                    ...state,
                    isLoading: false,
                };
            }

            return {
                ...state,
                isLoading: false,
                history: transactionsHistory.map((h: PipelineHistoryItem) => ({
                    ...h,
                    transaction: {
                        ...h.transaction,
                        collateralQualityTests: sortCollateralQualityTests(
                            h.transaction.collateralQualityTests
                        ),
                    }
                })),
                allHistoryClassesList
            };
        case getType(amrTransactionHistoryActions.storeTransactionDetais):
            const { version, transactionDetails } = action.payload;
            const { syndicateContacts } = transactionDetails;

            return {
                ...state,
                history: state.history?.map((history) => {
                    if (history.version !== version) {
                        return history;
                    }

                    return {
                        ...history,
                        transaction: {
                            ...history.transaction,
                            ...transactionDetails,
                            syndicateContacts: sortSyndicateContacts(syndicateContacts),
                            isDetailsLoaded: true,
                        },
                    };
                }),
            };
        case getType(amrTransactionHistoryActions.reset): {
            return initialState;
        }
        case getType(amrTransactionHistoryActions.transactionFieldsUpdatedRequest):
            return {
                ...state,
                lastUpdatedFields: {
                    ...state.lastUpdatedFields,
                    isLoading: true,
                },
            };
        case getType(amrTransactionHistoryActions.transactionFieldsUpdatedResponse): {
            const { requestState, lastUpdatedFields } = action.payload;

            if (requestState === RequestState.failure) {
                return state;
            }

            return {
                ...state,
                lastUpdatedFields: {
                    ...state.lastUpdatedFields,
                    ...lastUpdatedFields,
                    isLoading: false,
                },
            };
        }
        case getType(amrTransactionHistoryActions.transactionFieldsUpdatedReset):
            return {
                ...state,
                lastUpdatedFields: {
                    isLoading: false,
                },
            };
        default:
            return state;
    }
};
