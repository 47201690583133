import { RequestState } from '../../constants/request-state';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { TRejectedTradesActionType, rejectedTradeActions } from '../../actions/entities/rejected-trades.actions';
import { getType } from 'typesafe-actions';
import { RejectedTrade } from '../../types/trades/RejectedTrade';

export const rejectedTradesInitialState: EntityListState<RejectedTrade> = {
    items: [],
    requestState: RequestState.none
}

export const rejectedTradesReducer = (state = rejectedTradesInitialState, action: TRejectedTradesActionType) => {
    switch (action.type) {
        case getType(rejectedTradeActions.request):
            return {
                items: [],
                requestState: RequestState.request,
            }
        case getType(rejectedTradeActions.requestResult):
            return {
                items: action.payload.trades,
                requestState: action.payload.requestState,
            }
        case getType(rejectedTradeActions.setTradesAsResolved):
            return {
                ...state,
                items: state.items.filter(i => !(action.payload.tradeIds.includes(i.id)))
            }
        case getType(rejectedTradeActions.pushBuyerRejectTrade):
            return {
                ...state,
                items: [...state.items, action.payload.trade]
            }

        default:
            return state;
    }
}
