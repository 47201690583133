import { getType } from "typesafe-actions";
import { tradeAllocationActions, TradeAllocationActionType } from "../../actions/trade-allocation.actions";
import { RequestState } from "../../constants/request-state";
import { TradeAllocationState } from "../../types/state/entities/TradeAllocationState";

export const tradeAllocationInitialState: TradeAllocationState = {
    items: {}
}

export function tradeAllocationReducer(
    state = tradeAllocationInitialState,
    action: TradeAllocationActionType): TradeAllocationState {
    switch (action.type) {
        case getType(tradeAllocationActions.request):
            return {
                items: {
                    ...state.items,
                    [action.payload.tradeId]: {
                        requestState: RequestState.request,
                        tradeAllocationItems: []
                    }
                }
            };
        case getType(tradeAllocationActions.requestResult):
            return {
                items: {
                    ...state.items,
                    [action.payload.tradeId]: {
                        requestState: action.payload.requestState,
                        tradeAllocationItems: action.payload.tradeAllocationItems
                    }
                }
            };
        case getType(tradeAllocationActions.update):
            return {
                items: {
                    ...state.items,
                    [action.payload.tradeId]: {
                        requestState: RequestState.success,
                        tradeAllocationItems: action.payload.items
                    }
                }
            };
        case getType(tradeAllocationActions.reset):
            return { ...tradeAllocationInitialState };
    }
}
