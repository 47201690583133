import {accountActions} from '../constants';

const initialState = {
    isLoading: false,
    authenticated: false,
    email: '',
    resetToken: '',
    tokenVerified: false
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case accountActions.LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                user: action.user
            };
        case accountActions.LOGOUT:
            return {...initialState};
        case accountActions.LOGIN_FAILURE:
            return {...initialState, error: action.error};
        case accountActions.LOGIN_REFRESH_AUTHENTICATION:
            return {
                ...state,
                authenticated: true
            };
        case accountActions.SETUP_PASSWORD_FAILURE:
            return {...state, error: action.error};
        case accountActions.CHANGE_PASSWORD_FAILURE:
            return {...state, error: action.error};
        case accountActions.SETUP_PASSWORD_INIT_COMPLETE:
            return {
                ...state,
                email: action.email,
                resetToken: action.resetToken,
                error: {},
                tokenVerified: true,
            };
        case accountActions.RESET_PASSWORD_SUCCESS:
        case accountActions.SETUP_PASSWORD_SUCCESS:
            return { ...state, success: action.success };
        case accountActions.RESET_PASSWORD_FAILURE:
            return { ...state, errorMessage: action.errorMessage };
        case accountActions.RESET_PASSWORD_RESET:
        case accountActions.SETUP_PASSWORD_RESET:
        case accountActions.CHANGE_PASSWORD_RESET:
        case accountActions.CHANGE_PHONE_NUMBER_RESET:
        case accountActions.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                email: '',
                resetToken: '',
                tokenVerified: false,
                errorMessage: '',
                error: {}
            };
        case accountActions.LOGIN_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
}
