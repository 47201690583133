import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { amrTransactionAnalyticsActions } from '../actions';
import { constants, amrPipelineDetailedActions as actionTypes, analyticDefaultFilters } from '../constants';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { AmrTransaction } from '../types/amr-pipeline/models/AmrTransaction';
import { Contact } from '../types/amr-pipeline/models/Contact';
import { IOI } from '../types/amr-pipeline/models/IOI';
import { OriginatingTransaction } from '../types/amr-pipeline/models/OriginatingTransaction';
import { PipelineDetailedState } from '../types/state/PipelineDetailedState';
import { formatIoI, sortCollateralQualityTests, sortSyndicateContacts } from '../utils/amr-pipeline.utils';
import { compareFullNames, compareIOI } from '../utils/compare.utils';

const initialState: PipelineDetailedState = {
    loadedTransactionsWithDetails: [],
    isTransactionsRequesting: false,
    isTransactionDetailsRequesting: true,
    isIOIsSubmitting: false,
    hasMoreTransactions: true,
    searchTerm: '',
    offset: 0,
    editIOIs: false,
    investorPopup: {
        isVisible: false,
    },
    analytics: {
        isLoading: true,
        versions: [],
        documentDownloads: [],
        totalDocumentsDownloads: 0,
        recentTransactionViews: 0,
        totalTransactionViews: 0,
        daysInMarket: 0,
        users: [],
        clientsActivity: {
            isLoading: true,
            data: [],
            filter: analyticDefaultFilters,
            numberOfHiddenItems: 0,
        },
        transactionHistoryViews: {
            isLoading: true,
            data: [],
            filter: analyticDefaultFilters,
            numberOfHiddenItems: 0,
        },
    },
};

export const amrPipelineDetailed = (state: PipelineDetailedState = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.TRANSACTIONS_REQUESTING_STATE:
            return {
                ...state,
                isTransactionsRequesting: action.isRequesting,
            };
        case actionTypes.EDIT_IOIS:
            return {
                ...state,
                editIOIs: action.isEdit,
            };
        case actionTypes.TRANSACTION_DETAILS_REQUESTING_STATE:
            return {
                ...state,
                isTransactionDetailsRequesting: action.isRequesting,
            };
        case actionTypes.TRANSACTION_DETAILS_IOIS_SUBMITTING:
            return {
                ...state,
                isIOIsSubmitting: action.isSubmitting,
            };
        case actionTypes.STORE_TRANSACTIONS:
            const transactions = action.transactions.items;

            return {
                ...state,
                transactions: state.transactions
                    ? [
                          ...state.transactions,
                          ...transactions.map((t: AmrTransaction | OriginatingTransaction) =>
                              t.referenceName === state.initialTransaction?.referenceName &&
                              t.dealReferenceName === state.initialTransaction.dealReferenceName
                                  ? state.initialTransaction
                                  : t,
                          ),
                      ]
                    : [...transactions],
                offset: state.offset + transactions.length,
                numberOfHiddenTransactions: action.transactions.numberOfHiddenItems,
                hasMoreTransactions: transactions.length === constants.defaultTransactionsSearchCriteria.count,
            };
        case actionTypes.STORE_INITIAL_TRANSACTION:
            return {
                ...state,
                initialTransaction: {
                    ...action.transaction,
                },
            };
        case actionTypes.SEARCH_TERM_CHANGE:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };
        case actionTypes.STORE_TRANSACTION_DETAILS: {
            return {
                ...state,
                filteredDocuments: null,
                loadedTransactionsWithDetails: [
                    ...state.loadedTransactionsWithDetails,
                    {
                        ...action.transaction,
                        collateralQualityTests: sortCollateralQualityTests(
                            action.transaction.collateralQualityTests
                        ),
                        syndicateContacts: sortSyndicateContacts(
                            action.transaction.syndicateContacts
                        ),
                    }
                ],
            };
        }
        case actionTypes.STORE_IOIS:
            return {
                ...state,
                loadedTransactionsWithDetails: state.loadedTransactionsWithDetails?.map(t =>
                    t.referenceName === action.transactionReferenceName
                        ? {
                              ...t,
                              iois: action.dataItems?.map(formatIoI).sort(compareIOI),
                          }
                        : t,
                ),
                transactions: state.transactions?.map(t =>
                    t.referenceName === action.transactionReferenceName
                        ? {
                              ...t,
                              iois: action.dataItems?.map(formatIoI).sort(compareIOI),
                          }
                        : t,
                ),
                initialTransaction:
                    state.initialTransaction?.referenceName === action.transactionReferenceName
                        ? {
                              ...state.initialTransaction,
                              iois: action.dataItems?.map(formatIoI).sort(compareIOI),
                          }
                        : state.initialTransaction,
            };
        case actionTypes.SET_UNREAD_IOIS_READ:
            return {
                ...state,
                loadedTransactionsWithDetails: state.loadedTransactionsWithDetails?.map(t =>
                    t.referenceName === action.transactionReferenceName
                        ? {
                              ...t,
                              iois: (t as OriginatingTransaction).iois.map((ioi: IOI) => ({ ...ioi, isRead: true })),
                          }
                        : t,
                ),
                initialTransaction:
                    state.initialTransaction?.referenceName === action.transactionReferenceName
                        ? {
                              ...state.initialTransaction,
                              iois: (state.initialTransaction as OriginatingTransaction)?.iois.map((ioi: IOI) => ({
                                  ...ioi,
                                  isRead: true,
                              })),
                          }
                        : state.initialTransaction,
            };
        case actionTypes.STORE_DOCUMENTS_SEARCH_RESULTS:
            return {
                ...state,
                filteredDocuments: action.filteredDocuments,
            };
        case actionTypes.RESET_TRANSACTIONS:
            return {
                ...state,
                offset: 0,
                hasMoreTransactions: true,
                transactions: [],
                numberOfHiddenTransactions: undefined,
            };
        case actionTypes.SHOW_INVESTOR_POPUP:
            let investor;

            if (action.company) {
                investor = { ...action.company };
                (investor.members as Contact[]).sort(compareFullNames);
            }

            return {
                ...state,
                investorPopup: {
                    isVisible: action.isPopupVisible,
                    investor,
                },
            };
        case getType(amrTransactionAnalyticsActions.analyticsInit): {
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    isLoading: true,
                },
            };
        }
        case getType(amrTransactionAnalyticsActions.analyticsInitResponse):
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    ...action.payload,
                    isLoading: false,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        data: action.payload.clientsActivity,
                        isLoading: false,
                    },
                },
            };
        case getType(amrTransactionAnalyticsActions.clientsActivityRequest):
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        isLoading: true,
                    },
                },
            };
        case getType(amrTransactionAnalyticsActions.clientsActivityResponse): {
            const { data } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        data,
                        isLoading: false,
                    },
                },
            };
        }
        case getType(amrTransactionAnalyticsActions.transactionViewHistoryResponse): {
            const { data } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    transactionHistoryViews: {
                        ...state.analytics.transactionHistoryViews,
                        data,
                        isLoading: false,
                    },
                },
            };
        }
        case getType(amrTransactionAnalyticsActions.clientsActivityFilterChange): {
            const filter = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        filter: {
                            ...state.analytics.clientsActivity.filter,
                            ...filter,
                        },
                    },
                },
            };
        }
        case getType(amrTransactionAnalyticsActions.transactionViewHistoryFilterChange): {
            const filter = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    transactionHistoryViews: {
                        ...state.analytics.transactionHistoryViews,
                        filter: {
                            ...state.analytics.transactionHistoryViews.filter,
                            ...filter,
                        },
                    },
                },
            };
        }
        case getType(amrTransactionAnalyticsActions.transactionViewHistoryFilterByBar): {
            const { accessType, date } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    transactionHistoryViews: {
                        ...state.analytics.transactionHistoryViews,
                        filter: {
                            ...initialState.analytics.transactionHistoryViews.filter,
                            tabs: [accessType],
                            dateOption: dateRangeFilterOptions.Custom,
                            date: {
                                dateFrom: date,
                                dateTo: date,
                            }
                        },
                    },
                },
            };
        }
        case getType(amrTransactionAnalyticsActions.reset): {
            return {
                ...state,
                analytics: initialState.analytics,
            };
        }
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};
