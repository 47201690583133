import { getType } from 'typesafe-actions';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { CompanySlim } from '../../types/company/CompanySlim';
import { RequestState } from '../../constants/request-state';
import { TAssetManagerActionType, assetManagerActions } from '../../actions/asset-manager.actions';

export const assetManagerInitialState: EntityListState<CompanySlim> = {
    items: [],
    requestState: RequestState.none
}

export function assetManagerReducer(state = assetManagerInitialState, action: TAssetManagerActionType) {
    switch (action.type) {
        case getType(assetManagerActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(assetManagerActions.requestResult):
            return {
                items: action.payload.companies,
                requestState: action.payload.requestState
            };
        default: return state;
    };
}
