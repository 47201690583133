import { EntityListState } from '../../types/state/entities/EntityListState';
import { RequestState } from '../../constants/request-state';
import { ActionType } from '../../types/ActionType';
import { getType } from 'typesafe-actions';
import { brokerDealerContactListActions } from '../../actions/broker-dealer-contact-list.actions';
import { Contact } from '../../types/company/Contact';

export const brokerDealerContactListInitialState: EntityListState<Contact> = {
    items: [],
    requestState: RequestState.none
};

export function brokerDealerContactListReducer(state = brokerDealerContactListInitialState, action: ActionType<any>) {
    switch (action.type) {
        case getType(brokerDealerContactListActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(brokerDealerContactListActions.requestResult):
            return {
                items: action.payload.result || [],
                requestState: action.payload.requestState
            };
        case getType(brokerDealerContactListActions.pushUpdateList):
            return {
                ...state,
                items: pushUpdateListReducer([...state.items], action.payload.contacts)
            };
        case getType(brokerDealerContactListActions.setContactInvitationSent):
            return {
                ...state,
                items: state.items.map(i => {
                    if (i.id === action.payload.contactId) {
                        i.invitationSent = true;
                        return { ...i }
                    }
                    return i;
                })
            };
        case getType(brokerDealerContactListActions.resetEntities):
            return { ...brokerDealerContactListInitialState };
        default:
            return state;
    }
}

const pushUpdateListReducer = (currentContacts: Contact[], pushContactsList: Contact[]) => {
    pushContactsList.forEach(contact => {
        if (currentContacts.some(cc => cc.id === contact.id)) {
            const contactIndex = currentContacts.findIndex((c) => c.id === contact.id);
            if (contact.deleted) {
                currentContacts.splice(contactIndex, 1);
            } else {
                currentContacts[contactIndex] = contact;
            }
        } else {
            currentContacts.push(contact)
        }
    });
    return currentContacts;
};
