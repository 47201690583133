import { getType } from 'typesafe-actions';
import { Company } from '../../types/amr-pipeline/models/Company';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { RequestState } from '../../constants/request-state';
import { TTrusteeActionType, trusteeActions } from '../../actions/entities/trustee.actions';

export const trusteeInitialState: EntityListState<Company> = {
    items: [],
    requestState: RequestState.none
}

export function trusteeReducer(state = trusteeInitialState, action: TTrusteeActionType): EntityListState<Company> {
    switch (action.type) {
        case getType(trusteeActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(trusteeActions.requestResult):
            return {
                items: action.payload.trustee,
                requestState: action.payload.requestState
            };
        default:
            return state;
    }
}
