import { getType } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { EntityMapState } from "../../types/state/entities/EntityMapState";
import { apiUtils } from "../../utils";
import { ArrangerPipeline } from "../../types/amr-pipeline/models/ArrangerPipeline";
import { arrangersPipelineActions, TArrangersPipelineActionType } from "../../actions/entities/arrangers-pipeline.actions";

export const arrangersPipelineInitialState: EntityMapState<string, ArrangerPipeline> = {
    requestState: RequestState.none,
    items: {}
}

export function arrangersPipelineReducer(
    state = arrangersPipelineInitialState,
    action: TArrangersPipelineActionType): EntityMapState<string, ArrangerPipeline> {
    switch (action.type) {
        case getType(arrangersPipelineActions.request):
            return {
                requestState: RequestState.request,
                items: {}
            }
        case getType(arrangersPipelineActions.response):
            return {
                requestState: action.payload.requestState,
                items: apiUtils.normalize(action.payload.pipeline, t => t.dealTicker?.toLowerCase() ?? '', t => t)
            }
        case getType(arrangersPipelineActions.reset):
            return { ...arrangersPipelineInitialState };
        default: return state;
    }
}
