import { editPortfolioActions as actionTypes } from '../constants';
import { RequestState } from '../constants/request-state';

const initialState = {
    portfolio: {
        name: '',
        securities: [],
        id: 0,
        referenceName: '',
        bwicAlert: false,
        dealersInventoryAlert: false
    },
    renamePortfolioDialog: {
        visible: false
    },
    deletePortfolioDialog: {
        visible: false
    },
    updatePortfolioRequestState: RequestState.none,
    isRequestingExportPortfolio: false,
};

export const editPortfolio = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PORTFOLIO_LOADED:
            return {
                ...state,
                portfolio: action.portfolio
            };
        case actionTypes.PORTFOLIO_SAVED:
            return {
                ...state,
                saved: true
            };
        case actionTypes.RESET:
            return initialState;
        case actionTypes.RENAME_DIALOG_VISIBLE:
            return {
                ...state,
                renamePortfolioDialog: action.dialog
            };
        case actionTypes.DELETE_PORTFOLIO_DIALOG:
            return {
                ...state,
                deletePortfolioDialog: action.dialog
            };
        case actionTypes.PORTFOLIO_DELETED:
            return {
                ...state,
                deleted: true
            };
        case actionTypes.EDIT_PORTFOLIO_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case actionTypes.PORTFOLIO_UPDATE_REQUEST:
            return {
                ...state,
                updatePortfolioRequestState: RequestState.request
            };
        case actionTypes.PORTFOLIO_UPDATE_SUCCESS:
            return {
                ...state,
                updatePortfolioRequestState: RequestState.success
            };
        case actionTypes.PORTFOLIO_UPDATE_FAILURE:
            return {
                ...state,
                updatePortfolioRequestState: RequestState.failure
            };
        case actionTypes.PORTFOLIO_SET_REQUESTING_EXPORT_STATE:
            return {
                ...state,
                isRequestingExportPortfolio: action.payload.status
            };
        default:
            return state;
    }
}
