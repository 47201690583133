import { EntityListState } from '../../types/state/entities/EntityListState';
import { RequestState } from '../../constants/request-state';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { ActionType } from '../../types/ActionType';
import { getType } from 'typesafe-actions';
import { settlementAgentAgreementActions } from '../../actions/settlement-agent-agreements.actions';
import { user } from '../../user';

export const settlementAgentAgreementsInitialState: EntityListState<SettlementAgentAgreement> = {
    items: [],
    requestState: RequestState.none
};

export function settlementAgentAgreementsReducer(state = settlementAgentAgreementsInitialState, action: ActionType<any>) {
    switch (action.type) {
        case getType(settlementAgentAgreementActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(settlementAgentAgreementActions.requestResult):
            return {
                items: action.payload.result || [],
                requestState: action.payload.requestState
            };
        case getType(settlementAgentAgreementActions.pushDataReceived):
            return {
                ...state,
                items: updateAgreementsList([...state.items], action.payload.agreement)
            };
        case getType(settlementAgentAgreementActions.resetEntities):
            return settlementAgentAgreementsInitialState;

        default:
            return state;
    }
}

const updateAgreementsList = (currentAgreements: SettlementAgentAgreement[], agreement: SettlementAgentAgreement) => {
    if (user.current()?.isClearingBank) {
        return currentAgreements;
    }
    if (currentAgreements.some(a => a.settlementAgent.id === agreement.settlementAgent.id)) {
        return currentAgreements.map(a => {
            if (a.settlementAgent.id === agreement.settlementAgent.id) {
                return agreement;
            }
            return a;
        })
    }
    currentAgreements.push(agreement);
    return currentAgreements;
};
