import { getType } from 'typesafe-actions';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { RequestState } from '../../constants/request-state';
import { LiveBiddingPosition } from '../../types/live-bidding/LiveBiddingPosition';
import { TLiveBiddingAction, liveBiddingActions } from '../../actions/entities/live-bidding.actions';

export const liveBiddingInitialState: EntityListState<LiveBiddingPosition> = {
    items: [],
    requestState: RequestState.none // not used
}

export function liveBiddingReducer(state = liveBiddingInitialState, action: TLiveBiddingAction): EntityListState<LiveBiddingPosition> {
    switch (action.type) {
        case getType(liveBiddingActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(liveBiddingActions.response):
            return {
                items: action.payload.positions,
                requestState: action.payload.requestState
            };
        case getType(liveBiddingActions.reset):
            return {
                ...state,
                items: state.items.filter(x => x.bwicReferenceName !== action.payload.bwicReferenceName)
            };
        case getType(liveBiddingActions.stage2LevelChange):
            return {
                ...state,
                items: state.items.map(x =>
                    x.bwicReferenceName === action.payload.bwicReferenceName && x.positionId === action.payload.positionId ? {
                        ...x,
                        openBiddingStage2Level: action.payload.level,
                        tiedForBest: action.payload.tiedForBest,
                        latestBidSubmission: action.payload.sentDateUtc
                    } : x
                )
            };
        case getType(liveBiddingActions.stage2Start):
            return {
                ...state,
                items: state.items
                    .filter(x => x.bwicReferenceName !== action.payload.bwicReferenceName)
                    .concat(action.payload.canDealerBidOnPositions?.map(x => ({
                        bwicReferenceName: action.payload.bwicReferenceName,
                        positionId: x.positionId,
                        isStage2Participant: x.canBidOnStage2,
                        openBiddingStage2Level: x.level,
                        tiedForBest: x.tiedForBest,
                        latestBidSubmission: undefined
                    })) ?? [])
            };

        default: return state;
    };
}
