import { getType } from 'typesafe-actions';
import { amrArrangerPipelineActions, TArrangerPipelineActions } from '../actions/amr-arranger-pipeline.actions';
import { constants, SORT } from '../constants';
import { RequestState } from '../constants/request-state';
import { ArrangerPipelineDealsState } from '../types/state/ArrangerPipelineDealsState';
import { sortSyndicateContacts } from '../utils/amr-pipeline.utils';

const initialState: ArrangerPipelineDealsState = {
    isLoading: true,
    deals: [],
    dealsSearchItems: [],
    selectedDealsLegalNames: [],
    sortBy: 'lastUpdated',
    sortDirection: SORT.DESC,
    hasMore: true,
    isExportLoad: false,
    isSubmitting: false,
    isSubmitted: false,
    anyPipelineExists: true,
    offset: 0,
    isSummaryRequesting: true
};

export const amrArrangerPipeline = (
    state: ArrangerPipelineDealsState = initialState,
    action: TArrangerPipelineActions,
) => {
    switch (action.type) {
        case getType(amrArrangerPipelineActions.arrangerPipelinesRequest):
            return {
                ...state,
                isLoading: true,
            };
        case getType(amrArrangerPipelineActions.setAnyPipelineExists):
            return {
                ...state,
                anyPipelineExists: action.payload.anyPipelineExists,
            };
        case getType(amrArrangerPipelineActions.arrangerPipelinesResponse): {
            const { requestState, arrangerPipelines, nonVisibleCount } = action.payload;

            if (!arrangerPipelines || requestState === RequestState.failure) {
                return state;
            }

            return {
                ...state,
                arrangerPipelines: state.arrangerPipelines
                    ? [
                          ...state.arrangerPipelines,
                          ...arrangerPipelines.map(p => ({
                              ...p,
                              syndicateContacts: sortSyndicateContacts(p.syndicateContacts),
                          })),
                      ]
                    : [
                          ...arrangerPipelines.map(p => ({
                              ...p,
                              syndicateContacts: sortSyndicateContacts(p.syndicateContacts),
                          })),
                      ],
                offset: state.offset + arrangerPipelines.length,
                hasMore: arrangerPipelines.length === constants.defaultTransactionsSearchCriteria.count,
                nonVisibleCount,
            };
        }
        case getType(amrArrangerPipelineActions.sortingChange): {
            const { sortBy, sortDirection } = action.payload;
            return {
                ...state,
                sortBy,
                sortDirection,
                arrangerPipelines: [],
                offset: 0,
            };
        }
        case getType(amrArrangerPipelineActions.arrangerPipelineStatisticRequest):
            return {
                ...state,
                isSummaryRequesting: true,
            };
        case getType(amrArrangerPipelineActions.arrangerPipelineStatisticResponse):
            return {
                ...state,
                isSummaryRequesting: false,
                summary: action.payload.summary
            };
        case getType(amrArrangerPipelineActions.resetPipelines): {
            return {
                ...state,
                offset: 0,
                arrangerPipelines: [],
            };
        }
        case getType(amrArrangerPipelineActions.dealsResponse): {
            return {
                ...state,
                deals: action.payload.deals,
            };
        }
        case getType(amrArrangerPipelineActions.selectDealsFromSearch): {
            return {
                ...state,
                selectedDealsLegalNames: action.payload.dealsLegalNames,
            };
        }
        case getType(amrArrangerPipelineActions.dealsSearchResponse): {
            return {
                ...state,
                dealsSearchItems: action.payload.arrangerPipelineDeals?.map(d => d.legalName),
            };
        }
        case getType(amrArrangerPipelineActions.edit): {
            return {
                ...state,
                isLoading: true,
            };
        }
        case getType(amrArrangerPipelineActions.setEditLoadingStatus): {
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        }
        case getType(amrArrangerPipelineActions.submitArrangerPipeline): {
            return {
                ...state,
                isSubmitting: true,
            };
        }
        case getType(amrArrangerPipelineActions.submitArrangerPipelineStatus): {
            return {
                ...state,
                isSubmitted: action.payload.isSubmitted,
                isSubmitting: false,
            };
        }
        case getType(amrArrangerPipelineActions.exportLoad): {
            return {
                ...state,
                isExportLoad: action.payload.isLoading,
            };
        }
        case getType(amrArrangerPipelineActions.resetDeals): {
            return {
                ...state,
                deals: [],
            };
        }
        case getType(amrArrangerPipelineActions.reset): {
            return initialState;
        }
        default:
            return state;
    }
};
