import { ActionType, getType } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { Company } from '../../types/amr-pipeline/models/Company';
import { collateralManagersActions } from '../../actions/entities/collateralManagers.action';

export const collateralManagersDefaultState: EntityListState<Company> = {
    items: [],
    requestState: RequestState.none,
};

export type CollateralManagersActionTypes = ActionType<
    | typeof collateralManagersActions.requestCollateralManagers
    | typeof collateralManagersActions.responseCollateralManagers
>;

export function collateralManagersReducer(
    state = collateralManagersDefaultState,
    action: CollateralManagersActionTypes,
): EntityListState<Company> {
    switch (action.type) {
        case getType(collateralManagersActions.requestCollateralManagers):
            return {
                ...state,
                requestState: action.payload.requestState,
            };
        case getType(collateralManagersActions.responseCollateralManagers):
            return {
                ...state,
                items: action.payload.collateralManagers,
                requestState: action.payload.requestState,
            };
        default:
            return state;
    }
}
