import { getType } from 'typesafe-actions';
import { emailPreferencesActions, TEmailPreferecnesActionTypes } from '../actions/email-preferences.actions';
import { RequestState } from '../constants/request-state';
import { EmailPreferencesState } from '../types/state/EmailPreferencesState';
import { isRequestSuccess } from '../utils/request-state.utils';

export const emailPreferencesInitialState: EmailPreferencesState = {
    requestState: RequestState.none
}

export const emailPreferencesReducer = (state = emailPreferencesInitialState, action: TEmailPreferecnesActionTypes): EmailPreferencesState => {
    switch (action.type) {
        case getType(emailPreferencesActions.request):
            return {
                ...state,
                requestState: RequestState.request,
                preferences: undefined
            }
        case getType(emailPreferencesActions.requestResult):
            return {
                ...state,
                requestState: action.payload.requestState,
                preferences: action.payload.preferences
            };
        case getType(emailPreferencesActions.reset):
            return { ...emailPreferencesInitialState };
        case getType(emailPreferencesActions.inventoryAlertChange):
            if (isRequestSuccess(state.requestState) && state.preferences != null) {
                return {
                    ...state,
                    preferences: {
                        ...state.preferences,
                        portfolioDealersInventoryAlertState: action.payload.enabled
                    }
                };
            }

            return state;
        case getType(emailPreferencesActions.bwicAlertChange):
            if (isRequestSuccess(state.requestState) && state.preferences != null) {
                return {
                    ...state,
                    preferences: {
                        ...state.preferences,
                        portfolioBwicAlertState: action.payload.enabled
                    }
                };
            }

            return state;
    };
}
