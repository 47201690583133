import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { EntityListState } from "../../types/state/entities/EntityListState";
import { brokerDealerListActions } from '../../actions/broker-dealer-list.actions';
import { RequestState } from "../../constants/request-state";
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';

export const brokerDealerListInitialState: EntityListState<BrokerDealerCompanySlim> = {
    items: [],
    requestState: RequestState.none
};

export function brokerDealerListReducer(state = brokerDealerListInitialState, action: AnyAction) {
    switch (action.type) {
        case getType(brokerDealerListActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(brokerDealerListActions.requestResult):
            return {
                items: action.payload.result || [],
                requestState: action.payload.requestState
            };
        case getType(brokerDealerListActions.updateDealerDistributionListInvitationSent):
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id === action.payload.dealerId) {
                        item.distributionListInvitationSent = true;
                        return { ...item }
                    }
                    return item
                })
            };
        case getType(brokerDealerListActions.updateDealerHeadOfTradingInvitationSent):
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.headOfTrading && item.id === action.payload.dealerId) {
                        item.headOfTradingInvitationSent = true;
                        return { ...item }
                    }
                    return item
                })
            };
        case getType(brokerDealerListActions.resetEntities):
            return { ...brokerDealerListInitialState };
        default: return state;
    }
}
