import { ActionType } from '../types/ActionType';
import { dealerListActions } from '../actions/dealer-list.actions';
import { getType } from 'typesafe-actions';
import { DealerListState } from '../types/state/DealerListState';

const initialStateDealerList: DealerListState = {
    expandedDealers: {},
    editableContact: {
        contact: null,
        dealerName: '',
        updateRequestStatus: false,
    },
    inviteToJoinRequestStatus: {},
    expandedAll: true,
};

export const dealerList = (state = initialStateDealerList, action: ActionType<any>) => {
    switch (action.type) {
        //Control actions
        case getType(dealerListActions.setDealerExpanded):
            return {
                ...state,
                expandedDealers: {
                    ...state.expandedDealers,
                    [action.payload.dealerId]: !(state.expandedDealers[action.payload.dealerId] ?? true)
                }
            };
        case getType(dealerListActions.expandDealers):
            return {
                ...state,
                expandedDealers: action.payload.dealers,
                expandedAll: action.payload.expandedAll,
            };
        case getType(dealerListActions.toggleExpandedAllDealers):
            return {
                ...state,
                expandedAll: !state.expandedAll
            };
        case getType(dealerListActions.setExpandAllDealers):
            return {
                ...state,
                expandedAll: state.expandedAll
            }
        case getType(dealerListActions.reset):
            return { ...initialStateDealerList };
        //Edit contact
        case getType(dealerListActions.setEditableContact):
            return {
                ...state,
                editableContact: {
                    contact: action.payload.contact,
                    dealerName: action.payload.dealerName
                }
            };
        case getType(dealerListActions.editableContactUpdateDealer):
            return {
                ...state,
                editableContact: {
                    ...state.editableContact,
                    dealerName: action.payload.dealerName
                }
            };
        case getType(dealerListActions.editableContactSetRequestStatus):
            return {
                ...state,
                editableContact: {
                    ...state.editableContact,
                    updateRequestStatus: action.payload.requestStatus
                }
            };
         //Invite to join
        case getType(dealerListActions.contactInviteToJoinSetRequestStatus):
            const requestStatus = { ...state.inviteToJoinRequestStatus };
            if (requestStatus[action.payload.contactId] && !action.payload.requestStatus) {
                delete requestStatus[action.payload.contactId]
            } else {
                requestStatus[action.payload.contactId] = true;
            }
            return {
                ...state,
                inviteToJoinRequestStatus: requestStatus
            };
        case getType(dealerListActions.resetEditableContact):
            return {
                ...state,
                editableContact: { ...initialStateDealerList.editableContact }
            };
        default:
            return state
    }
};
