import { bwicDateFilterOptions, companiesActions as actionTypes, companyColumns, memberColumns } from '../constants';
import { RequestState } from '../constants/request-state';
import { getType } from 'typesafe-actions';
import { pushUserActions } from '../actions/push.user.actions.js';
import { userRequestAdminActions } from '../actions/user-request-admin.actions';
import { userRequestAdminReducer } from './user-request-admin.reducer';
import { UserRequestStatus } from '../types/management/UserRequestStatus';
import { manageCompaniesFiltersActions } from '../actions/manage-companies-filter.actions';
import { manageCompaniesFiltersReducer } from './manage-companies-filter.reducer';
import { manageMembersFiltersActions } from '../actions/manage-members-filter.actions';
import { manageMembersFiltersReducer } from '../reducers/manage-members-filter.reducer';

export const companyFilterInitialState = {
    active: false,
    blocked: false,
    offPlatform: false,
    administrator: false,
    brokerDealer: false,
    seller: false,
    viewer: false,
    media: false,
    collateralManager: false,
    selectedDateOption: bwicDateFilterOptions.unspecified,
    customDateRange: { from: null, to: null },
    salesRepresentatives: [],
    regBy: [],
    searchTerm: ''
};

export const memberFilterInitialState = {
    active: false,
    blocked: false,
    invited: false,
    notInvited: false,
    pendingApproval: false,
    administrator: false,
    arrangersClient: false,
    brokerDealerTrader: false,
    brokerDealerViewer: false,
    dataEntry: false,
    sellerAdmin: false,
    sellerTrader: false,
    sellerViewer: false,
    subscriptionManager: false,
    viewer: false,
    companies: [],
    selectedDateOption: bwicDateFilterOptions.unspecified,
    customDateRange: { from: null, to: null },
    regBy: [],
    searchTerm: ''
}

const initialState = {
    isInitCompleted: false,
    initEditUserPageComplete: false,
    companies: [],
    users: [],
    userRequests: [],
    companyOrderBy: {
        column: companyColumns.legalName,
        direction: 'asc'
    },
    memberOrderBy: {
        column: memberColumns.fullName,
        direction: 'asc'
    },
    companyFilter: companyFilterInitialState,
    lastAppliedCompanyFilter: companyFilterInitialState,
    memberFilter: memberFilterInitialState,
    lastAppliedMemberFilter: memberFilterInitialState,
    editMember: {
        sendInvite: true,
        initialUser: {},
    },
    getEditUserRequestStatus: RequestState.none,
    companyStatusChangeConfirmation: null,
    approvalConfirmation: null,
    rejectionConfirmation: null,
    tfaStatusChangeConfirmation: null,
    rsStatusChange: {},
    rejectionReason: '',
    membersOfacRequestStatus: {},
    companiesOfacRequestStatus: {},
    exportUsersRequestState: RequestState.none,
    iRequestingExportCompanies: false,
    userLastLoginHistory: {
        history: [],
        user: null
    },
    salesRepresentatives: [],
    userRequestOperationState: {},
    requestStatusGetUserList: false,
    requestStateGetPendingUserRequestCount: RequestState.none,
    pendingUserRequestCount: 0,
};

export const companies = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_COMPLETED:
            return {
                ...state,
                isInitCompleted: true
            };

        case actionTypes.COMPANIES:
            return {
                ...state,
                companies: action.companies
            };
        case actionTypes.RESET_SUBSCRIPTION:
            return {
                ...state,
                companies: state.companies.map(c => {
                    if(c.id === action.payload.companyId) {
                        return {
                            ...c,
                            subscription: null,
                            subscriptionExpiration: null,
                        }
                    }
                    return c
                })
            }
        case actionTypes.USERS:
            return {
                ...state,
                users: action.users
            };
        case actionTypes.USER_REQUESTS:
            return {
                ...state,
                userRequests: action.userRequests,
                pendingUserRequestCount: action.userRequests.filter(u => u.status === UserRequestStatus.Pending).length
            };
        case actionTypes.RESET:
            return {
                ...initialState
            }
        case actionTypes.RESET_EDIT_MEMBER:
            return {
                ...state,
                editMember: { ...initialState.editMember },
                initEditUserPageComplete: false
            }
        case actionTypes.ORDER_BY:
            return {
                ...state,
                companyOrderBy: action.orderBy
            };
        case actionTypes.COMPANY_REJECT_REASON_SET:
            return {
                ...state,
                rejectionReason: action.value,
            };
        case actionTypes.COMPANY_APPROVAL_CONFIRMATION_SHOW:
            return {
                ...state,
                approvalConfirmation: action.company,
            };
        case actionTypes.COMPANY_APPROVAL_CONFIRMATION_HIDE:
            return {
                ...state,
                approvalConfirmation: null,
            };
        case actionTypes.COMPANY_REJECTION_CONFIRMATION_SHOW:
            return {
                ...state,
                rejectionConfirmation: action.company,
            };
        case actionTypes.COMPANY_REJECTION_CONFIRMATION_HIDE:
            return {
                ...state,
                rejectionConfirmation: null,
                rejectionReason: '',
            };
        case actionTypes.COMPANY_APPROVE_REQUEST:
            return {
                ...state,
                rsStatusChange: {
                    ...state.rsStatusChange,
                    [action.companyId]: RequestState.request,
                },
            };
        case actionTypes.COMPANY_APPROVE_SUCCESS:
            return {
                ...state,
                approvalConfirmation: null,
                rejectionConfirmation: null,
                rejectionReason: '',
                rsStatusChange: {
                    ...state.rsStatusChange,
                    [action.companyId]: RequestState.success,
                },
            };
        case actionTypes.COMPANY_APPROVE_FAILURE:
            return {
                ...state,
                rsStatusChange: {
                    ...state.rsStatusChange,
                    [action.companyId]: RequestState.failure
                },
            };
        case actionTypes.COMPANY_STATUS_CHANGE_CONFIRMATION:
            return {
                ...state,
                companyStatusChangeConfirmation: action.confirmation
            };
        case actionTypes.MEMBER_STATUS_CHANGE_CONFIRMATION:
            return {
                ...state,
                memberStatusChangeConfirmation: action.confirmation
            };
        case actionTypes.TFA_STATUS_CHANGE_CONFIRMATION:
            return {
                ...state,
                tfaStatusChangeConfirmation: action.confirmation
            };
        case actionTypes.TFA_ENABLED_STATE_CHANGED:
            return {
                ...state,
                users: state.users.map(u => u.id === action.userId ? { ...u, tfaEnabled: !u.tfaEnabled } : u)
            };
        case actionTypes.COMPANY_FILTER_CHNAGED:
            return {
                ...state,
                companyFilter: {
                    ...state.companyFilter,
                    ...action.filter,
                },
                lastAppliedCompanyFilter: state.companyFilter
            };
        case actionTypes.COMPANY_FILTER_APPLY:
            return {
                ...state,
                lastAppliedCompanyFilter: state.companyFilter
            };
        case actionTypes.COMPANY_FILTER_RESET:
            return {
                ...state,
                companyFilter: {
                    ...initialState.companyFilter,
                    searchTerm: state.companyFilter.searchTerm
                },
                lastAppliedCompanyFilter: {
                    ...initialState.lastAppliedCompanyFilter,
                    searchTerm: state.lastAppliedCompanyFilter.searchTerm
                }
            };
        case actionTypes.MEMBERS_FILTER_CHNAGED:
            return {
                ...state,
                memberFilter: {
                    ...state.memberFilter,
                    ...action.filter,
                },
                lastAppliedMemberFilter: state.memberFilter
            };
        case actionTypes.MEMBER_FILTER_APPLY:
            return {
                ...state,
                lastAppliedMemberFilter: state.memberFilter
            };
        case actionTypes.MEMBER_FILTER_RESET:
            return {
                ...state,
                memberFilter: {
                    ...initialState.memberFilter,
                    searchTerm: state.memberFilter.searchTerm
                },
                lastAppliedMemberFilter: {
                    ...initialState.lastAppliedMemberFilter,
                    searchTerm: state.lastAppliedMemberFilter.searchTerm
                }
            };
        case actionTypes.COUNTRIES:
            return {
                ...state,
                countries: action.countries
            };
        case actionTypes.MEMBERS_ORDER_BY:
            return {
                ...state,
                memberOrderBy: action.orderBy
            };
        case actionTypes.RESEND_INVITE_CONFIRMATION:
            return {
                ...state,
                memberResendInviteConfirmation: action.confirmation
            };

        case actionTypes.RESET_PASSWORD_CONFIRMATION:
            return {
                ...state,
                memberResetPasswordConfirmation: action.confirmation
            };
        case actionTypes.COMPANY_UPDATE_INIT_STATUS_EDIT_USER:
            return {
                ...state,
                initEditUserPageComplete: true
            };
        case actionTypes.COMPANY_SET_EDITING_USER:
            return {
                ...state,
                editMember: {
                    ...state.editMember,
                    initialUser: { ...action.payload.user }
                }
            };
        case actionTypes.SAVING_USER:
            return {
                ...state,
                editMember: {
                    ...state.editMember,
                    savingUserFlag: !!action.savingUserFlag
                }
            };
        case actionTypes.COMPANY_GET_USER_LIST_REQUEST_STATUS:
            return {
                ...state,
                requestStatusGetUserList: action.payload.status
            };
        case actionTypes.COMPANY_PUSH_DATA_RECEIVED:
            return {
                ...state,
                companies: mergeCompanyUpdates(state.companies, action.payload.company),
            };
        case actionTypes.COMPANY_MEMBER_SET_OFAC_REQUEST:
            return {
                ...state,
                membersOfacRequestStatus: action.payload.membersOfacRequestStatus
            };
        case actionTypes.COMPANY_MEMBER_SET_OFAC_SUCCESS:
            return {
                ...state,
                membersOfacRequestStatus: action.payload.membersOfacRequestStatus,
                users: action.payload.users
            };
        case actionTypes.COMPANY_MEMBER_SET_OFAC_FAILURE:
            return {
                ...state,
                membersOfacRequestStatus: action.payload.membersOfacRequestStatus
            };

        case actionTypes.COMPANY_SET_OFAC_REQUEST:
            return {
                ...state,
                companiesOfacRequestStatus: action.payload.companiesOfacRequestStatus
            };
        case actionTypes.COMPANY_SET_OFAC_SUCCESS:
            return {
                ...state,
                companiesOfacRequestStatus: action.payload.companiesOfacRequestStatus,
                companies: action.payload.companies
            };
        case actionTypes.COMPANY_SET_OFAC_FAILURE:
            return {
                ...state,
                companiesOfacRequestStatus: action.payload.companiesOfacRequestStatus
            };

        case actionTypes.MANAGE_EXPORT_USERS_REQUEST:
            return {
                ...state,
                exportUsersRequestState: RequestState.request
            };
        case actionTypes.MANAGE_EXPORT_USERS_SUCCESS:
            return {
                ...state,
                exportUsersRequestState: RequestState.success
            };
        case actionTypes.MANAGE_EXPORT_USERS_FAILURE:
            return {
                ...state,
                exportUsersRequestState: RequestState.failure
            };
        case actionTypes.MANAGE_SET_REQUEST_STATE_EXPORT_COMPANIES:
            return {
                ...state,
                iRequestingExportCompanies: action.payload.status
            }
        case actionTypes.COMPANY_MEMBER_SET_USER_LAST_LOGIN_HISTORY:
            return {
                ...state,
                userLastLoginHistory: { history: action.payload.history, user: action.payload.user }
            };
        case actionTypes.COMPANY_MEMBER_RESET_USER_LAST_LOGIN_HISTORY:
            return {
                ...state,
                userLastLoginHistory: { ...initialState.userLastLoginHistory }

            };
        case getType(pushUserActions.user):
            const user = action.payload.user;
            return {
                ...state,
                users: state.users.map(currentUser => currentUser.id === user.id ? user : currentUser)
            };
        case actionTypes.COMPANY_MEMBER_SUBSCRIPTION_CHANGE:
            return {
                ...state,
                users: state.users.map(u => u.id === action.userId
                    ? {
                        ...u,
                        subscription: action.subscriptionType,
                        trialPeriodEnd: action.trialPeriodEnd || u.trialPeriodEnd
                    }
                    : u
                )
            };
        case actionTypes.COMPANY_SUBSCRIPTION_CHANGE:
            return {
                ...state,
                companies: state.companies.map(c => c.id === action.companyId
                    ? {
                        ...c,
                        subscription: action.subscriptionType,
                        subscriptionExpiration: action.subscriptionPeriodEnd
                    }
                    : c
                )
            };
        case actionTypes.STORE_SALES_REPRESENTATIVES:
            return {
                ...state,
                salesRepresentatives: action.salesRepresentatives
            };
        case actionTypes.COMPANY_SET_CLEARING_BANK:
            return {
                ...state,
                companies: state.companies.map(c => c.id === action.companyId
                    ? { ...c, isClearingBank: action.isClearingBank }
                    : c
                )
            };
        case actionTypes.COMPANY_MEMBER_DELETED:
            return {
                ...state,
                users: state.users.filter(u => u.id !== action.payload.userId)
            };
        case getType(userRequestAdminActions.setOperationState):
        case getType(userRequestAdminActions.userRequestApproved):
        case getType(userRequestAdminActions.userRequestBlocked):
        case getType(userRequestAdminActions.userRequestUnblocked):
        case getType(userRequestAdminActions.userRequestDeleted):
        case getType(userRequestAdminActions.setPendingUserRequestCount):
            return userRequestAdminReducer(state, action);
        case getType(manageCompaniesFiltersActions.setDateFilterChange):
        case getType(manageCompaniesFiltersActions.setDateFilterRangeChange):
        case getType(manageCompaniesFiltersActions.setFilterRegBy):
        case getType(manageCompaniesFiltersActions.setFilterSalesRepresentative):
        case getType(manageCompaniesFiltersActions.toogleFilterRegBy):
        case getType(manageCompaniesFiltersActions.toogleFilterRoleAdministrator):
        case getType(manageCompaniesFiltersActions.toogleFilterRoleBrokerDealer):
        case getType(manageCompaniesFiltersActions.toogleFilterRoleSeller):
        case getType(manageCompaniesFiltersActions.toogleFilterRoleViewer):
        case getType(manageCompaniesFiltersActions.toogleFilterRoleMedia):
        case getType(manageCompaniesFiltersActions.toogleFilterSalesRepresentative):
        case getType(manageCompaniesFiltersActions.toogleFilterStatusActive):
        case getType(manageCompaniesFiltersActions.toogleFilterStatusBlocked):
        case getType(manageCompaniesFiltersActions.toogleFilterStatusOffPlatform):
            return {
                ...state,
                companyFilter: manageCompaniesFiltersReducer(state.companyFilter, action)
            }
        case getType(manageMembersFiltersActions.setDateFilterChange):
        case getType(manageMembersFiltersActions.setDateFilterRangeChange):
        case getType(manageMembersFiltersActions.setFilterRegBy):
        case getType(manageMembersFiltersActions.toogleFilterRegBy):
        case getType(manageMembersFiltersActions.setFilterCompanies):
        case getType(manageMembersFiltersActions.toogleFilterCompanies):
        case getType(manageMembersFiltersActions.toogleFilterRoleAdministrator):
        case getType(manageMembersFiltersActions.toggleFilterRoleArrangersClient):
        case getType(manageMembersFiltersActions.toogleFilterRoleBrokerDealerTrader):
        case getType(manageMembersFiltersActions.toogleFilterRoleBrokerDealerViewer):
        case getType(manageMembersFiltersActions.toogleFilterRolesDataEntry):
        case getType(manageMembersFiltersActions.toogleFilterRoleSellerAdministrator):
        case getType(manageMembersFiltersActions.toogleFilterRoleSellerTrader):
        case getType(manageMembersFiltersActions.toogleFilterRolesSubscriptionManager):
        case getType(manageMembersFiltersActions.toogleFilterRoleSellerViewer):
        case getType(manageMembersFiltersActions.toogleFilterRoleViewer):
        case getType(manageMembersFiltersActions.toogleFilterStatusActive):
        case getType(manageMembersFiltersActions.toogleFilterStatusBlocked):
        case getType(manageMembersFiltersActions.toogleFilterStatusInvited):
        case getType(manageMembersFiltersActions.toogleFilterStatusNotInvited):
        case getType(manageMembersFiltersActions.toogleFilterStatusPendingApproval):
        case getType(manageMembersFiltersActions.toogleFilterCollateralManager):
        case getType(manageMembersFiltersActions.toogleFilterRoleMedia):
            return {
                ...state,
                memberFilter: manageMembersFiltersReducer(state.memberFilter, action)
            }
        default:
            return state;
    }
};

function mergeCompanyUpdates(initialCompanyList, company) {
    let found = false;
    const companyList = [...initialCompanyList];
    for (let i = 0; i < companyList.length; i++) {
        if (companyList[i].id === company.id) {
            found = true;
            companyList[i] = company;
        }
    }
    if (!found) {
        companyList.push(company);
    }
    return companyList
}
