import * as _ from 'lodash';
import {
    biddingActions as actionTypes,
    colorDistributionActions,
    pushDataActions,
    autoFeedbackSettingsActions
} from '../constants';
import { RequestState } from '../constants/request-state';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { BwicProcessType } from '../types/models/Process';

const initialState = {
    bwic: undefined,
    visitorType: undefined,
    userCompany: undefined,
    isLoading: true,
    participantsLoadingState: RequestState.none,
    bwicDealers: [],
    bwicDealerUsers: [],
    bwicDealerContacts: []
};

export const bidding = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BIDDING_SAVE_BWIC_DEALERS:
        case actionTypes.BIDDING_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case actionTypes.BIDDING_STORE_ORIGINAL_BWIC:
            return {
                ...state,
                bwic: action.bwic
            };
        case actionTypes.BIDDING_STORE_VISITOR_TYPE:
            return {
                ...state,
                visitorType: action.visitorType,
                userCompany: action.userCompany
            };
        case actionTypes.BIDDING_IMPROVERS_LOADED:
            return {
                ...state,
                improvers: action.improvers
            };
        case colorDistributionActions.COLOR_DISTRIBUTION_SET_BWIC_COLOR_DISTRIBUTION:
            return {
                ...state,
                bwic: {
                    ...state.bwic,
                    isColorDistribution: action.isColorDistribution
                }
            };
        case actionTypes.STORE_BWIC_PARTICIPANTS:
            return {
                ...state,
                bwicDealers: action.payload.bwicDealers,
                bwicDealerUsers: action.payload.bwicDealerUsers,
                bwicDealerContacts: action.payload.bwicDealerContacts
            };
        case actionTypes.BIDDING_LOAD_PARTICIPANTS_REQUEST_STATUS:
            return {
                ...state,
                participantsLoadingState: action.payload.requestState
            };
        case actionTypes.LIVE_BIDDING_STAGE_2_TIMEOUT_END:
            return _.cloneDeep(state);
        case actionTypes.BIDDING_BWIC_STATUS_CHANGE:
            return {
                ...state,
                bwic: {
                    ...state.bwic,
                    status: action.nextStatus
                }
            };
        case autoFeedbackSettingsActions.AUTO_FEEDBACK_SETTINGS_STORE:
            return {
                ...state,
                bwic: state.bwic && {
                    ...state.bwic,
                    autoFeedbackConfiguration: action.settings
                }
            };
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_START_STAGE2:
            if (!shouldHandlePushData(state, action)) return state;

            const autoFeedbackConfiguration = state.bwic.process.type === BwicProcessType.TopX
                ? { onPlatform: true, offPlatform: true }
                : state.bwic.autoFeedbackConfiguration;

            return {
                ...state,
                bwic: {
                    ...state.bwic,
                    autoFeedbackConfiguration,
                    process: {
                        ...state.bwic.process,
                        stagedBiddingStatus: OpenBiddingStatus.stage1Ended,
                        jumpBall: state.bwic.process.jumpBall == null
                            ? state.bwic.process.jumpBall
                            : {
                                ...state.bwic.process.jumpBall,
                                stage1EndDateUtc: action.stage1EndDateUtc
                            },
                        topX: state.bwic.process.topX == null
                            ? state.bwic.process.topX
                            : {
                                ...state.bwic.process.topX,
                                stage1EndDateUtc: action.stage1EndDateUtc
                            }

                    }
                }
            };
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                bwic: {
                    ...state.bwic,
                    process: {
                        ...state.bwic.process,
                        stagedBiddingStatus: OpenBiddingStatus.improvementRoundEnded,
                    }
                }
            };
        case pushDataActions.PUSH_DATA_COLOR_DISTRIBUTION:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                bwic: {
                    ...state.bwic,
                    isColorDistribution: action.isColorDistribution
                }
            };
        case pushDataActions.PUSH_DATA_AUTO_FEEDBACK_CONFIG:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                bwic: {
                    ...state.bwic,
                    autoFeedbackConfiguration: action.settings
                }
            };
        case pushDataActions.PUSH_DATA_NEW_BID:
            return shouldHandlePushData(state, action)
                ? updateBwicParticipants(state, action.bid.company)
                : state;
        case pushDataActions.PUSH_DATA_QUICK_FEEDBACK:
            return shouldHandlePushData(state, action)
                ? updateBwicParticipants(state, action.feedback.brokerDealer)
                : state;
        case actionTypes.BIDDING_RESET:
            return initialState;
        default:
            return state;
    }
};

function shouldHandlePushData(state, action) {
    return (
        state.bwic &&
        state.bwic.referenceName === action.bwicReferenceName
    );
}

function updateBwicParticipants(state, company) {
    return {
        ...state,
        bwic: {
            ...state.bwic,
            companies: state.bwic.companies.some(c => c.id === company.id)
                ? state.bwic.companies
                : state.bwic.companies.concat(company)
        }
    };
}

