import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { addToPortfolioActions } from "../actions/add-to-portfolio.actions";
import { AddToPortfolioState } from '../types/state/AddToPortfolioState';

const initialState = {
    popupVisible: false,
    selectedPortfolio: undefined,
    isSaving: false,
    error: undefined
};

export function addToPortfolio(state = initialState, action: AnyAction): AddToPortfolioState {
    switch (action.type) {
        case getType(addToPortfolioActions.setPopupVisibility):
            return {
                ...state,
                popupVisible: action.payload.visible
            };
        case getType(addToPortfolioActions.setSelectedPortfolio):
            return {
                ...state,
                selectedPortfolio: action.payload.selectedPortfolio
            };
        case getType(addToPortfolioActions.reset):
            return { ...initialState };
        case getType(addToPortfolioActions.setSubmittingProgress):
            return {
                ...state,
                isSaving: action.payload.isSaving
            };
        case getType(addToPortfolioActions.storeError):
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state;
    }
}
