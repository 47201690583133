import { getType } from "typesafe-actions";
import { tradeAllocationTemplateActions, TradeAllocationTemplateActionType } from "../../actions/trade-allocation-template.actions";
import { RequestState } from "../../constants/request-state";
import { TradeAllocationTemplate } from "../../types/trade-allocation/template/TradeAllocationTemplate";
import { EntityListState } from '../../types/state/entities/EntityListState';
import { compareBoolean, compareStrings } from "../../utils/compare.utils";

export const tradeAllocationTemplateInitialState: EntityListState<TradeAllocationTemplate> = {
    items: [],
    requestState: RequestState.none
};

export function tradeAllocationTemplateReducer(
    state = tradeAllocationTemplateInitialState,
    action: TradeAllocationTemplateActionType): EntityListState<TradeAllocationTemplate> {
    switch (action.type) {
        case getType(tradeAllocationTemplateActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(tradeAllocationTemplateActions.requestResult):
            return {
                items: sortTemplates(action.payload.templates),
                requestState: action.payload.requestState
            };
        case getType(tradeAllocationTemplateActions.update):
            const { template } = action.payload;
            return {
                ...state,
                items: sortTemplates(
                    state.items.some(i => i.id === template.id)
                        ? state.items.map(i => i.id === template.id ? template : i)
                        : state.items.concat(template)
                )
            }
        case getType(tradeAllocationTemplateActions.deleteTemplate):
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.payload.templateId)
            };
        case getType(tradeAllocationTemplateActions.activeChange):
            return {
                ...state,
                items: sortTemplates(
                    state.items.map(i => {
                        if (i.id === action.payload.activated?.id) return action.payload.activated
                        if (i.id === action.payload.deactivated?.id) return action.payload.deactivated
                        return i;
                    })
                )
            };
        default:
            return state;
    };
}

function sortTemplates(templates: TradeAllocationTemplate[]) {
    return templates.sort((a, b) =>
        compareBoolean(a.active, b.active) ||
        compareStrings(a.name, b.name)
    );
}
