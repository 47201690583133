import { RequestState } from '../constants/request-state';
import { getType } from 'typesafe-actions';
import {
    blotterFiltersReset,
    blotterHasTradesFailure,
    blotterHasTradesRequest,
    blotterHasTradesSuccess,
    blotterSearchFailure,
    blotterSearchRequest,
    blotterSearchRequestNextPage,
    blotterSearchSuccess,
    blotterSetCurrency,
    blotterToggleConfirmed,
    blotterTogglePending,
    blotterToggleRejected,
    blotterExportRequest,
    blotterExportSuccess,
    blotterExportFailure,
    blotterToggleFilterBuy,
    blotterToggleFilterSell,
    blotterToggleFilterClient,
    blotterToggleFilterDesc,
    blotterDateFilterChange,
    blotterCustomDateFilterChange,
    blotterSetVconRequest,
    blotterSetVconSuccess,
    blotterSetVconFailure,
    blotterSetBookedRequest,
    blotterSetBookedSuccess,
    blotterSetBookedFailure,
    blotterToggleAllocationStatusUnsettled,
    blotterToggleAllocationStatusPendingSettlement,
    blotterToggleAllocationStatusPendingAllocation,
    blotterToggleAllocationStatusSettled,
    blotterSettlementDateFilterChange,
    blotterSettlementCustomDateFilterChange,
    blotterSetFilterDefaults,
    blotterResetCurrency,
    blotterSelectAllCurrencies,
    blotterToggleAllocationStatusPendingProceeds,
    blotterReset,
    blotterOrderByChange
} from '../actions';
import { blotterTradeAllocationActions } from '../actions/blotter-trade-allocation.actions';
import { blotterTradeAllocationTemplateActions } from '../actions/blotter-trade-allocation-template.actions';
import { accountActions, bwicDateFilterOptions, pushDataActions, SORT } from '../constants';
import { Currencies } from '../types/enums/Currency';
import { isRequesting } from '../utils';

const initialFilter = {
    selectedDateOption: bwicDateFilterOptions.unspecified,
    customDateRange: {},
    settlementSelectedDateOption: bwicDateFilterOptions.unspecified,
    settlementCustomDateRange: {},
    confirmed: false,
    pending: false,
    rejected: false,
    currency: [],
    buySide: false,
    sellSide: false,
    client: false,
    desc: false,
    allocationStatus: {
        unsettled: false,
        pendingSettlement: false,
        pendingAllocation: false,
        pendingProceeds: false,
        settled: false
    }
};

const initialState = {
    initialFilters: { ...initialFilter },
    filters: { ...initialFilter },
    prevFilters: { ...initialFilter },
    securities: [],
    requestStateSecurities: RequestState.none,
    ownTrades: {
        hasTrades: false,
        requestState: RequestState.none,
    },
    requestStateExport: RequestState.none,
    requestStatusSetVcon: {},
    requestStatusSetBooked: {},
    requestStateSaveTradeAllocation: RequestState.none,
    tradeAllocationTemplate: {
        saveRequestState: RequestState.none
    },
    totalRecordNumber: 0,
    page: 1,
    pageSize: 50,
    orderByColumn: 'tradeDate',
    orderByDirection: SORT.DESC
};

export function blotter(state = initialState, action) {
    switch (action.type) {

        case getType(blotterFiltersReset):
            return {
                ...state,
                filters: { ...state.initialFilters },
            };
        case getType(blotterSetFilterDefaults):
            return {
                ...state,
                filters: { ...state.initialFilters },
            };
        case getType(blotterDateFilterChange):
        case getType(blotterCustomDateFilterChange):
        case getType(blotterToggleConfirmed):
        case getType(blotterTogglePending):
        case getType(blotterToggleRejected):
        case getType(blotterSetCurrency):
        case getType(blotterToggleFilterBuy):
        case getType(blotterToggleFilterSell):
        case getType(blotterToggleFilterClient):
        case getType(blotterToggleFilterDesc):
        case getType(blotterToggleAllocationStatusUnsettled):
        case getType(blotterToggleAllocationStatusPendingSettlement):
        case getType(blotterToggleAllocationStatusPendingAllocation):
        case getType(blotterToggleAllocationStatusPendingProceeds):
        case getType(blotterToggleAllocationStatusSettled):
        case getType(blotterSettlementDateFilterChange):
        case getType(blotterSettlementCustomDateFilterChange):
        case getType(blotterSelectAllCurrencies):
        case getType(blotterResetCurrency):
            if (!isRequesting(state.requestStateSecurities)) {
                return {
                    ...state,
                    filters: blotterFiltersReducer(state.filters, action),
                };
            }
            return state;
        case getType(blotterSearchRequest):
            return {
                ...state,
                requestStateSecurities: RequestState.request,
                securities: [],
                page: 1,
                totalRecordNumber: 0,
                prevFilters: state.filters,
            };
        case getType(blotterSearchRequestNextPage):
            return {
                ...state,
                requestStateSecurities: RequestState.request,
            };
        case getType(blotterSearchSuccess):
            return {
                ...state,
                requestStateSecurities: RequestState.success,
                totalRecordNumber: action.payload.totalRecordNumber,
                securities: state.securities.concat(action.payload.result),
                page: state.securities.length ? state.page + 1 : 1
            };
        case getType(blotterSearchFailure):
            return {
                ...state,
                requestStateSecurities: RequestState.failure,
            };
        case getType(blotterOrderByChange):
            return {
                ...state,
                orderByColumn: action.payload.orderByColumn,
                orderByDirection: state.orderByColumn === action.payload.orderByColumn
                    ? state.orderByDirection === SORT.ASC ? SORT.DESC : SORT.ASC
                    : SORT.ASC
            };
        case getType(blotterHasTradesRequest):
            return {
                ...state,
                ownTrades: {
                    ...state.ownTrades,
                    requestState: RequestState.request
                },
            };
        case getType(blotterHasTradesSuccess):
            return {
                ...state,
                ownTrades: {
                    requestState: RequestState.success,
                    hasTrades: action.payload.hasTrades,
                }
            };
        case getType(blotterHasTradesFailure):
            return {
                ...state,
                ownTrades: {
                    ...state.ownTrades,
                    requestState: RequestState.failure,
                }
            };
        case pushDataActions.PUSH_DATA_TRADE:
            return {
                ...state,
                securities: state.securities.map(s => s.tradeId === action.trade.id
                    ? {
                        ...s,
                        status: action.trade.status,
                        rejectReason: action.trade.rejectReason,
                        settlementDate: action.trade.settlementDate,
                        buyerSettlementStatus: action.trade.buyerSettlementStatus,
                        sellerSettlementStatus: action.trade.sellerSettlementStatus,
                        isAllocationManual: action.trade.isAllocationManual,
                        allocationLock: action.trade.allocationLock,
                        affirmedRejectedBy: action.trade.affirmedRejectedBy,
                        createdBy: action.trade.createdBy
                    }
                    : s
                )
            };

        // TO DO: Commented code breaks redux action processing flow and should be rewritten.
        //case pushDataActions.PUSH_DATA_BWIC_STATUS_CHANGE:
        //case pushDataActions.PUSH_DATA_TRADED_AWAY:
        //    return blotterPushDataReducer(state, action);

        case getType(blotterExportRequest):
            return {
                ...state,
                requestStateExport: RequestState.request
            };
        case getType(blotterExportSuccess):
            return {
                ...state,
                requestStateExport: RequestState.success
            };
        case getType(blotterExportFailure):
            return {
                ...state,
                requestStateExport: RequestState.failure
            };
        case accountActions.LOGOUT:
            return { ...initialState };
        case getType(blotterSetVconRequest):{
            const { tradeId, isBuyTrade } = action.payload;
            return {
                ...state,
                requestStatusSetVcon: {
                    ...state.requestStatusSetVcon,
                    [getTradeKey(tradeId, isBuyTrade)]: true
                }
            };
        }
        case getType(blotterSetVconSuccess): {
            const { tradeId, isBuyTrade, value } = action.payload;
            return {
                ...state,
                requestStatusSetVcon: {
                    ...state.requestStatusSetVcon,
                    [getTradeKey(tradeId, isBuyTrade)]: false
                },
                securities: state.securities.map(s => (s.tradeId === tradeId)
                    ? {
                        ...s,
                        vconBuy: (isBuyTrade ? value : s.vconBuy),
                        vconSell: (!isBuyTrade ? value : s.vconSell)
                    }
                    : s
                )
            }
        }
        case getType(blotterSetVconFailure):{
            const { tradeId, isBuyTrade } = action.payload;
            return {
                ...state,
                requestStatusSetVcon: {
                    ...state.requestStatusSetVcon,
                    [getTradeKey(tradeId, isBuyTrade)]: false
                }
            };
        }
        case getType(blotterSetBookedRequest):{
            const { tradeId, isBuyTrade } = action.payload;
            return {
                ...state,
                requestStatusSetBooked: {
                    ...state.requestStatusSetBooked,
                    [getTradeKey(tradeId, isBuyTrade)]: true
                }
            };
        }
        case getType(blotterSetBookedSuccess): {
            const { tradeId, isBuyTrade, value } = action.payload;
            return {
                ...state,
                requestStatusSetBooked: {
                    ...state.requestStatusSetBooked,
                    [getTradeKey(tradeId, isBuyTrade)]: false
                },
                securities: state.securities.map(s => (s.tradeId === tradeId)
                    ? {
                        ...s,
                        bookedBuy: (isBuyTrade ? value : s.bookedBuy),
                        bookedSell: (!isBuyTrade ? value : s.bookedSell)
                    }
                    : s
                )
            }
        }
        case getType(blotterSetBookedFailure): {
            const { tradeId, isBuyTrade } = action.payload;
            return {
                ...state,
                requestStatusSetBooked: {
                    ...state.requestStatusSetBooked,
                    [getTradeKey(tradeId, isBuyTrade)]: false
                }
            };
        }
        case getType(blotterTradeAllocationActions.resetPopupState):
        case getType(blotterTradeAllocationActions.saveRequest):
        case getType(blotterTradeAllocationActions.saveSuccess):
        case getType(blotterTradeAllocationActions.saveFailure):
            return tradeAllocationReducer(state, action);
        case getType(blotterTradeAllocationTemplateActions.saveRequest):
        case getType(blotterTradeAllocationTemplateActions.saveSuccess):
        case getType(blotterTradeAllocationTemplateActions.saveFailure):
        case getType(blotterTradeAllocationTemplateActions.resetPanelState):
            return {
                ...state,
                tradeAllocationTemplate: tradeAllocationTemplateReducer(state.tradeAllocationTemplate, action)
            };
        case getType(blotterReset):
            return { ...initialState }
        default:
            return state;
    }
}

/*
 TO DO: use this function if pending trade must be shown even if 'Pending' filter option is deactivated
function createTrade(actionTrade) {
    const trade = {};
    trade.bwicReferenceName = actionTrade.bwicReferenceName;
    trade.positionId = actionTrade.positionId;
    trade.tradeId = actionTrade.trade.id;
    trade.tradeDate = actionTrade.trade.tradeDate;
    trade.settleDate = actionTrade.trade.settleDate;
    trade.price = actionTrade.trade.price;
    trade.size = actionTrade.trade.size;
    trade.status = actionTrade.trade.status;
    trade.comment = actionTrade.trade.comment;
    trade.rejectReason = actionTrade.trade.rejectReason;
    trade.counterparty = actionTrade.trade.buyerCompany.name;
    trade.isinCusip = actionTrade.trade.isinCusip;
    trade.currency = actionTrade.trade.currency;
    trade.rating = actionTrade.trade.rating;
    trade.onBehalf = actionTrade.trade.onBehalf;
    trade.ticker = actionTrade.trade.ticker;
    return trade;
}
*/

/*function blotterPushDataReducer(state, action) {
    for (let i = 0; i < state.securities.length; i++) {
        if (state.securities[i].positionId === action.positionId) {
            const securities = [...state.securities];
            const { status, rejectReason, comment } = action.trade;
            securities[i] = { ...securities[i], status, rejectReason, comment };
            return { ...state, securities };
        }
    }
    return state;
}*/

function tradeAllocationTemplateReducer(state, action) {
    switch (action.type) {
        case getType(blotterTradeAllocationTemplateActions.resetPanelState):
            return {
                saveRequestState: RequestState.none
            };
        case getType(blotterTradeAllocationTemplateActions.saveRequest):
            return {
                ...state,
                saveRequestState: RequestState.request
            };
        case getType(blotterTradeAllocationTemplateActions.saveSuccess):
            return {
                ...state,
                saveRequestState: RequestState.success
            };
        case getType(blotterTradeAllocationTemplateActions.saveFailure):
            return {
                ...state,
                saveRequestState: RequestState.failure
            };
        default: return state;
    }
}

function tradeAllocationReducer(state, action) {
    switch (action.type) {
        case getType(blotterTradeAllocationActions.resetPopupState):
            return { ...state, requestStateSaveTradeAllocation: RequestState.none };
        case getType(blotterTradeAllocationActions.saveRequest):
            return { ...state, requestStateSaveTradeAllocation: RequestState.request }
        case getType(blotterTradeAllocationActions.saveSuccess):
            return {
                ...state,
                securities: state.securities.map(t => t.tradeId === action.payload.tradeId
                    ? {
                        ...t,
                        allocationLock: action.payload.allocationLock,
                        buyerSettlementStatus: action.payload.buyerSettlementStatus,
                        sellerSettlementStatus: action.payload.sellerSettlementStatus,
                        isBuyerAllocationManual: action.payload.isBuyerAllocationManual,
                        isSellerAllocationManual: action.payload.isSellerAllocationManual,
                    }
                    : t
                ),
                requestStateSaveTradeAllocation: RequestState.success
            }
        case getType(blotterTradeAllocationActions.saveFailure):
            return { ...state, requestStateSaveTradeAllocation: RequestState.failure };
        default: return state;
    }
}

function blotterFiltersReducer(state = initialState.filters, action) {
    switch (action.type) {
        case getType(blotterDateFilterChange):
            return {
                ...state,
                selectedDateOption: action.payload.dateOption,
                customDateRange: action.payload.dateOption.key === bwicDateFilterOptions.custom.key ?
                    state.customDateRange : {}
            };
        case getType(blotterCustomDateFilterChange):
            return {
                ...state,
                selectedDateOption: bwicDateFilterOptions.custom,
                customDateRange: action.payload.dateRange
            };
        case getType(blotterSettlementDateFilterChange):
            return {
                ...state,
                settlementSelectedDateOption: action.payload.dateOption,
                settlementCustomDateRange: action.payload.dateOption.key === bwicDateFilterOptions.custom.key
                    ? state.settlementCustomDateRange : {}
            };
        case getType(blotterSettlementCustomDateFilterChange):
            return {
                ...state,
                settlementSelectedDateOption: bwicDateFilterOptions.custom,
                settlementCustomDateRange: action.payload.dateRange
            };
        case getType(blotterSetCurrency):
            return {
                ...state,
                currency: state.currency.some(c => c === action.payload.currency)
                    ? state.currency.filter(c => c !== action.payload.currency)
                    : state.currency.concat(action.payload.currency),
            };
        case getType(blotterResetCurrency):
            return {
                ...state,
                currency: []
            };
        case getType(blotterSelectAllCurrencies):
            return {
                ...state,
                currency: [...Currencies]
            };
        case getType(blotterToggleConfirmed):
            return {
                ...state,
                confirmed: !state.confirmed,
            };
        case getType(blotterToggleRejected):
            return {
                ...state,
                rejected: !state.rejected,
            };
        case getType(blotterTogglePending):
            return {
                ...state,
                pending: !state.pending,
            };
        case getType(blotterToggleAllocationStatusUnsettled):
            return {
                ...state,
                allocationStatus: {
                    ...state.allocationStatus,
                    unsettled: !state.allocationStatus.unsettled
                }
            };
        case getType(blotterToggleAllocationStatusPendingSettlement):
            return {
                ...state,
                allocationStatus: {
                    ...state.allocationStatus,
                    pendingSettlement: !state.allocationStatus.pendingSettlement
                }
            };
        case getType(blotterToggleAllocationStatusPendingAllocation):
            return {
                ...state,
                allocationStatus: {
                    ...state.allocationStatus,
                    pendingAllocation: !state.allocationStatus.pendingAllocation
                }
            };
        case getType(blotterToggleAllocationStatusPendingProceeds):
            return {
                ...state,
                allocationStatus: {
                    ...state.allocationStatus,
                    pendingProceeds: !state.allocationStatus.pendingProceeds
                }
            };
        case getType(blotterToggleAllocationStatusSettled):
            return {
                ...state,
                allocationStatus: {
                    ...state.allocationStatus,
                    settled: !state.allocationStatus.settled
                }
            };
        case getType(blotterToggleFilterBuy):
            return {
                ...state,
                buySide: !state.buySide
            };
        case getType(blotterToggleFilterSell):
            return {
                ...state,
                sellSide: !state.sellSide
            };
        case getType(blotterToggleFilterClient):
            return {
                ...state,
                client: !state.client
            };
        case getType(blotterToggleFilterDesc):
            return {
                ...state,
                desc: !state.desc
            };

        default:
            return state;
    }
}

function getTradeKey(tradeId, isBuyTrade) {
    return `${tradeId}_${isBuyTrade}`
}
