import { RequestState } from '../constants/request-state';
import { getType } from 'typesafe-actions';
import { TCompanyMembersActions, companyMembersActions } from '../actions/company-members.actions';

export function companyMembers(state = {}, action: TCompanyMembersActions) {
    switch (action.type) {
        case getType(companyMembersActions.fetchCompanyMembers.request):
            return {
                ...state,
                [action.payload.companyId]: {
                    members: [],
                    requestState: RequestState.request,
                    error: ''
                }
            };
        case getType(companyMembersActions.fetchCompanyMembers.success):
            return {
                ...state,
                [action.payload.companyId]: {
                    members: action.payload.members,
                    RequestState: RequestState.success,
                    error: ''
                }
            };
        case getType(companyMembersActions.fetchCompanyMembers.failure):
            return {
                ...state,
                [action.payload.companyId]: {
                    members: [],
                    requestState: RequestState.failure,
                    error: action.payload.error
                }
            };
        default:
            return state;
    }
}
