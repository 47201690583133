import { getType } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { changePxTalkActions as actionTypes } from '../constants';
import { ChangePxTalkState } from '../types/state/ChangePxTalkState';
import { priceTalksActions } from '../actions/price-talks.actions';
import { isRequestSuccess } from '../utils';

const initialState: ChangePxTalkState = {
    visible: false,
    bwicReferenceName: undefined,
    positionId: undefined,
    ticker: undefined,
    pxTalks: undefined,
    deletedPriceTalks: [],
    isNormalizing: false
};

export const changePxTalk = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.RESET:
            return { ...initialState };
        case actionTypes.STORE_PXTALKS:
            return {
                ...state,
                bwicReferenceName: action.bwicReferenceName,
                positionId: action.positionId,
                ticker: action.ticker,
                pxTalks: action.pxTalks,
                visible: true
            };
        case actionTypes.VALIDATION_RESULT:
            return {
                ...state,
                pxTalks: action.pxTalks
            };
        case actionTypes.ADD_PXTALK:
            return {
                ...state,
                pxTalks: [action.pxTalk].concat(state.pxTalks)
            };
        case actionTypes.TALK_CHANGE:
            return {
                ...state,
                pxTalks: state.pxTalks?.map(p =>
                    p.company.id === action.change.companyId
                        ? { ...p, talk: action.change.talk }
                        : p)
            };
        case actionTypes.LEVEL_TYPE_CHANGE:
            return {
                ...state,
                pxTalks: state.pxTalks?.map(p =>
                    p.company.id === action.change.companyId
                        ? { ...p, levelType: action.change.levelType }
                        : p)
            };
        case actionTypes.DELETE_PX_TALK: {
            const deletedPriceTalk = state.pxTalks?.find((p, i) => i === action.index);
            return {
                ...state,
                pxTalks: state.pxTalks?.filter((p, i) => i !== action.index),
                deletedPriceTalks: deletedPriceTalk?.talk ? [...state.deletedPriceTalks, deletedPriceTalk] : state.deletedPriceTalks
            };
        }
        case actionTypes.NORMALIZED_LEVEL_CHANGE:
            return {
                ...state,
                pxTalks: state.pxTalks?.map(p =>
                    p.company.id === action.change.companyId
                        ? { ...p, normalizedLevel: action.change.normalizedLevel, talk: action.change.talk || p.talk }
                        : p)
            };
        case actionTypes.TALK_ERROR:
            return {
                ...state,
                pxTalks: state.pxTalks?.map(p =>
                    p.company.id === action.error.companyId
                        ? { ...p, error: action.error.errorMessage }
                        : p)
            };
        case actionTypes.NORMALIZING:
            return {
                ...state,
                isNormalizing: action.isNormalizing
            };
        case getType(priceTalksActions.requestResult):
            if (action.payload.positionId === state.positionId && isRequestSuccess(action.payload.requestState)) {
                return {
                    ...state,
                    pxTalks: action.payload.priceTalks
                };
            }

            return state;
        default:
            return state;
    }
};
