import { getType } from 'typesafe-actions';
import { Company } from '../../types/amr-pipeline/models/Company';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { RequestState } from '../../constants/request-state';
import {
    TAvailableBrokerDealersActionType,
    availableBrokerDealersActions,
} from '../../actions/entities/availableBrokerDealers.actions';
import { CompanyStatus } from '../../types/amr-pipeline/enums/CompanyStatus';

export const availableBrokerDealersInitialState: EntityListState<Company> = {
    items: [],
    requestState: RequestState.none,
};

export function availableBrokerDealersReducer(
    state = availableBrokerDealersInitialState,
    action: TAvailableBrokerDealersActionType,
): EntityListState<Company> {
    switch (action.type) {
        case getType(availableBrokerDealersActions.request):
            return {
                items: [],
                requestState: RequestState.request,
            };
        case getType(availableBrokerDealersActions.requestResult):
            return {
                items: action.payload.companies
                    .filter(c => c.status === CompanyStatus.Active)
                    .sort((a, b) => a.legalName.localeCompare(b.legalName)),
                requestState: action.payload.requestState,
            };
        default:
            return state;
    }
}
