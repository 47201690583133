import { dealersStatsActions as actionTypes } from '../constants/actionTypes/dealers-stats.actions';
import { AnyAction } from "redux";
import { DealersStatsState } from "../types/state/DealersStatsState";

const initialState = {
    statsExist: false,
    fetchStatsRequestStatus: false,
    fetchStatsExistRequestState: false,
    exportStatsRequestStatus: false,
    stats: []
};

export function dealersStats(state = initialState, action: AnyAction): DealersStatsState {
    switch (action.type) {
        case actionTypes.RESET_STATS:
                return initialState;
        case actionTypes.STORE_STATS_EXIST_STATUS:
            return {
                ...state,
                statsExist: action.payload.existStatus
            };
        case actionTypes.FETCH_STATS_REQUEST_STATUS:
            return {
                ...state,
                fetchStatsRequestStatus: action.payload.status
            };
        case actionTypes.STORE_STATS:
            return {
                ...state,
                stats: action.payload.stats,
            };
        case actionTypes.EXPORT_STATS_REQUEST_STATUS:
            return {
                ...state,
                exportStatsRequestStatus: action.payload.status,
            };
        case actionTypes.FETCH_STATS_EXIST_REQUEST_STATUS:
            return {
                ...state,
                fetchStatsExistRequestState: action.payload.status,
            };
        default:
            return state;
    }
}

