import { getType } from 'typesafe-actions';
import { compareBoolean, compareStrings } from '../utils/compare.utils';
import { BanksState } from '../types/state/BanksState';
import { Bank } from '../types/banks/Bank';
import { banksActions, TBanksActions } from '../actions/banks.actions';
import { compact, isNil } from 'lodash';
import { RequestState } from '../constants/request-state';
import { BrokerDealerSave } from '../types/banks/BrokerDealerSave';
import { analyticDefaultFilters } from '../constants';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { sortSyndicateContacts } from '../utils/amr-pipeline.utils';
import { BankSyndicateContacts } from '../types/banks/BankSyndicateContacts';
import { BrokerDealerContact } from '../types/amr-pipeline/models/BrokerDealerContact';
import { ContactType } from '../types/amr-pipeline/enums/ContactType';
import { Contact } from '../types/amr-pipeline/models/Contact';

const banksInitialState: BanksState = {
    isLoading: true,
    isDetailsLoading: true,
    isUpdating: false,
    events: [],
    syndicateContacts: {
        primaryContacts: {
            items: [],
            numberOfHiddenItems: 0,
        },
        secondaryContacts: {
            items: [],
            numberOfHiddenItems: 0,
        },
    },
    newsRequestState: RequestState.none,
    analytics: {
        isLoading: true,
        users: [],
        clientsActivity: {
            isLoading: false,
            data: [],
            filter: analyticDefaultFilters,
            numberOfHiddenItems: 0,
        },
        profileHistoryViews: {
            isLoading: true,
            data: [],
            filter: analyticDefaultFilters,
            numberOfHiddenItems: 0,
        },
    },
    exportTransactionCountLoading: false,
};

const isOfType = (type: ContactType) => (contact: Contact) => contact.type === type;
const isDistributionList = isOfType(ContactType.DistributionList);
const isHeadOfDesk = isOfType(ContactType.HeadOfDesk);
const isHeadOfTrading = (contact: Contact) => contact.headOfTrading;

function sortBanks(data: Bank[]) {
    const featuresCount = (bank: Bank) => compact([
        bank.hasActiveTransactions,
        bank.existsOnDealerInventory,
        bank.sendsPxTalk
    ]).length;

    return data.sort(
        (a, b) =>
        compareBoolean(a.isUserCompany, b.isUserCompany) ||
        featuresCount(b) - featuresCount(a) ||
        compareStrings(a.legalName, b.legalName)
    );
}

function sortByOrder(a: BrokerDealerContact, b: BrokerDealerContact) {
    return a.order - b.order;
}

function sortContacts(syndicateContacts: BankSyndicateContacts): BankSyndicateContacts {
    return  {
        primaryContacts: {
            ...syndicateContacts.primaryContacts,
            items: sortSyndicateContacts(syndicateContacts.primaryContacts.items, sortByOrder) || [],
        },
        secondaryContacts: {
            ...syndicateContacts.secondaryContacts,
            items: sortSyndicateContacts(syndicateContacts.secondaryContacts.items, sortByOrder) || [],
        }
    };
}

export const banks = (state = banksInitialState, action: TBanksActions): BanksState => {
    switch (action.type) {
        case getType(banksActions.getBanks):
            return {
                ...state,
                isLoading: true,
            };
        case getType(banksActions.getBanksResult):
            return {
                ...state,
                isLoading: false,
                data: sortBanks(action.payload.banks),
            };
        case getType(banksActions.getBankDetails):
            return {
                ...state,
                selectedBank: state.data?.find(x => x.referenceName === action.payload.referenceName),
                isDetailsLoading: true,
                newsRequestState: RequestState.request,
            };
        case getType(banksActions.getBankDetailsResult): {
            const {
                bankDetails,
                referenceName,
                aggregatedManagerTransactions,
                syndicateContacts,
                companyEvents,
            } = action.payload;

            return {
                ...state,
                isDetailsLoading: false,
                isUpdating: false,
                selectedBank:
                    state.selectedBank?.referenceName === referenceName
                        ? { ...state.selectedBank, details: bankDetails }
                        : state.selectedBank,
                aggregatedManagersCount: aggregatedManagerTransactions,
                syndicateContacts: sortContacts(syndicateContacts),
                events: companyEvents,
                newsRequestState: RequestState.success,
            };
        };
        case getType(banksActions.managersTransactionCountFilterResult): {
            const aggregatedManagersCount = action.payload;

            return {
                ...state,
                aggregatedManagersCount,
            };
        };
        case getType(banksActions.exportTransactionCountRequest):
            return {
                ...state,
                exportTransactionCountLoading: true,
            };
        case getType(banksActions.exportTransactionCountResponse):
            return {
                ...state,
                exportTransactionCountLoading: false,
            };
        case getType(banksActions.editBank): {
            const {
                primaryContacts: { items: primary },
                secondaryContacts: { items: secondary },
            } = state.syndicateContacts;

            const headOfDeskContact = primary.find(isHeadOfDesk);
            const headOfDeskDistributionList = primary.find(isDistributionList);
            const headOfTradingContact = secondary.find(contact =>
                isHeadOfTrading(contact) && !isDistributionList(contact)
            );
            const headOfTradingDistributionList = secondary.find(isDistributionList);

            const leftPrimaryContacts = primary.filter(contact => isNil(contact.type));
            const leftSecondaryContacts = secondary.filter(contact => !isHeadOfTrading(contact) && !isDistributionList(contact));
            const contacts = [
                ...leftPrimaryContacts,
                ...leftSecondaryContacts,
            ].sort(sortByOrder);

            const headOfDesk: BrokerDealerSave = {
                id: 0,
                officePhoneNumber: '',
                mobilePhoneNumber: '',
                email: '',
                primaryDesk: false,
                order: 0,
                ...(headOfDeskContact || {}),
                distributionListEmail: headOfDeskDistributionList?.email,
                type: ContactType.HeadOfDesk,
                headOfTrading: false,
            };

            const headOfTrading: BrokerDealerSave = {
                id: 0,
                officePhoneNumber: '',
                mobilePhoneNumber: '',
                email: '',
                primaryDesk: false,
                order: 0,
                ...(headOfTradingContact || {}),
                distributionListEmail: headOfTradingDistributionList?.email,
                headOfTrading: true,
            };

            return {
                ...state,
                editBank: {
                    headOfDesk,
                    headOfTrading,
                    events: state.events,
                    contacts,
                },
            };
        };
        case getType(banksActions.editBankReset):
            return {
                ...state,
                editBank: undefined
            };
        case getType(banksActions.updateBank):
            return {
                ...state,
                isUpdating: true,
            };
        case getType(banksActions.updateBankResult): {
            const { requestState, result } = action.payload;

            if (requestState === RequestState.failure || !result) {
                return {
                    ...state,
                    isUpdating: false,
                };
            }

            return {
                ...state,
                ...result,
                syndicateContacts: sortContacts(result.syndicateContacts),
                isUpdating: false,
            };
        };
        case getType(banksActions.setBankForAlertPopup):
            return {
                ...state,
                bankReferenceNameForAlert: action.payload.referenceName,
            };
        case getType(banksActions.analyticsInitResponse):
            const { users, analytics, numberOfHiddenItems } = action.payload;
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    users,
                    general: analytics,
                    isLoading: false,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        numberOfHiddenItems,
                        isLoading: false,
                    },
                },
            };
        case getType(banksActions.analyticsReset):
            return {
                ...state,
                analytics: banksInitialState.analytics,
            };
        case getType(banksActions.clientsActivityRequest):
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        isLoading: true,
                    },
                },
            };
        case getType(banksActions.clientsActivityResponse): {
            const { data, numberOfHiddenItems } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        data,
                        numberOfHiddenItems,
                        isLoading: false,
                    },
                },
            };
        }
        case getType(banksActions.profileViewHistoryResponse): {
            const { data, numberOfHiddenItems } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    profileHistoryViews: {
                        ...state.analytics.profileHistoryViews,
                        data,
                        numberOfHiddenItems,
                        isLoading: false,
                    },
                },
            };
        }
        case getType(banksActions.clientsActivityFilterChange): {
            const filter = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    clientsActivity: {
                        ...state.analytics.clientsActivity,
                        filter: {
                            ...state.analytics.clientsActivity.filter,
                            ...filter,
                        },
                    },
                },
            };
        }
        case getType(banksActions.profileViewHistoryFilterChange): {
            const filter = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    profileHistoryViews: {
                        ...state.analytics.profileHistoryViews,
                        filter: {
                            ...state.analytics.profileHistoryViews.filter,
                            ...filter,
                        },
                    },
                },
            };
        }
        case getType(banksActions.profileViewHistoryFilterByBar): {
            const { accessType, date } = action.payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    profileHistoryViews: {
                        ...state.analytics.profileHistoryViews,
                        filter: {
                            ...banksInitialState.analytics.profileHistoryViews.filter,
                            tabs: [accessType],
                            dateOption: dateRangeFilterOptions.Custom,
                            date: {
                                dateFrom: date,
                                dateTo: date,
                            }
                        },
                    },
                },
            };
        }
        case getType(banksActions.reset):
            return banksInitialState;
        default:
            return state;
    }
}
