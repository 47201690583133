import { SettlementAgentCommissionState } from '../../types/state/entities/SettlementAgentCommissionState';
import { clearingBankActions, TSettlementAgentCommissionActionType } from '../../actions/clearing-bank.actions';
import { getType } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { SettlementAgentCommissionByRating } from '../../types/bid-as-dealer/SettlementAgentCommissionByRating';

export const settlementAgentCommissionInitialState: SettlementAgentCommissionState = {
    commission: {}
}

export function settlementAgentCommissionReducer(
    state = settlementAgentCommissionInitialState,
    action: TSettlementAgentCommissionActionType): SettlementAgentCommissionState {
    switch (action.type) {
        case getType(clearingBankActions.settlementAgentCommissionRequest):
            return {
                ...state,
                commission: {
                    ...state.commission,
                    [action.payload.companyId]: {
                        requestState: RequestState.request,
                        values: {} as SettlementAgentCommissionByRating
                    }
                }
            };
        case getType(clearingBankActions.settlementAgentCommissionResponse):
            return {
                ...state,
                commission: {
                    ...state.commission,
                    [action.payload.companyId]: {
                        requestState: action.payload.requestState,
                        values: action.payload.commission ?? {} as SettlementAgentCommissionByRating
                    }
                }
            };
        default: return state;
    }
}
