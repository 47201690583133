import { AnyAction } from 'redux'
import { getType } from 'typesafe-actions';
import { BdInventoryState } from "../types/state/BdInventoryState"
import { bdInventoryActions } from '../actions/bd-inventory.actions';
import { RequestState } from '../constants/request-state';

const initialState: BdInventoryState = {
    isInventoryExist: false,
    requestStateInventoryExist: RequestState.none,
    requestStatusExportInventory: false,
    requestStatusSaveBloombergEmail: false,
    isVisibleAddBloombergEmailForm: false,
    advancedFilterBlocked: false
};

export const bdInventory = (state = initialState, action: AnyAction): BdInventoryState => {
    switch (action.type) {
        case getType(bdInventoryActions.reset):
            return { ...initialState };
        case getType(bdInventoryActions.requestInventoryExistFlag):
            return {
                ...state,
                requestStateInventoryExist: RequestState.request
            };
        case getType(bdInventoryActions.inventoryExistFlag):
            return {
                ...state,
                requestStateInventoryExist: RequestState.success,
                isInventoryExist: action.payload.exist
            };
        case getType(bdInventoryActions.exportInventoryRequest):
            return {
                ...state,
                requestStatusExportInventory: true
            };
        case getType(bdInventoryActions.exportInventoryResponse):
            return {
                ...state,
                requestStatusExportInventory: false
            };
        case getType(bdInventoryActions.toggleVisibleAddBloombergEmailForm):
            return {
                ...state,
                isVisibleAddBloombergEmailForm: !state.isVisibleAddBloombergEmailForm
            };
        case getType(bdInventoryActions.addBloombergEmailRequest):
            return {
                ...state,
                requestStatusSaveBloombergEmail: true
            };
        case getType(bdInventoryActions.addBloombergEmailResponse):
            return {
                ...state,
                requestStatusSaveBloombergEmail: false
            };
        case getType(bdInventoryActions.advancedFilterBlocked):
            return {
                ...state,
                advancedFilterBlocked: action.payload.blocked
            }
        default:
            return state
    }
};
