import { BlastMessageRecipientLevelState, BlastMessageState, BlastMessageTypes } from "../types/state/BlastMessageState";
import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { blastMessageActions } from "../actions/blast-message.actions";

const initialState: BlastMessageState = {
    panelVisible: false,
    isSending: false,
    messageType: BlastMessageTypes.submitFinal,
    recipientLevel: BlastMessageRecipientLevelState.Top3,
    recipients: {},
    isSelectAllRecipients: true,
    stage1AboutToEndTimeout: '00:02:00',
    error: ''
}

export function blastMessage(state = initialState, action: AnyAction): BlastMessageState {
    switch (action.type) {
        case getType(blastMessageActions.show):
            return { ...state, panelVisible: true };
        case getType(blastMessageActions.hide):
            return { ...initialState };
        case getType(blastMessageActions.messageTypeChange):
            return {
                ...state,
                messageType: action.payload.messageType,
                recipientLevel: BlastMessageRecipientLevelState.Top3
            };
        case getType(blastMessageActions.recipientLevelChange):
            return {
                ...state,
                messageType: BlastMessageTypes.submitFinal,
                recipientLevel: action.payload.recipientLevel
            };
        case getType(blastMessageActions.storeRecipients):
            return {
                ...state,
                recipients: action.payload.recipients
            };

        case getType(blastMessageActions.editMinutesAboutToEnd):
            return {
                ...state,
                stage1AboutToEndTimeout: action.payload.stage1AboutToEndTimeout
            };

        case getType(blastMessageActions.setError):
            return {
                ...state,
                error: action.payload.error
            };

        case getType(blastMessageActions.recipientSelectedChange):
            if (state.recipients[action.payload.companyIdentifier] == null) {
                return state;
            } else {
                return {
                    ...state,
                    recipients: {
                        ...state.recipients,
                        [action.payload.companyIdentifier]: !state.recipients[action.payload.companyIdentifier]
                    }
                };
            }
        case getType(blastMessageActions.toggleSelectAllParticipantsFlag):
            return { ...state, isSelectAllRecipients: !state.isSelectAllRecipients };
        case getType(blastMessageActions.sending):
            return { ...state, isSending: action.payload.isSending };
        case getType(blastMessageActions.selectAllRecipientsChange): {
            const recipients = { ...state.recipients };

            Object
                .keys(recipients)
                .forEach((key: string) => recipients[key] = action.payload.isSelectAllRecipients);

            return {
                ...state,
                isSelectAllRecipients: action.payload.isSelectAllRecipients,
                recipients
            };
        };
        default: return state;
    }
}
