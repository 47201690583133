import { HelpResourcesState } from '../types/state/HelpResourcesState';
import { helpResourcesActions, TResourcesActions } from '../actions/help-resources.actions';
import { getType } from 'typesafe-actions';
import { RequestState } from "../constants/request-state";

const initialState: HelpResourcesState = {
    categories: [],
    activeSectionArticles: [],
    search: {
        results: [],
        totalRecordNumber: 0
    },
    categoriesRequestState: RequestState.none,
    sectionRequestState: RequestState.none,
    searchRequestState: RequestState.none
}

export const helpResources = (state = initialState, action: TResourcesActions) => {
    switch (action.type) {
        case getType(helpResourcesActions.resetSearch):
            return {
                ...state,
                search: {
                    results: []
                },
                searchRequestState: RequestState.none
            }
        case getType(helpResourcesActions.resetSection):
            return {
                ...state,
                activeSectionArticles: [],
                sectionRequestState: RequestState.none
            }
        case getType(helpResourcesActions.fetchCategories.request):
            return {
                ...state,
                categoriesRequestState: RequestState.request
            }
        case getType(helpResourcesActions.fetchSection.request):
            return {
                ...state,
                sectionRequestState: RequestState.request
            }
        case getType(helpResourcesActions.fetchBySearch.request):
            return {
                ...state,
                searchRequestState: RequestState.request,
                totalRecordNumber: 0
            }
        case getType(helpResourcesActions.fetchCategories.success):
            return {
                ...state,
                categories: action.payload,
                categoriesRequestState: RequestState.success
            }
        case getType(helpResourcesActions.fetchBySearch.success):
            return {
                ...state,
                search: {
                    ...state.search,
                    results: action.payload.results,
                    totalRecordNumber: action.payload.totalRecordNumber
                },
                searchRequestState: RequestState.success
            }
        case getType(helpResourcesActions.loadMoreSearch.success):
            return {
                ...state,
                search: {
                    ...state.search,
                    results: [
                        ...state.search.results,
                        ...action.payload.results
                    ],
                    totalRecordNumber: action.payload.totalRecordNumber
                },
                searchRequestState: RequestState.success
            }
        case getType(helpResourcesActions.fetchSection.success):
            return {
                ...state,
                activeSectionArticles: action.payload.articles,
                sectionRequestState: RequestState.success
            }
        default:
            return state;
    }
}