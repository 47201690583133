import { getType } from 'typesafe-actions';
import { TDealsActions, dealsActions } from '../actions/deals.actions';
import { DealsState } from '../types/state/DealsState';
import { isNil, omit } from 'lodash';

const initialState: DealsState = {
    isLoading: false,
    isDetailsLoading: false,
    isUpdating: false,
    data: undefined,
    isSecondaryInfoLoading: false,
    selectedReferenceName: undefined,
    selectedDeal: undefined,
    editDeal: undefined,
    allClasses: {
        cachedInactiveClasses: {},
    },
    classSecurity: undefined,
    classSecurityStats: { count: 0, securities: [] },
};

export function deals(state = initialState, action: TDealsActions): DealsState {
    switch (action.type) {
        case getType(dealsActions.getDeals):
            return {
                ...state,
                isLoading: true,
            };
        case getType(dealsActions.getDealsResult):
            return {
                ...state,
                isLoading: false,
                data: action.payload.deals,
            };
        case getType(dealsActions.getDealDetails):
            return {
                ...state,
                isDetailsLoading: true,
                selectedReferenceName: action.payload.referenceName,
            };
        case getType(dealsActions.getDealDetailsResult):
            return {
                ...state,
                isDetailsLoading: false,
                selectedDeal: action.payload.dealDetails,
            };
        case getType(dealsActions.editDeal):
            return {
                ...state,
                editDeal: state.selectedDeal ? { ...state.selectedDeal } : undefined,
            };
        case getType(dealsActions.editDealReset):
            return {
                ...state,
                editDeal: undefined,
            };
        case getType(dealsActions.updateDeal):
        case getType(dealsActions.updateDealDocuments):
        case getType(dealsActions.deleteDealRequest):
        case getType(dealsActions.amrInfoSave):
        case getType(dealsActions.allClassesUpdate): {
            return {
                ...state,
                isUpdating: true,
            };
        }
        case getType(dealsActions.updateDealResult):
        case getType(dealsActions.updateDealDocumentsResult):
        case getType(dealsActions.allClassesUpdateResponse):
        case getType(dealsActions.saveAmrInfoResponse): {
            return {
                ...state,
                isUpdating: false,
            };
        }
        case getType(dealsActions.getClassSecondaryInfo):
            return {
                ...state,
                isSecondaryInfoLoading: true,
            };
        case getType(dealsActions.getClassSecondaryInfoResult):
            return {
                ...state,
                isSecondaryInfoLoading: false,
                classSecurity: action.payload.security,
                classSecurityStats: action.payload.securityStatistics || initialState.classSecurityStats,
            };
        case getType(dealsActions.deleteDealResult):
            return {
                ...state,
                isUpdating: false,
                editDeal: undefined,
                data: state.data?.filter(deal => deal.referenceName !== action.payload.referenceName),
            };
        case getType(dealsActions.allClassesEdit):
            return {
                ...state,
                allClasses: {
                    ...state.allClasses,
                    classes: action.payload.classes,
                },
            };
        case getType(dealsActions.allClassesCacheTranche): {
            const { rowIndex, tranche } = action.payload;

            return {
                ...state,
                allClasses: {
                    ...state.allClasses,
                    cachedInactiveClasses: {
                        ...state.allClasses.cachedInactiveClasses,
                        [rowIndex]: tranche,
                    },
                },
            };
        }
        case getType(dealsActions.allClassesInvalidateTranceCache): {
            const { rowIndex } = action.payload;

            return {
                ...state,
                allClasses: {
                    ...state.allClasses,
                    cachedInactiveClasses: isNil(rowIndex)
                        ? {}
                        : omit(state.allClasses.cachedInactiveClasses, rowIndex),
                },
            };
        }
        case getType(dealsActions.reset):
            return initialState;

        default:
            return state;
    }
}
