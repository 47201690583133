import { errorActions as actionTypes } from '../constants';

const initialState = {
    criticalError: false,
    error: undefined
}

export const error = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CRITICAL_ERROR:
            return { criticalError: true, error: action.error };
        case actionTypes.ERROR_RESET:
            return { ...initialState };
        default:
            return state;
    }
}
