import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { inventoryActions } from '../../actions/inventory.actions';
import { RequestState } from "../../constants/request-state";
import { InventoryPosition } from "../../types/inventory/InventoryPosition";
import { InventoryPositionWithLock } from "../../types/inventory/InventoryPositionWithLock";
import { InventoryState } from '../../types/state/InventoryState';
import { isRequestSuccess } from '../../utils';
import { updateInventoryPushReducer } from './update-inventory-push.reducer';

export const allInventoryReducerDefaultState: InventoryState = {
    items: [],
    requestState: RequestState.none,
    totalRecordNumber: 0,
    currentPageNumber: 1,
    pageSize: 50,
    summary: undefined,
};

export function inventoryReducer(state: InventoryState, action: AnyAction): InventoryState {
    switch (action.type) {
        case (getType(inventoryActions.request)):
            return {
                ...state,
                requestState: RequestState.request,
            };
        case (getType(inventoryActions.requestResult)):
            return {
                ...state,
                items: action.payload.result ? state.items.concat(action.payload.result) : state.items,
                requestState: action.payload.requestState,
                totalRecordNumber: action.payload.totalRecordNumber || 0,
                summary: action.payload.summary ? action.payload.summary: state.summary
            };
        case getType(inventoryActions.loadNextPage):
            return {
                ...state,
                currentPageNumber: state.currentPageNumber + 1
            };
        case getType(inventoryActions.resetEntities):
            return { ...allInventoryReducerDefaultState };
        case getType(inventoryActions.pushInventory): {
            if (!isRequestSuccess(state.requestState)) return state;

            const pushInventories: InventoryPosition[] = action.payload.inventories;
            const lockModifiedDate: Date = action.payload.lockModifiedDate;

            const [position] = pushInventories;

            const items =
                updateInventoryPushReducer(state.items, pushInventories).map(p =>
                    p.dealer.id === position?.dealer.id
                        ? { ...p, lockModifiedDate }
                        : p as InventoryPositionWithLock
                );

            return { ...state, items };
        };
        case getType(inventoryActions.inventoryStatusChange):
            return {
                ...state,
                items: state.items.map(i => i.id === action.payload.inventoryPositionId
                    ? { ...i, status: action.payload.status }
                    : i)
            };
        case getType(inventoryActions.resetSummary):
            return {
                ...state,
                summary: undefined
            }
        default:
            return state
    }
}
