import { ActionType, getType } from 'typesafe-actions';
import { changeSubscriptionActions } from '../actions/change-subscription.actions';
import { ChangeSubscriptionState } from '../types/state/ChangeSubscriptionState';

const initialState: ChangeSubscriptionState = {
    popupVisible: false,
    company: undefined,
    isSubscriptionSavingFlag: false,
};

export function changeSubscription(
    state = initialState,
    action: ActionType<typeof changeSubscriptionActions[keyof typeof changeSubscriptionActions]>
): ChangeSubscriptionState {
    switch (action.type) {
        case getType(changeSubscriptionActions.show):
            return {
                ...state,
                popupVisible: true,
                company: action.payload.company,
            };
        case getType(changeSubscriptionActions.hide):
            return { ...initialState };
        default:
            return state;
    }
}
