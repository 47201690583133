import { getType } from "typesafe-actions";
import { EditNewsState } from "../types/state/EditNewsState";
import {
    editNewsActions,
    TEditNewsActions,
} from "../actions/edit-news.actions";

const editNewsInitialState: EditNewsState = {
    isLoading: false,
    isMoreLoading: false,
    isDetailsLoading: false,
    isSearching: false,
    newsList: [],
    newsDetails: undefined,
    initialNews: undefined,
    managers: [],
    arrangers: [],
    offset: 0,
    totalCount: 0,
    searchTerm: "",
};

export const editNews = (
    state = editNewsInitialState,
    action: TEditNewsActions
): EditNewsState => {
    switch (action.type) {
        case getType(editNewsActions.init):
            return {
                ...state,
                isLoading: true,
            };
        case getType(editNewsActions.getInitResult): {
            const { newsList, managers, arrangers } = action.payload;
            return {
                ...state,
                newsList,
                managers,
                arrangers,
                isLoading: false,
                offset: newsList.length,
            };
        }
        case getType(editNewsActions.getTotalCountResult): {
            const { totalCount } = action.payload;
            return {
                ...state,
                totalCount,
            };
        }
        case getType(editNewsActions.loadMoreNewsList):
            return {
                ...state,
                isMoreLoading: true,
            };
        case getType(editNewsActions.loadMoreNewsListResult): {
            const { newsList } = action.payload;
            return {
                ...state,
                isMoreLoading: false,
                newsList: [...state.newsList, ...newsList],
                offset: state.offset + newsList.length,
            };
        }
        case getType(editNewsActions.setSearchTerm): {
            const { searchTerm } = action.payload;
            return {
                ...state,
                searchTerm,
            };
        }
        case getType(editNewsActions.searchNewsList): {
            const { searchTerm } = action.payload;
            return {
                ...state,
                isSearching: true,
                searchTerm,
            };
        }
        case getType(editNewsActions.searchNewsListResult): {
            const { newsList } = action.payload;
            return {
                ...state,
                isSearching: false,
                offset: newsList.length,
                newsList,
            };
        }
        case getType(editNewsActions.getNewsDetails):
        case getType(editNewsActions.createOrUpdateNews):
        case getType(editNewsActions.deleteNews):
            return {
                ...state,
                isDetailsLoading: true,
            };
        case getType(editNewsActions.getNewsDetailsResult): {
            return {
                ...state,
                isDetailsLoading: false,
                newsDetails: action.payload.newsData,
            };
        }
        case getType(editNewsActions.updateNewsList): {
            const { newsList } = action.payload;
            return {
                ...state,
                newsList,
                offset: newsList.length,
                isDetailsLoading: false,
            };
        }
        case getType(editNewsActions.setInitialNews): {
            const { initialNews } = action.payload;
            return {
                ...state,
                initialNews,
                newsDetails: initialNews,
            };
        }
        case getType(editNewsActions.resetNewsDetails):
            return {
                ...state,
                newsDetails: undefined,
            };

        case getType(editNewsActions.reset):
            return editNewsInitialState;
        default:
            return state;
    }
};
