import { AnyAction, combineReducers } from 'redux';
import { ActionCreator, getType } from "typesafe-actions";
import { addressActions } from '../actions/address.actions';
import { RegionState } from '../types/state/AddressState';

function createRegionReducer<T extends string, K>(loadAction: ActionCreator<T>, responseAction: ActionCreator<T>) {
    const initialRegionState: RegionState<K> = {
        data: [],
        isLoading: false,
        isLoaded: false,
    };

    return function reducer(state = initialRegionState, action: AnyAction): RegionState<K> {
        switch (action.type) {
            case getType(loadAction):
                return {
                    ...state,
                    isLoading: true,
                    isLoaded: false,
                    data: [],
                };
            case getType(responseAction):
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    data: action.payload,
                };
            default:
                return state;
        }
    }
}

export const address = combineReducers({
    countries: createRegionReducer(addressActions.loadCountries, addressActions.loadCountriesResponse),
    states: createRegionReducer(addressActions.loadStates, addressActions.loadStatesResponse),
    cities: createRegionReducer(addressActions.loadCities, addressActions.loadCitiesResponse),
});
