import { getType, ActionType } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { AdminInventoryEditState } from "../../types/state/AdminInventoryEditState";
import { adminInventoryEditActions } from '../../actions/admin-inventory-edit.actions';

export type TAdminEditActionType =
    ActionType<
        typeof adminInventoryEditActions.requestInventory |
        typeof adminInventoryEditActions.requestInventorySuccess |
        typeof adminInventoryEditActions.requestInventoryError |
        typeof adminInventoryEditActions.reset
    >;

export const adminInventoryEditInitialState: AdminInventoryEditState = {
    items: [],
    requestState: RequestState.none
}

export function adminInventoryEditReducer(
    state: AdminInventoryEditState = adminInventoryEditInitialState,
    action: TAdminEditActionType): AdminInventoryEditState  {
    switch (action.type) {
        case getType(adminInventoryEditActions.requestInventory):
            return {
                ...state,
                requestState: RequestState.request,
                items: []
            };
        case getType(adminInventoryEditActions.requestInventorySuccess):
            return {
                ...state,
                requestState: RequestState.success,
                bdCompany: action.payload.company,
                lockModifiedDate: action.payload.lockModifiedDate,
                items: action.payload.positions
            };
        case getType(adminInventoryEditActions.requestInventoryError):
            return {
                ...adminInventoryEditInitialState,
                requestState: RequestState.failure
            };
        case getType(adminInventoryEditActions.reset):
            return {
                ...adminInventoryEditInitialState
            };
        default: return state;
    }
}
