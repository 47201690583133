import { RequestState } from '../../constants/request-state';
import { getType } from 'typesafe-actions';
import { awaitingTradesActions, AwaitingTradesActionTypes } from '../../actions/entities/awaitingTrades.actions';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { AwaitingTrade } from '../../types/trades/AwaitingTrade';

export const awaitingTradesInitialState: EntityListState<AwaitingTrade> = {
    requestState: RequestState.none,
    items: [],
}

export const awaitingTrades = (state = awaitingTradesInitialState, action: AwaitingTradesActionTypes): EntityListState<AwaitingTrade> => {
    switch (action.type) {
        case getType(awaitingTradesActions.requestTrades):
            return {
                ...state,
                requestState: RequestState.request
            }
        case getType(awaitingTradesActions.requestTradesResult):
            return {
                ...state,
                requestState: action.payload.requestStatus,
                items: action.payload.awaitingTrades,
            }
        case getType(awaitingTradesActions.trade):
            return {
                ...state,
                items: state.items.some(t => t.id === action.payload.trade.id)
                    ? state.items.map(t => t.id === action.payload.trade.id ? action.payload.trade : t)
                    : state.items.concat(action.payload.trade)
            }
        case getType(awaitingTradesActions.tradeAllocationCreated):
            return {
                ...state,
                items: state
                    .items
                    .map(t => t.id === action.payload.tradeId ? {
                        ...t,
                        buyerSettlementStatus: action.payload.buyerSettlementStatus,
                        sellerSettlementStatus: action.payload.sellerSettlementStatus
                    } : t)
            }
        default:
            return state;
    }
}
