import * as _ from 'lodash';
import { getType } from 'typesafe-actions';
import { brokerDealerBiddingActions as actionTypes, pushDataActions, biddingActions, bidPlacementActions, tradeConfirmActions } from '../constants';
import { biddingPushDataReducer, shouldHandlePushData } from './bidding.push.data.reducer';
import { bidPlacementReducer } from './bid-placement.reducer';
import { submitBidsConfirmationInitial, bidConfirmationReducer } from './bid-confirmation.reducer';
import { bidConfirmationActions } from '../actions/bid-confirmation.actions';
import { pushBwicActions } from '../actions/push.bwic.actions';

const initialState = {
    accessState: null,
    securities: [],
    editBid: {},
    requestingPositions: [],
    submitBidsConfirmation: submitBidsConfirmationInitial
};

export const brokerDealerBidding = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BROKER_DEALER_BIDDING_RESET:
        case biddingActions.BIDDING_RESET:
            return initialState;
        case actionTypes.BROKER_DEALER_BIDDING_STORE_SECURITIES:
            return {
                ...state,
                securities: action.securities
            };
        case actionTypes.BROKER_DEALER_ACCESS_STATE:
            return {
                ...state,
                accessState: action.accessState
            };
        case biddingActions.LIVE_BIDDING_STAGE_2_TIMEOUT_END:
            return _.cloneDeep(state);
        case tradeConfirmActions.SET_TRADED_POSITION_STATUS:
            return {
                ...state,
                securities: state.securities.map(s => {
                    if (action.positions.includes(s.id)) {
                        return { ...s, status: action.status, rejectReason: action.rejectReason }
                    }
                    return s;
                }),
            };
        case bidPlacementActions.BID_PLACEMENT_SET_BID_CAN_SUBMIT:
        case bidPlacementActions.BID_PLACEMENT_BID_CHANGE:
        case bidPlacementActions.BID_PLACEMENT_AXED_CHANGE:
        case bidPlacementActions.BID_PLACEMENT_FINAL_CHANGE:
        case bidPlacementActions.BID_PLACEMENT_PASS_CHANGE:
        case bidPlacementActions.BID_PLACEMENT_SET_BID_ERRORS:
        case bidPlacementActions.COMMISSION_CHANGE:
        case bidPlacementActions.REVERT:
        case bidPlacementActions.REVERT_ALL:
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2:
            return {
                ...state,
                editBid: bidPlacementReducer(state.editBid, action)
            }
        case getType(bidConfirmationActions.confirmation):
        case getType(bidConfirmationActions.reset):
        case getType(bidConfirmationActions.submitInProgress):
        case getType(bidConfirmationActions.submitResults):
            return {
                ...state,
                submitBidsConfirmation: bidConfirmationReducer(state.submitBidsConfirmation, action)
            };
        // Push data
        case pushDataActions.PUSH_DATA_NEW_BID: {
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                editBid: {
                    ...state.editBid,
                    [action.positionId]: {
                        commission: state.editBid[action.positionId]?.commission,
                        commissionError: state.editBid[action.positionId]?.commissionError,
                    }
                },
                securities: state.securities.map(p => p.id === action.positionId
                    ? {
                        ...p,
                        bidLock: action.bid.modifiedDate,
                        bids: [{ ...action.bid }],
                        latestBidSubmission: action.bid.createdUtc,
                    }
                    : p
                )
            };
        }
        case pushDataActions.PUSH_DATA_AXED_FINAL_CHANGE: {
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, bidId, axed, final, modifiedDate } = action;

            let editBid = state.editBid;
            if (state.editBid[positionId]) {
                editBid = {
                    ...state.editBid,
                    [positionId]: { ...state.editBid[positionId], axed, final }
                };
            }

            return {
                ...state,
                editBid,
                securities: state.securities.map(p => p.id === positionId
                    ? {
                        ...p,
                        bidLock: modifiedDate,
                        bids: p.bids && p.bids.length
                            ? p.bids.map(b => b.id === bidId ? { ...b, axed, final } : b)
                            : p.bids
                    }
                    : p
                )
            };
        }
        case pushDataActions.PUSH_DATA_OPEN_BIDDING_STAGE2_LEVEL:
            if (!state.securities) return state;

            return {
                ...state,
                securities: state.securities.map(s => s.id === action.positionId
                    ? {
                        ...s,
                        openBiddingStage2Level: action.level,
                        tiedForBest: action.tiedForBest,
                        latestBidSubmission: action.sentDateUtc,
                    } : s
                )
            };
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_START_STAGE2:
            if (!state.securities?.length || !action.canDealerBidOnPositions) return state;

            const editBid = {};
            // Reset edit state for positions if canBidOnStage2 === false
            action.canDealerBidOnPositions.forEach(({ positionId, canBidOnStage2 }) => {
                if (canBidOnStage2 && state.editBid[positionId]) {
                    editBid[positionId] = { ...state.editBid[positionId] };
                    // Axed-Final flags are not available in Stage 2
                    delete editBid['axed'];
                    delete editBid['final'];
                }
            });

            return {
                ...state,
                editBid,
                securities: state.securities.map(s => {
                    const found = action.canDealerBidOnPositions.find(c => s.id === c.positionId);
                    if (found) {
                        return {
                            ...s,
                            canBidOnStage2: found.canBidOnStage2,
                            isStage2Participant: found.canBidOnStage2,
                            tiedForBest: found.tiedForBest,
                            openBiddingStage2Level: found.level
                        }
                    }

                    return s;
                })
            }
        case pushDataActions.PUSH_DATA_NEW_PX_TALK:
        case pushDataActions.PUSH_DATA_QUICK_FEEDBACK:
        case pushDataActions.PUSH_DATA_TRADE:
        case pushDataActions.PUSH_DATA_TRADED_AWAY:
            return biddingPushDataReducer(state, action);
        case getType(pushBwicActions.bidRequest): {
            if (!shouldHandlePushData(state, action)) return state;

            const { positionId, bidRequest } = action;

            return {
                ...state,
                securities: state.securities?.map(s => s.id === positionId
                    ? { ...s, bidRequest }
                    : s
                )
            };
        }
        default:
            return state;
    }
};
