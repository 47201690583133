import { getType } from 'typesafe-actions';
import { pushBwicActions, sellerBuysideActions } from '../actions';
import {
    bwicHistoryActions as actionTypes,
    pushDataActions,
    biddingActions
} from '../constants';
import { RequestState } from '../constants/request-state';
import { BwicVisitorType } from '../types/state/BiddingState';
import { user } from '../user/user';

const initialState = {
    actions: null,
    bwicReferenceName: null,
    positionId: null,
    ticker: null,
    visible: false,
    requestState: RequestState.none,
    visitorType: null
}

export const bwicHistory = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_BWIC_HISTORY:
            return {
                ...state,
                bwicReferenceName: action.bwicReferenceName,
                positionId: action.positionId,
                actions: action.historyActions
            };
        case actionTypes.LOAD_HISTORY_REQUEST_STATE:
            return {
                ...state,
                requestState: action.requestState
            };
        case actionTypes.SHOW_BWIC_HISTORY:
            return {
                ...state,
                visible: action.visible,
                positionId: action.positionId,
                ticker: action.ticker
            };
        case actionTypes.MARK_HISTORY_MESSAGES_AS_READ:
            return {
                ...state,
                actions: Array.isArray(state.actions)
                    ? state.actions
                        .map(a => action.messages
                            .some(m => m === a.referenceName) ? { ...a, isRead: true } : a)
                    : state.actions
            };

        case pushDataActions.PUSH_DATA_BWIC_STATUS_CHANGE:
        case pushDataActions.PUSH_DATA_PUBLIC_COLORS:
        case pushDataActions.PUSH_DATA_TRADE:
        case pushDataActions.PUSH_DATA_TRADED_AWAY:
        case pushDataActions.PUSH_DATA_NEW_BID:
        case pushDataActions.PUSH_DATA_AXED_FINAL_CHANGE:
        case pushDataActions.PUSH_DATA_QUICK_FEEDBACK:
        case getType(pushBwicActions.bidRequest):
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_START_STAGE2:
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                actions: (state.actions || []).concat(action.historyAction ?? action.payload.historyAction)
            };
        case pushDataActions.PUSH_DATA_NEW_PX_TALK: {
            if (shouldHandlePushData(state, action) && (
                state.visitorType === BwicVisitorType.Seller ||
                user.current()?.companyId === action.priceTalk.company.id)) {
                return {
                    ...state,
                    actions: (state.actions || []).concat(action.historyAction)
                };
            }

            return state;
        }
        case getType(sellerBuysideActions.init):
            return { ...state, visitorType: BwicVisitorType.Buyer };
        case biddingActions.BIDDING_STORE_VISITOR_TYPE:
            return { ...state, visitorType: action.visitorType };
        case getType(sellerBuysideActions.reset):
        case biddingActions.BIDDING_RESET:
            return { ...initialState };
        case actionTypes.RESET:
            return { ...initialState, visitorType: state.visitorType };
        default: return state;
    }
}

function shouldHandlePushData(state, action) {
    return (
        state.bwicReferenceName === action.bwicReferenceName &&
        action.historyAction &&
        !action.historyAction.ignoreInHistory
    );
}

