import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { FavoriteBrokerDealersState } from '../types/state/FavoriteBrokerDealersState';
import { favoriteBrokerDealersActions } from '../actions/favorite-broker-dealers.actions';
import { RequestState } from '../constants/request-state';

const initialState: FavoriteBrokerDealersState = {
    favorites: {},
    requestState: RequestState.none,
    isUpdatingFavorite: {}
};

export const favoriteBrokerDealers = (state = initialState, action: AnyAction): FavoriteBrokerDealersState => {
    switch (action.type) {
        case getType(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersRequest):
            return {
                ...state,
                requestState: RequestState.request
            };
        case getType(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersSuccess):
            return {
                ...state,
                requestState: RequestState.success,
                favorites: action.payload.favorites
            };
        case getType(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersFailure):
            return {
                ...state,
                requestState: RequestState.failure,
            };
        case getType(favoriteBrokerDealersActions.updateFavoriteBrokerDealerRequest):
            return {
                ...state,
                isUpdatingFavorite: { ...state.isUpdatingFavorite, [action.payload.dealerId]: true }
            };
        case getType(favoriteBrokerDealersActions.updateFavoriteBrokerDealerSuccess): {
            const isUpdatingFavorite = { ...state.isUpdatingFavorite };
            delete isUpdatingFavorite[action.payload.dealerId];
            return {
                ...state,
                isUpdatingFavorite,
                favorites: action.payload.favorites
            };
        }
        case getType(favoriteBrokerDealersActions.updateFavoriteBrokerDealerFailure): {
            const isUpdatingFavorite = { ...state.isUpdatingFavorite };
            delete isUpdatingFavorite[action.payload.dealerId];
            return {
                ...state,
                isUpdatingFavorite
            };
        }
        case getType(favoriteBrokerDealersActions.reset):
            return { ...initialState };
        default:
            return state;
    }
};
