import moment from 'moment';
import { constants } from "../constants";
import { editParsedBwicActions as actionTypes } from '../constants';
import { estId, getTimeZones } from '../constants/timeZones';

const initialState = {
    isInitCompleted: false,
    errors: {},
    cancelDialog: {
        visible: false
    },
    emailReceivedDate: moment().format(constants.dateFormatISO8601),
    isSaving: false,
    saved: false,
    timeZones: getTimeZones(),
    timeZone: estId
};

export const editParsedBwic = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_COMPLETED:
            return {
                ...state,
                isInitCompleted: true
            };
        case actionTypes.STORE_ORIGINAL_BWIC:
            return {
                ...state,
                bwic: action.bwic
            };
        case actionTypes.CHANGE_DATE:
            return {
                ...state,
                date: action.date
            };
        case actionTypes.DAYS_TO_SETTLE:
            return {
                ...state,
                daysToSettle: action.days
            };
        case actionTypes.TIME_ZONE:
            return {
                ...state,
                timeZone: action.timeZone
            };
        case actionTypes.BIDS_DUE:
            return {
                ...state,
                bidsDue: action.time
            };
        case actionTypes.GOOD_UNTIL:
            return {
                ...state,
                goodUntil: action.time
            };
        case actionTypes.RULES_TEXT:
            return {
                ...state,
                rulesText: action.rulesText
            };
        case actionTypes.RESERVE_LEVELS_APPLY_CHANHE:
            return {
                ...state,
                reserveLevelsApply: action.value
            };

        case actionTypes.CAN_TRADE_EARLY_CHANGE:
            return {
                ...state,
                canTradeEarly: action.value
            };

        case actionTypes.CAN_BID_EARLY_CHANGE:
            return {
                ...state,
                canBidEarly: action.value
            };
        case actionTypes.PARTIAL_BIDS_ACCEPTED_CHANHE:
            return {
                ...state,
                partialBidsAccepted: action.value
            };
        case actionTypes.BIDS_GOOD_FOR_HOURS_CHANGE:
            return {
                ...state,
                bidsGoodForHours: action.value
            };
        case actionTypes.BIDS_GOOD_FOR_MINUTES_CHANGE:
            return {
                ...state,
                bidsGoodForMinutes: action.value
            };
        case actionTypes.STAGE_1_TIME_CHANGE:
            return {
                ...state,
                stage1EndDateUtc: action.value
            };
        case actionTypes.STAGE_2_TIME_CHANGE:
            return {
                ...state,
                stage2EndDateUtc: action.value
            };
        case actionTypes.HARD_CUT_OFF_NUMBER_CHANGE:
            return {
                ...state,
                hardCutOff: action.value
            };
        case actionTypes.STAGE_2_PARTICIPANTS_CHANGE:
            return {
                ...state,
                improverCount: action.value
            };
        case actionTypes.BWIC_PROCESS_TYPE_CHANGE:
            return {
                ...state,
                bwicProcessType: action.value
            }
        case actionTypes.VALIDATE:
            return {
                ...state,
                isValid: action.validation.isValid,
                errors: action.validation.errors
            };
        case actionTypes.CANCEL_DIALOG:
            return {
                ...state,
                cancelDialog: action.cancelDialog
            };
        case actionTypes.SET_EMAIL_RECEIVED_DATE:
            return {
                ...state,
                emailReceivedDate: action.payload.date
            };
        case actionTypes.SAVING_STATE:
            return {
                ...state,
                isSaving: action.isSaving
            };
        case actionTypes.BWIC_SAVED:
            return {
                ...state,
                saved: true
            };
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
}
