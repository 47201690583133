import { cloneDeep, first, isNil, last } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';
import { createFilterActions } from '../actions/filter.actions';
import { portfolioInitialFilters } from '../components/portfolio/filter/portfolioInitialFilters';
import { accountActions, amrArrangerPipelineFilters, pipelineFilters } from '../constants';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { TFilter, FilterState, TFilterType, BwicFilterType } from '../types/filters/FilterState';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { RequestState } from '../constants/request-state';
import { FilterBooleanGroup, FilterDateGroup, FilterSelectGroup } from '../types/filters/FilterGroup';
import { FilterOption, FilterOptionBase } from '../types/filters/FilterOption';
import { RedirectFilterOption } from '../types/filters/RedirectFilterOption';
import { filterUtils } from '../utils/filtering/filter.utils';
import { numericUtils } from '../utils';
import { deserializeFilters } from '../utils/filtering/serializers/amr-pipeline/deserializeFilters';
import { inventoryFilters } from '../components/inventory/filter/inventoryFilters';
import { bdInventoryFilters } from '../components/inventory/bd/filter/bdInventoryFilters';
import { bwicMonitorBuyFilters, bwicMonitorFilters, bwicMonitorSellFilters } from '../types/state/AllBwicsState';

function getDefaultFilter(filterType: TFilterType): TFilter<typeof filterType> {
    switch (filterType) {
        case PipelineType.ArrangerPipeline:
            return amrArrangerPipelineFilters.defaultFilter;
        case PipelineType.Deals:
        case PipelineType.IOIs:
            return pipelineFilters.defaultFilter;
        case BwicFilterType.Portfolio:
            return portfolioInitialFilters.defaultFilter;
        case BwicFilterType.Inventory:
            return inventoryFilters.defaultFilter;
        case BwicFilterType.BDInventory:
            return bdInventoryFilters.defaultFilter;
        case BwicFilterType.BwicMonitor:
            return bwicMonitorFilters.defaultFilters();
        case BwicFilterType.BwicMonitorSell:
            return bwicMonitorSellFilters.defaultFilters;
        case BwicFilterType.BwicMonitorBuy:
            return bwicMonitorBuyFilters.defaultFilters;
        default:
            return {};
    }
}

function getInitialVisibleFilters(filterType: TFilterType): string[] {
    switch (filterType) {
        case PipelineType.ArrangerPipeline:
            return amrArrangerPipelineFilters.visibleFilters;
        case PipelineType.Deals:
        case PipelineType.IOIs:
            return pipelineFilters.visibleFilters;
        case BwicFilterType.Portfolio:
            return portfolioInitialFilters.visibleFilters;
        case BwicFilterType.Inventory:
            return inventoryFilters.visibleFilters
        case BwicFilterType.BDInventory:
            return bdInventoryFilters.visibleFilters;
        case BwicFilterType.BwicMonitor:
            return bwicMonitorFilters.visibleFilters;
        case BwicFilterType.BwicMonitorSell:
            return bwicMonitorSellFilters.visibleFilters;
        case BwicFilterType.BwicMonitorBuy:
            return bwicMonitorBuyFilters.visibleFilters;
        default:
            return [];
    }
}

function getInitialState(filterType: TFilterType): FilterState<typeof filterType> {
    return {
        filter: getDefaultFilter(filterType),
        visibleFilters: getInitialVisibleFilters(filterType),
        lastAppliedFilter: undefined,
        selectedFilter: undefined,
        redirectFilters: null,
        filterChanged: false,
        filterApplied: true,
        filterModified: false,
    };
}

export const createFilter = (filterType: TFilterType) => {
    const actions = createFilterActions(filterType);
    type TFilterActions = ActionType<(typeof actions)[keyof typeof actions]>;

    return (state: FilterState<typeof filterType> = getInitialState(filterType), action: TFilterActions) => {
        const initialState = getInitialState(filterType);

        if (action.type === accountActions.LOGOUT) {
            return initialState;
        }
        if (action.payload?.filterType !== filterType) {
            return state;
        }

        switch (action.type) {
            case getType(actions.init): {
                return {
                    ...state,
                    visibleFilters: state.redirectFilters
                        ? visibleRedirectFilters(state, state.redirectFilters)
                        : state.visibleFilters,
                    initialFilter: { ...action.payload.filter },
                    filter: state.redirectFilters
                        ? applyRedirect(action.payload.filter, state.redirectFilters, filterType)
                        : { ...action.payload.filter }
                };
            }
            case getType(actions.resetFilter): {
                const filter = resetFilter<typeof filterType>(state.filter, state.initialFilter);

                return {
                    ...state,
                    filterApplied: true,
                    filterChanged: false,
                    filter,
                    lastAppliedFilter: undefined,
                };
            }
            case getType(actions.resetFiltersVisibility): {
                return {
                    ...state,
                    visibleFilters: getInitialVisibleFilters(filterType),
                };
            }
            case getType(actions.updateFilterState):
                return {
                    ...state,
                    filterApplied: false,
                    filterChanged: filterUtils.isFilterChanged(filterType, state.filter, state.initialFilter),
                    filterModified: filterUtils.isFilterChanged(filterType, state.filter, state.selectedFilter),
                };
            case getType(actions.applyFilter):
                return {
                    ...state,
                    filterApplied: true,
                    filter: setFilterApplied<typeof filterType>(state.filter, true),
                    lastAppliedFilter: setFilterApplied<typeof filterType>(state.filter, true),
                };
            case getType(actions.storeFilterData.request):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            requestState: RequestState.request,
                        },
                    },
                };
            case getType(actions.storeFilterData.success):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            filter: action.payload.data,
                            requestState: RequestState.success,
                        },
                    },
                };
            case getType(actions.storeFilterData.failure):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            requestState: RequestState.failure,
                        },
                    },
                };
            case getType(actions.filterSelectChange):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: state.filter[action.payload.filterName].filter.map((r: FilterOption) =>
                                action.payload.option === r.value ? { ...r, selected: !r.selected } : r,
                            ),
                        },
                    },
                };
            case getType(actions.changeRangeFilter):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: action.payload.value,
                        },
                    },
                };
            case getType(actions.filterDateClearSelectedOptions):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                selectedOption: undefined,
                                options: {
                                    ...initialState.filter[action.payload.filterName].filter.options,
                                },
                            },
                        },
                    },
                };
            case getType(actions.filterDateSelectOption): {
                const { option, filterName } = action.payload;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [filterName]: {
                            ...state.filter[filterName],
                            isApplied: false,
                            filter: {
                                selectedOption: option,
                                options: {
                                    ...initialState.filter[filterName].filter.options,
                                    customYearsRange: numericUtils.isNumber(option.from) && numericUtils.isNumber(option.to)
                                        ? { from: String(option.from), to: String(option.to) }
                                        : initialState.filter[filterName].filter.options.customYearsRange,
                                },
                            },
                        },
                    },
                };
            }
            case getType(actions.filterDateTimeOptionSelected):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...state.filter[action.payload.filterName].filter,
                                selectedOption: action.payload.option,
                            },
                        },
                    },
                };
            case getType(actions.filterDateSelectYearsRange):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...state.filter[action.payload.filterName].filter,
                                options: {
                                    ...state.filter[action.payload.filterName].filter.options,
                                    customYearsRange: action.payload.option,
                                },
                            },
                        },
                    },
                };
            case getType(actions.filterDateSelectCustomRange):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...state.filter[action.payload.filterName].filter,
                                options: {
                                    ...state.filter[action.payload.filterName].filter.options,
                                    customDateRange: action.payload.option,
                                },
                            },
                        },
                    },
                };
            case getType(actions.filterDateTimeSelectCustomRange):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...state.filter[action.payload.filterName].filter,
                                selectedOption: {
                                    ...state.filter[action.payload.filterName].filter.selectedOption,
                                    ...action.payload.option,
                                },
                            },
                        },
                    },
                };
            case getType(actions.filterRadioChange):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...state.filter[action.payload.filterName].filter,
                                selectedOption: action.payload.option,
                            },
                        },
                    },
                };
            case getType(actions.filterRadioClearOption):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...initialState.filter[action.payload.filterName].filter,
                            },
                        },
                    },
                };
            case getType(actions.filterSelectSelectAll):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: [
                                ...state.filter[action.payload.filterName].filter.map((f: FilterOption) => ({
                                    ...f,
                                    selected: f.text.toLowerCase().includes(action.payload.searchTerm)
                                        ? true
                                        : f.selected,
                                })),
                            ],
                        },
                    },
                };
            case getType(actions.filterSelectClearAll):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: [
                                ...state.filter[action.payload.filterName].filter.map((f: FilterOption) => ({
                                    ...f,
                                    selected: false,
                                })),
                            ],
                        },
                    },
                };
            case getType(actions.filterRangeClearSelectedOptions):
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...state.filter[action.payload.filterName],
                            isApplied: false,
                            filter: {
                                ...initialState.filter[action.payload.filterName].filter,
                            },
                        },
                    },
                };
            case getType(actions.filterVisibilityChange):
                const visible = !state.filter[action.payload.filterName].visible;
                const selected = !state.filter[action.payload.filterName].selected;
                const currentFilter = state.filter[action.payload.filterName];

                return {
                    ...state,
                    visibleFilters: visible
                        ? [...state.visibleFilters, action.payload.filterName]
                        : state.visibleFilters.filter(f => f !== action.payload.filterName),
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...currentFilter,
                            visible,
                            selected,
                            filter: visible
                                ? currentFilter.filter
                                : state.initialFilter
                                    ? state.initialFilter[action.payload.filterName].filter
                                    : getDefaultFilter(filterType)[action.payload.filterName].filter,
                        },
                    },
                };
            case getType(actions.makeFilterVisible): {
                const currentFilter = state.filter[action.payload.filterName];

                return {
                    ...state,
                    visibleFilters: [...state.visibleFilters, action.payload.filterName],
                    filter: {
                        ...state.filter,
                        [action.payload.filterName]: {
                            ...currentFilter,
                            visible: true,
                            selected: true,
                        },
                    },
                };
            }
            case getType(actions.setFilterByReferenceName):
                return {
                    ...state,
                    filterModified: false,
                    selectedFilterReferenceName: action.payload.selectedFilterReferenceName,
                    selectedFilter: action.payload.selectedFilter,
                };
            case getType(actions.selectFilterFromConfig):
                if (state.redirectFilters) {
                    return {
                        ...state,
                        redirectFilters: null,
                    };
                }

                const { selectedFilter, referenceName } = action.payload;

                if (!selectedFilter) {
                    return {
                        ...state,
                    };
                }

                const selectedFilterReferenceName = referenceName || selectedFilter.referenceName;
                const deserializedFilter = deserializeFilters(selectedFilter, state.filter, filterType);

                let visibleFilters = [...initialState.visibleFilters];

                const newFilterState = Object.entries(deserializedFilter).reduce((acc: any, [key, configFilter]) => {
                    const { initialFilter } = state;

                    // Bypass state change if initial filter is not initialized
                    if (!initialFilter || !initialFilter[key]) {
                        return acc;
                    }

                    const stateFilter = initialFilter[key];

                    // Return initial filter value if config filter is undefined
                    if (!deserializedFilter[key as keyof typeof deserializedFilter]) {
                        return {
                            ...acc,
                            [key]: { ...stateFilter },
                        };
                    }

                    // Add key to visible filters list, if user config filter is defined and
                    // default visible filters list doesn't include this key
                    visibleFilters =
                        !!configFilter && !initialState.visibleFilters.includes(key)
                            ? [...visibleFilters, key]
                            : visibleFilters;

                    return {
                        ...acc,
                        [key]: {
                            ...stateFilter,
                            filter: configFilter || stateFilter.filter,
                            visible: !!configFilter,
                            selected: !!configFilter,
                            isApplied: !!configFilter,
                        },
                    };
                }, {});

                const mergedState = {
                    ...state.filter,
                    ...newFilterState,
                };

                return {
                    ...state,
                    filterModified: false,
                    selectedFilter: mergedState,
                    selectedFilterReferenceName,
                    filterApplied: true,
                    filterChanged: true,
                    filter: mergedState,
                    visibleFilters,
                };
            case getType(actions.setRedirectFilters):
                return {
                    ...state,
                    redirectFilters: action.payload.filter,
                };
            case getType(actions.resetFilterState):
                return initialState;
            case getType(actions.resetSelectedFilter):
                return {
                    ...state,
                    selectedFilterReferenceName: undefined,
                    selectedFilter: undefined
                };
            default:
                return state;
        }
    };
};

const resetFilter = <FType extends TFilterType>(filter: TFilter<FType>, initialFilter: TFilter<FType> | undefined) => {
    const result = cloneDeep(filter);
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
        const filterName = key;
        result[filterName] = initialFilter ? { ...initialFilter[filterName] } : { ...filter[filterName] };
        result[filterName].visible = filter[filterName].visible;
        result[filterName].selected = filter[filterName].selected;
    });
    return result;
};

function setFilterApplied<T extends TFilterType>(filter: TFilter<T>, isApplied: boolean) {
    const filterKeys = Object.keys(filter);

    return filterKeys.reduce((acc, key) => {
        const currentFilter: any = filter[key];

        if (Array.isArray(currentFilter)) {
            return { ...acc, [key]: currentFilter };
        }

        return {
            ...acc,
            [key]: {
                ...filter[key],
                isApplied,
            },
        };
    }, {});
}

function setFilterOption(filter: FilterSelectGroup, options?: FilterOptionBase[]) {
    const filterData = filter.filter;

    return isNil(options)
        ? filter
        : {
            ...filter,
            visible: true,
            selected: true,
            disabled: false,
            filter: filterData.map(o => {
                const optionForSelect = options.find(option => o.value === option.value);

                return optionForSelect ? { ...optionForSelect, selected: true, visible: true, disabled: false } : o;
            }),
        };
}

function setFilterBoolean(filter: FilterBooleanGroup, selectedOption?: boolean) {
    return isNil(selectedOption)
        ? filter
        : {
            ...filter,
            visible: true,
            selected: true,
            disabled: false,
            filter: {
                selectedOption,
            },
        };
}

function setFilterYearsRange(filter: FilterDateGroup, selectedOptions?: [number, number]) {
    return isNil(selectedOptions)
        ? filter
        : ({
            ...filter,
            visible: true,
            selected: true,
            disabled: false,
            filter: {
                selectedOption: dateRangeFilterOptions.YearsRange,
                options: {
                    customDateRange: {
                        from: null,
                        to: null,
                    },
                    customYearsRange: {
                        from: first(selectedOptions)?.toString(),
                        to: last(selectedOptions)?.toString(),
                    },
                },
            },
        } as FilterDateGroup);
}

function setFilterDataRange(filter: FilterDateGroup, selectedOptions?: [Date, Date]) {
    return isNil(selectedOptions)
        ? filter
        : ({
            ...filter,
            visible: true,
            selected: true,
            disabled: false,
            filter: {
                selectedOption: dateRangeFilterOptions.Custom,
                options: {
                    customDateRange: {
                        from: first(selectedOptions),
                        to: last(selectedOptions),
                    },
                    customYearsRange: {
                        from: '',
                        to: '',
                    },
                },
            },
        } as FilterDateGroup);
}

function visibleRedirectFilters<T extends TFilterType>(state: FilterState<T>, redirectFilters: RedirectFilterOption) {
    const { visibleFilters } = state;

    return Object.keys(redirectFilters).reduce((acc: string[], key: string) => {
        if (redirectFilters[key as keyof RedirectFilterOption] && visibleFilters.includes(key)) {
            return acc;
        }

        return [...acc, key];
    }, visibleFilters);
}

function applyRedirect<T extends TFilterType>(
    initialFilter: TFilter<T>,
    redirectFilters: RedirectFilterOption,
    filterType: T,
) {
    const cloned = cloneDeep(initialFilter);

    switch (filterType) {
        case PipelineType.ArrangerPipeline:
            return {
                ...cloned,
                managers: setFilterOption(cloned.managers as FilterSelectGroup, redirectFilters.managers),
            };
        case PipelineType.Deals:
        case PipelineType.IOIs:
            return {
                ...cloned,
                classNames: setFilterOption(cloned.classNames, redirectFilters.classNames),
                ratings: setFilterOption(cloned.ratings, redirectFilters.ratings),
                statuses: setFilterOption(cloned.statuses, redirectFilters.statuses),
                transactionTypes: setFilterOption(cloned.transactionTypes, redirectFilters.transactionTypes),
                managers: setFilterOption(cloned.managers as FilterSelectGroup, redirectFilters.managers),
                arrangers: setFilterOption(cloned.arrangers as FilterSelectGroup, redirectFilters.arrangers),
                trustees: setFilterOption(cloned.trustees, redirectFilters.trustees),
                amrDeal: setFilterBoolean(cloned.amrDeal, redirectFilters.amrDeal),
                euCompliance: setFilterBoolean(cloned.euCompliance, redirectFilters.euCompliance),
                esg: setFilterBoolean(cloned.esg, redirectFilters.esg),
                staticDeal: setFilterBoolean(cloned.staticDeal, redirectFilters.staticDeal),
                currency: setFilterOption(cloned.currency, redirectFilters.currency),
                reinvestmentEnd: setFilterYearsRange(cloned.reinvestmentEnd, redirectFilters.reinvestmentEnd),
                nonCallEnd: setFilterYearsRange(cloned.nonCallEnd, redirectFilters.nonCallEnd),
                pricingDate: setFilterDataRange(cloned.pricingDate, redirectFilters.pricingDate),
            };
        default: {
            return cloned;
        }
    }
}
