import { formsActions as actionTypes } from '../constants';

const initialState = {
    triggerValidation: false,
    displayErrors: false
}

export const forms = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DISPLAY_VALIDATION_ERRORS:
            return action.activate ? {
                triggerValidation: !state.triggerValidation,
                displayErrors: true
            } : initialState;
        default:
            return state;
    }
}
