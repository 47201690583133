import { getType, ActionType } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { activeInventorySecurityActions } from '../../actions/active-inventory-security.actions';
import { ActiveInventorySecurityState } from '../../types/state/ActiveInventorySecurityState';

export type TActiveInventorySecuritiesActionType =
    ActionType<
        typeof activeInventorySecurityActions.requestActiveInventorySecurities |
        typeof activeInventorySecurityActions.requestActiveInventorySecuritiesSuccess |
        typeof activeInventorySecurityActions.requestActiveInventorySecuritiesError |
        typeof activeInventorySecurityActions.reset
    >;

export const activeInventorySecurityInitialState: ActiveInventorySecurityState = {
    items: {},
    requestState: RequestState.none
}

export function activeInventorySecuritiesReducer(
    state: ActiveInventorySecurityState = activeInventorySecurityInitialState,
    action: TActiveInventorySecuritiesActionType): ActiveInventorySecurityState  {
    switch (action.type) {
        case getType(activeInventorySecurityActions.requestActiveInventorySecurities):
            return {
                ...state,
                requestState: RequestState.request,
                items: {}
            };
        case getType(activeInventorySecurityActions.requestActiveInventorySecuritiesSuccess):
            return {
                ...state,
                requestState: RequestState.success,
                items: aggregate(action.payload.securityIds)
            };
        case getType(activeInventorySecurityActions.requestActiveInventorySecuritiesError):
            return {
                ...activeInventorySecurityInitialState,
                requestState: RequestState.failure
            };
        case getType(activeInventorySecurityActions.reset):
            return {
                ...activeInventorySecurityInitialState
            }
        default: return state;
    }
}

const aggregate = (securityIds: number[]) => {
    const aggregatedList: {[securityId: number]: number} = {}
    securityIds.forEach(securityId => {
        aggregatedList[securityId] = aggregatedList[securityId]
            ? ++aggregatedList[securityId]
            : 1;
    });
    return aggregatedList;
}
