import { RequestState } from "../../constants/request-state";
import { getType } from "typesafe-actions";
import { TPageConfigActions, pageConfigActions } from "../../actions/page-config.actions";
import { PageConfigState } from "../../types/state/entities/PageConfigState";

export const pageConfigDefaultState = {
    requestState: RequestState.none,
    defaultConfigRequestState: RequestState.none,
    defaultConfigs: {},
    configs: {}
}

export function pageConfigReducer(state: PageConfigState, action: TPageConfigActions): PageConfigState {
    switch (action.type) {
        case getType(pageConfigActions.getConfigs.request):
            return {
                ...state,
                requestState: RequestState.request
            };
        case getType(pageConfigActions.getDefaultConfig.request):
            return {
                ...state,
                defaultConfigRequestState: RequestState.request
            };
        case getType(pageConfigActions.getConfigs.success):
            return {
                ...state,
                configs: action.payload.configs,
                requestState: RequestState.success
            }
        case getType(pageConfigActions.saveColumns.success):
            return {
                ...state,
                configs: {
                    ...state.configs,
                    [action.payload.configType]: {
                        ...state.configs[action.payload.configType],
                        columns: action.payload.columns
                    }
                }
            }
        case getType(pageConfigActions.getDefaultConfig.success):
            return {
                ...state,
                defaultConfigRequestState: RequestState.success,
                defaultConfigs: {
                    ...state.defaultConfigs,
                    [action.payload.configType]: {
                        ...state.defaultConfigs[action.payload.configType],
                        ...action.payload.config,
                    }
                }
            }
        case getType(pageConfigActions.getConfigs.failure):
            return {
                ...state,
                requestState: RequestState.failure
            }
        default:
            return state
    }
}
