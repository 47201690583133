import { contactsSaveActions as actionTypes, contactsSaveViews } from '../constants';

const initialState = {
    view: contactsSaveViews.grid,
    isLoading: false,
    isEdit: false,
    isSaved: false,
    companies: [],
    conflicts: null,
    initialContacts: null,
    deletedContacts: [],
    companyDomains: [],
    companiesLookup: {
        companies: [],
        searchTerm: ''
    }
};

export const contactsSave = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET:
            return initialState;
        case actionTypes.CONTACT_SAVE_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case actionTypes.LOAD_COMPANIES:
            return {
                ...state,
                companies: action.companies
            };
        case actionTypes.LOAD_COMPANY_DOMAINS:
            return {
                ...state,
                companyDomains: action.companyDomains
            };
        case actionTypes.INITIAL_CONTACTS:
            return {
                ...state,
                initialContacts: action.contacts
            };
        case actionTypes.COMPANIES_LOOKUP:
            return {
                ...state,
                companiesLookup: action.lookup
            };
        case actionTypes.SWITCH_VIEW:
            return {
                ...state,
                view: action.view
            };
        case actionTypes.EDIT_MODE:
            return {
                ...state,
                isEdit: action.isEdit
            };
        case actionTypes.STORE_CONFLICTS:
            return {
                ...state,
                conflicts: action.conflicts
            };
        case actionTypes.RESET_CONFLICTS:
            return {
                ...state,
                conflicts: null,
                deletedContacts: state.deletedContacts.filter(id =>
                    !state.conflicts.some(c =>
                        c.duplicates.some(d => d.isDeleted && d.id === id)))
            };

        case actionTypes.CONTACTS_SAVED:
            return {
                ...state,
                isSaved: true
            };
        case actionTypes.DELETE_CONTACT:
            return {
                ...state,
                deletedContacts: state.deletedContacts.concat(action.contacts)
            };
        case actionTypes.DELETE_DUPLICATE_CONTACT:
            return {
                ...state,
                conflicts: state.conflicts.map(c => ({
                    ...c,
                    duplicates: c.duplicates.map(d =>
                        (d.id && action.contact.id && d.id === action.contact.id) ||
                        (d.contactIndex != null && action.contact.contactIndex != null && d.contactIndex === action.contact.contactIndex)
                            ? { ...d, isDeleted: true }
                            : d
                    )
                }))
            };
        case actionTypes.ENSURE_CONFLICTS_RESOLVED:
            return {
                ...state,
                conflicts: state.conflicts.map(c => ({
                    ...c,
                    isResolved: c.duplicates.filter(d => !d.isDeleted).length <= 1
                }))
            };
        default:
            return state;
    }
}
