import { EntityListState } from '../../types/state/entities/EntityListState';
import { RequestState } from '../../constants/request-state';
import { ActionType } from '../../types/ActionType';
import { getType } from 'typesafe-actions';
import { brokerDealerUserListActions } from '../../actions/broker-dealer-user-list.actions';
import { UserSlim } from '../../types/account/UserSlim';

export const brokerDealerUserListInitialState: EntityListState<UserSlim> = {
    items: [],
    requestState: RequestState.none
};

export function brokerDealerUserListReducer(state = brokerDealerUserListInitialState, action: ActionType<any>) {
    switch (action.type) {
        case getType(brokerDealerUserListActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(brokerDealerUserListActions.requestResult):
            return {
                items: action.payload.result || [],
                requestState: action.payload.requestState
            };
        case getType(brokerDealerUserListActions.resetEntities):
            return { ...brokerDealerUserListInitialState };
        default:
            return state;
    }
}
