import { constants, gridActions as actionTypes, uploadStatus } from '../constants';
import { OrderByDirection } from '../types/OrderByDirection';

const initialState = {
    searchTerm: '',
    lastAppliedSearchTerm: '',
    orderBy: {
        columnName: undefined,
        direction: OrderByDirection.asc
    },
    dataItems: [],
    selection: [],
    upload: { status: uploadStatus.none, progress: 0 },
    isDataProcessing: false,
    inputBlocked: false,
    mountedKey: 0,
    isValid: false,
    rowsLimit: constants.gridRowsLimit,
    position: {
        index: 0,
        editing: false,
        editingValue: ''
    },
    minDrafts: constants.gridRowsLimit
};

export const grid = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ROWS_LIMIT:
            return {
                ...state,
                rowsLimit: action.rowsLimit,
                minDrafts: action.minDrafts
            };
        case actionTypes.GRID_SET_MOUNTED_KEY:
            return {
                ...state,
                mountedKey: action.payload.key
            };
        case actionTypes.POSITION_CHANGE:
            return {
                ...state,
                selection: [],
                position: action.position
            };
        case actionTypes.APPLY_EDIT: {
            const { position } = state;
            const dataItems = state.dataItems.map((dataItem, index) =>
                position.index === index
                    ? {
                        ...dataItem,
                        [position.columnName]: action.editingValue,
                        errors: action.errors ?? []
                    }
                    : dataItem
            );

            return {
                ...state,
                position: { index: position.index, columnName: position.columnName },
                dataItems,
                selection: []
            };
        }
        case (actionTypes.UPDATE_DRAFT):
            return {
                ...state,
                dataItems: state.dataItems.map((item, index) =>
                    index === action.rowIndex ? { ...item, draft: action.draft } : item)
            };
        case (actionTypes.ORDER_BY): {

            const { orderBy, dataItems } = action.ordering;
            return {
                ...state,
                orderBy,
                dataItems,
                selection: []
            };
        }
        case (actionTypes.DELETE_ROW):
            return {
                ...state,
                selection: [],
                position: state.position && state.position.index === action.index
                    ? { index: state.position.index, columnName: state.position.columnName }
                    : state.position,
                dataItems: state.dataItems.filter((e, i) => i !== action.index)
            };
        case (actionTypes.DELETE_SELECTED): {

            const { dataItems, selection } = state;
            return {
                ...state,
                selection: [],
                dataItems: dataItems
                    .filter((item, index) =>
                        item.draft ||
                        !selection.some(selected => selected === index))
                    .sort((a, b) => !!a.draft - !!b.draft)
            };
        }
        case (actionTypes.DELETE_INVALID):
            if (state.isValid) {
                return state;
            }

            return {
                ...state,
                selection: [],
                dataItems: deleteItemsWithErrorReducer(state.dataItems)
            };
        case (actionTypes.DELETE_FLAGGED):

            const { dataItems } = state;
            return {
                ...state,
                selection: [],
                dataItems: dataItems
                    .filter(item => !item.isFlagged)
            };
        case (actionTypes.EDITING): {

            const position = {
                ...state.position,
                editingValue: action.editing.value,
                editingError: action.editing.errorMessage
            };

            return {
                ...state,
                position,
                selection: []
            };
        }
        case actionTypes.SETUP_HEADERS:
            return {
                ...state,
                headers: action.headers
            };
        case actionTypes.ADD_COLUMN:
            return {
                ...state,
                headers: action.positionIndex == null
                    ? state.headers.concat(action.column)
                    : state.headers
                        .slice(0, action.positionIndex)
                        .concat(action.column)
                        .concat(state.headers.slice(action.positionIndex))
            };
        case actionTypes.MOVE_COLUMN_DATA:
            return {
                ...state,
                dataItems: state.dataItems.map(i => {
                    if (i.draft) return i;
                    const changed = { ...i, [action.toColumnName]: i[action.fromColumnName] };
                    delete changed[action.fromColumnName];

                    return changed;
                })
            };
        case actionTypes.REMOVE_COLUMN:
            return {
                ...state,
                headers: state.headers.filter(h => h.name !== action.name),
                dataItems: state.dataItems.map(i => {
                    if (i.draft) return i;

                    const copy = { ...i };
                    delete copy[action.name];

                    return {
                        ...copy,
                        errors: copy.errors?.filter(e => e.columnName !== action.name)
                    }
                })
            };
        case actionTypes.ADD_DATA_ITEMS: {
            const { currentItems, newItems } = action.payload;
            const length = currentItems.length + newItems.length;

            return {
                ...state,
                selection: [],
                dataItems: currentItems
                    .concat(newItems)
                    .concat(state.dataItems.slice(length)),
            }
        };
        case actionTypes.REPLACE_DATA_ITEM:
            return {
                ...state,
                selection: [],
                dataItems: state.dataItems.map((item, itemIndex) =>
                    itemIndex === action.payload.index ? action.payload.dataItem : item)
            };
        case actionTypes.INSERT_DATA_ITEMS:
            return {
                ...state,
                selection: [],
                dataItems: state.dataItems
                    .slice(0, action.payload.index)
                    .concat(action.payload.newItems)
                    .concat(state.dataItems.slice(action.payload.index))
                    .slice(0, state.rowsLimit)
            };
        case actionTypes.APPEND_DATA_ITEMS:
            return {
                ...state,
                dataItems: [...state.dataItems, ...action.dataItems],
                selection: []
            };
        case actionTypes.SET_SELECTION:
            return {
                ...state,
                selection: action.selection
            };
        case actionTypes.VALIDATE:
            return {
                ...state,
                headers: action.validation.headers,
                dataItems: action.validation.dataItems,
                isValid: action.validation.isValid
            };
        case actionTypes.SHOW_HELP:
            return {
                ...state,
                showHelp: true
            };
        case actionTypes.HIDE_HELP:
            return {
                ...state,
                showHelp: false
            };
        case actionTypes.DATA_ITEMS_UPLOAD_STATE:
            return {
                ...state,
                upload: action.state
            };
        case actionTypes.RESET:
            return { ...initialState };
        case actionTypes.BLOCK_INPUT:
            return {
                ...state,
                inputBlocked: action.block
            };
        case actionTypes.DATA_PROCESSING:
            return {
                ...state,
                isDataProcessing: action.isDataProcessing
            };
        case actionTypes.CHANGE_ROW_ORDER: {
            const dataItems = [...state.dataItems];
            dataItems[action.payload.index] = state.dataItems[action.payload.newIndex];
            dataItems[action.payload.newIndex] = state.dataItems[action.payload.index];

            return {
                ...state,
                selection: [],
                dataItems
            };
        }
        case actionTypes.CLEAR:
            return {
                ...state,
                dataItems: state.dataItems.filter(i => i.draft)
            };
        default:
            return state;
    }
};

const deleteItemsWithErrorReducer = (dataItems) => {
    // filter non duplicate errors and drafts
    let items = dataItems
        .filter(i => !i.draft && (!i.errors || !i.errors.length || !i.errors.some(e => !e.isDuplicated)));
    while (true) {
        const duplicate = items.find(i => i.errors && i.errors.length);
        if (!duplicate) break;
        [...duplicate.errors].forEach(({ columnName }) => {
            const duplicateIndexes = items
                .filter(i => i[columnName] === duplicate[columnName])
                .map(i => items.indexOf(i));
            if (duplicateIndexes.length) {
                const alive = Math.min(...duplicateIndexes);
                const indexesToRemove = duplicateIndexes.filter(i => i !== alive);
                const filteredItems = items.filter((item, index) => !indexesToRemove.some(i => index === i));
                // remove resolved error
                filteredItems[alive] = {
                    ...filteredItems[alive],
                    errors: filteredItems[alive].errors.filter(e => e.columnName !== columnName)
                };
                /*eslint no-loop-func: 0*/
                items = filteredItems;
            }
        });
    }
    return items;
};
