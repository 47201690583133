import { ActionType, getType } from 'typesafe-actions';
import { bidConfirmationActions } from '../actions/bid-confirmation.actions';
import { SubmitBidConfurmationState } from '../types/state/BrokerDealerBiddingState';

export const submitBidsConfirmationInitial: SubmitBidConfurmationState = {
    submitBidsConfirmationVisible: false,
    securities: undefined,
    submitInProgress: false,
    submitInProgressPositionId: undefined,
    submitResult: {}
};

type TBidConfirmationActionType = ActionType<
    typeof bidConfirmationActions.submitInProgress |
    typeof bidConfirmationActions.reset |
    typeof bidConfirmationActions.submitResults |
    typeof bidConfirmationActions.confirmation
>;

export const bidConfirmationReducer = (state = submitBidsConfirmationInitial, action: TBidConfirmationActionType) => {
    switch (action.type) {
        case getType(bidConfirmationActions.confirmation):
            return {
                ...state,
                securities: action.payload.positionIdList,
                submitBidsConfirmationVisible: action.payload.positionIdList != null && action.payload.positionIdList.length > 0
            };
        case getType(bidConfirmationActions.submitInProgress):
            return {
                ...state,
                submitInProgress: action.payload.inProgress,
                submitInProgressPositionId: action.payload.positionId
            };
        case getType(bidConfirmationActions.reset):
            return { ...submitBidsConfirmationInitial };
        case getType(bidConfirmationActions.submitResults):
            return {
                ...state,
                submitResult: {
                    ...state.submitResult,
                    ...action.payload.result
                }
            };
    }
}
