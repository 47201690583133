import { changeColorActions as actionTypes } from '../constants';

const initialState = {
    color: undefined,
    ticker: undefined,
    errors: {
        isValid: true,
    },
    isNormalizing: false
};

export const changeColor = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET:
            return { ...initialState };
        case actionTypes.SET_COLOR:
            return {
                ...state,
                ticker: action.data.ticker,
                color: {
                    ...action.data.color,
                    dm: action.data.color.dm !== undefined ? +action.data.color.dm : null,
                },
            };
        case actionTypes.CHANGE_COLOR_SET_DM:
            return {
                ...state,
                color: {
                    ...state.color,
                    dm: action.payload.dm !== '' ? +action.payload.dm : null
                }
            };
        case actionTypes.TRADED_CHANGE:
            return {
                ...state,
                color: { ...state.color, traded: action.traded }
            };
        case actionTypes.LEVEL_SPECIFICATION_CHANGE:
            return {
                ...state,
                color: { ...state.color, levelSpecificationType: action.levelSpecification }
            };
        case actionTypes.LEVEL_CHANGE:
            return {
                ...state,
                color: { ...state.color, level: action.level }
            };
        case actionTypes.NORMALIZED_LEVEL_CHANGE:
            return {
                ...state,
                color: { ...state.color, normalizedLevel: action.normalizedLevel, level: action.level || state.color.level }
            };
        case actionTypes.SET_LEVEL_ERROR:
            return {
                ...state,
                errors: { ...state.errors, level: action.error, isValid: !state.errors.color && !action.error }
            };
        case actionTypes.SET_COLOR_ERROR:
            return {
                ...state,
                errors: { ...state.errors, color: action.error, isValid: !state.errors.level && !action.error }
            };
        case actionTypes.LEVEL_TYPE_CHANGE:
            return {
                ...state,
                color: { ...state.color, levelType: action.levelType }
            };
        case actionTypes.NORMALIZING:
            return {
                ...state,
                isNormalizing: action.isNormalizing
            };
        default:
            return state;
    }
}
