import { AnyAction } from "redux";
import {
    amrPipelineActions as actionTypes,
    constants,
    SORT,
} from "../constants";
import { AmrDealsState } from '../types/state/AmrDealsState';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { sortSyndicateContacts, withIndicatingRefinancedIoIs } from '../utils/amr-pipeline.utils';
import { OriginatingTransaction } from "../types/amr-pipeline/models/OriginatingTransaction";

const initialState: AmrDealsState = {
    isTransactionsSearching: false,
    isSummaryRequesting: false,
    isClassesSearching: false,
    isManagerPopupVisible: false,
    transactionsOffset: 0,
    classesOffset: 0,
    dealSortBy: "transactionStatus",
    dealSortDirection: SORT.ASC,
    classSortBy: "transactionStatus",
    classSortDirection: SORT.ASC,
    isExpanded: false,
    hasMoreTransactions: true,
    hasMoreTransactionsClasses: true,
    wasInitialAccessTracked: false,
    isExportLoad: false,
    syndicateContacts: null,
    anyIOIsExists: true,
};

export const createAmrDealsWithNamedData = (pipelineType: PipelineType) => (
    state: AmrDealsState = initialState,
    action: AnyAction
) => {
    if (action.pipelineType !== pipelineType) {
        return state;
    }

    switch (action.type) {
        case actionTypes.PIPELINE_INITIAL_LOAD:
            return {
                ...state,
                wasInitialAccessTracked: true,
            };
        case actionTypes.PIPELINE_SEARCH_TRANSACTIONS:
            return {
                ...state,
                isTransactionsSearching: action.isSearching,
            };
        case actionTypes.PIPELINE_SEARCH_CLASSES:
            return {
                ...state,
                isClassesSearching: action.isSearching,
            };
        case actionTypes.PIPELINE_DEAL_SORTING_CHANGED:
            return {
                ...state,
                dealSortBy: action.sortBy,
                transactions: [],
                transactionsOffset: 0,
                numberOfHiddenTransactions: undefined,
                dealSortDirection: action.direction,
            };
        case actionTypes.PIPELINE_CLASS_SORTING_CHANGED:
            return {
                ...state,
                classSortBy: action.sortBy,
                classes: [],
                classesOffset: 0,
                numberOfHiddenClasses: undefined,
                classSortDirection: action.direction,
            };
        case actionTypes.PIPELINE_STORE_TRANSACTIONS: {
            const transactions =
                pipelineType === PipelineType.IOIs
                    ? action.transactions.items.map((t: OriginatingTransaction) => ({
                          ...t,
                          classes: withIndicatingRefinancedIoIs(t.classes),
                      }))
                    : action.transactions.items;

            return {
                ...state,
                transactions: state.transactions
                    ? [...state.transactions, ...transactions]
                    : transactions,
                numberOfHiddenTransactions: action.transactions.numberOfHiddenItems,
                transactionsOffset:
                    state.transactionsOffset + transactions.length,
                hasMoreTransactions:
                    transactions.length ===
                    constants.defaultTransactionsSearchCriteria.count,
            };
        }
        case actionTypes.PIPELINE_STORE_CLASSES: {
            const classes =
                pipelineType === PipelineType.IOIs ? withIndicatingRefinancedIoIs(action.classes.items) : action.classes.items;

            return {
                ...state,
                classes: state.classes
                    ? [...state.classes, ...classes]
                    : classes,
                numberOfHiddenClasses: action.classes.numberOfHiddenItems,
                classesOffset: state.classesOffset + classes.length,
                hasMoreTransactionsClasses:
                    classes.length ===
                    constants.defaultTransactionsSearchCriteria.count,
            };
        }
        case actionTypes.PIPELINE_SUMMARY_REQUESTING_STATE:
            return {
                ...state,
                isSummaryRequesting: action.isLoading
            };
        case actionTypes.PIPELINE_STORE_SUMMARY:
            return {
                ...state,
                transactionsStatistic: action.transactionsSummary,
                classesStatistic: action.classesSummary
            }
        case actionTypes.PIPELINE_RESET_TRANSACTIONS:
            return {
                ...state,
                classesOffset: 0,
                transactionsOffset: 0,
                transactionsStatistic: undefined,
                classesStatictic: undefined,
                transactions: [],
                classes: [],
                numberOfHiddenTransactions: undefined,
                numberOfHiddenClasses: undefined,
            };
        case actionTypes.PIPELINE_SET_EXPANDED:
            return {
                ...state,
                isExpanded: action.expanded,
            };
        case actionTypes.PIPELINE_RESET:
            return initialState;
        case actionTypes.PIPELINE_SET_REDIRECT_FILTER:
            return {
                ...state,
                redirectFilters: action.filter,
            };
        case actionTypes.PIPELINE_EXPORT_LOAD:
            return {
                ...state,
                isExportLoad: action.isLoading,
            };
        case actionTypes.PIPELINE_SET_SYNDICATE_CONTACTS:
            return {
                ...state,
                syndicateContacts: action.contacts ? {
                    arrangerName: action.arrangerName,
                    contacts: sortSyndicateContacts(action.contacts),
                } : null,
            };
        case actionTypes.PIPELINE_SET_ANY_IOIS_EXISTS:
            return {
                ...state,
                anyIOIsExists: action.anyIOIsExists,
            };
        default:
            return state;
    }
};
