import { getType } from "typesafe-actions";
import { settlementAccountActions, TSettlementAccountActionType } from "../../actions/settlement-account.actions";
import { RequestState } from "../../constants/request-state";
import { SettlementAccount } from "../../types/settlement/SettlementAccount";
import { EntityListState } from '../../types/state/entities/EntityListState';

export const settlementAccountInitialState: EntityListState<SettlementAccount> = {
    items: [],
    requestState: RequestState.none
};

export function settlementAccountReducer(
    state = settlementAccountInitialState,
    action: TSettlementAccountActionType): EntityListState<SettlementAccount> {
    switch (action.type) {
        case getType(settlementAccountActions.request):
            return {
                items: [],
                requestState: RequestState.request
            };
        case getType(settlementAccountActions.requestResult):
            return {
                items: applyOrdering(action.payload.settlementAccounts),
                requestState: action.payload.requestState
            };
        case getType(settlementAccountActions.update):
            const { account } = action.payload;
            return {
                ...state,
                items: applyOrdering(state.items.some(i => i.id === account.id)
                    ? state.items.map(i => i.id === account.id ? account : i)
                    : state.items.concat(account))
            }
        case getType(settlementAccountActions.deleteAccount):
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.payload.accountId)
            }
        case getType(settlementAccountActions.reset):
            return { ...settlementAccountInitialState };
        default:
            return state;
    }
}

function applyOrdering(settlementAccounts: SettlementAccount[] = []) {
    return settlementAccounts.sort((a, b) => a.accountName.localeCompare(b.accountName))
}
