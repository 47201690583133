import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { history } from '../history';
import { user } from '../user/user';

const reactPlugin = new ReactPlugin();
const instrumentationKey = process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY;

const ai = new ApplicationInsights({
    config: {
        instrumentationKey,
        extensions: [reactPlugin as unknown as ITelemetryPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history }
        }
    }
});

if (instrumentationKey) {
    ai.loadAppInsights();
    ai.addTelemetryInitializer(item => {

        if (item.baseType === "MessageData") {
            if (item.ext && item.ext.trace) {
                item.ext.trace.name = history.location.pathname;
            }
        }
        
        try {
            const currentUser = user.current();
            if (currentUser) {
                ai.setAuthenticatedUserContext(currentUser.email, undefined, true);
            } else {
                ai.clearAuthenticatedUserContext();
            }
        } catch (e) {
            console.log('Failed to add telemetry data', e);
        }
    });
}

export const appInsights = ai.appInsights;
