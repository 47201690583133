import { AnyAction } from "redux";
import { getType } from "typesafe-actions";
import { bdInventoryActions } from "../../actions/bd-inventory.actions";
import { RequestState } from "../../constants/request-state";
import { isRequestSuccess } from '../../utils';
import { BdInventoryEntityState } from '../../types/state/BdInventoryEntityState';
import { updateInventoryPushReducer } from './update-inventory-push.reducer';
import { user } from "../../user";
import { InventoryPosition } from "../../types/inventory/InventoryPosition";

export const inventoryReducerDefaultState: BdInventoryEntityState = {
    items: [],
    lockModifiedDate: undefined,
    requestState: RequestState.none
};

export function bdInventoryReducer(state: BdInventoryEntityState, action: AnyAction): BdInventoryEntityState {
    switch (action.type) {
        case getType(bdInventoryActions.request):
            return {
                items: [],
                lockModifiedDate: undefined,
                requestState: RequestState.request
            };

        case getType(bdInventoryActions.requestResult):
            return {
                items: action.payload.response.inventorySecurities || [],
                lockModifiedDate: action.payload.response.lockModifiedDate,
                requestState: action.payload.requestState
            };

        case getType(bdInventoryActions.inventoryStatusChange):
            return {
                ...state,
                items: state.items.map(i => i.id === action.payload.inventoryPositionId
                    ? { ...i, status: action.payload.status }
                    : i)
            };
        case getType(bdInventoryActions.pushInventory): {
            const bdCompanyId: number | undefined = user.current()?.companyId;
            const lockModifiedDate: Date | undefined = action.payload.data.lockModifiedDate;
            const inventoryPositions: InventoryPosition[] = action.payload.data.updatedInventorySecurities;

            if (!bdCompanyId || !isRequestSuccess(state.requestState) || !inventoryPositions.some(p => p.dealer.id === bdCompanyId)) {
                return state;
            }

            return {
                ...state,
                items: updateInventoryPushReducer(state.items, inventoryPositions),
                lockModifiedDate
            };
        };
        case getType(bdInventoryActions.resetEntities):
            return { ...inventoryReducerDefaultState };
        default:
            return state
    }
}
