import { getType } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { EntityMapState } from "../../types/state/entities/EntityMapState";
import { latestTransactionActions, TLatestTransactionActionType } from "../../actions/entities/latest-transaction.actions";
import { apiUtils } from "../../utils";
import { TransactionSlim } from "../../types/amr-pipeline/models/TransactionSlim";

export const latestTransactionInitialState: EntityMapState<string, TransactionSlim> = {
    requestState: RequestState.none,
    items: {}
}

export function latestTransactionReducer(
    state = latestTransactionInitialState,
    action: TLatestTransactionActionType): EntityMapState<string, TransactionSlim> {
    switch (action.type) {
        case getType(latestTransactionActions.request):
            return {
                requestState: RequestState.request,
                items: {}
            }
        case getType(latestTransactionActions.response):
            return {
                requestState: action.payload.requestState,
                items: apiUtils.normalize(action.payload.transactions, t => t.dealTicker.toLowerCase(), t => t)
            }
        case getType(latestTransactionActions.reset):
            return { ...latestTransactionInitialState };
        default: return state;
    }
}
