import { InventoryPosition } from '../../types/inventory/InventoryPosition';
import { InventoryPositionStatus } from '../../types/inventory/InventoryPositionStatus';

export function updateInventoryPushReducer(currentInventoryList: InventoryPosition[], inventoryListUpdates: InventoryPosition[]) {
    let inventoryList = [...currentInventoryList];

    inventoryListUpdates.forEach(position => {
        if (position.status === InventoryPositionStatus.obsolete || position.status === InventoryPositionStatus.removed) {
            inventoryList = inventoryList.filter(i => i.id !== position.id);
        } else if (inventoryList.some(i => i.id === position.id)) {
            const index = inventoryList.findIndex(i => i.id === position.id);
            inventoryList.splice(index, 1);
            inventoryList.unshift(position);
        } else {
            inventoryList.unshift(position);
        }
    });

    return inventoryList;
}
