import { appInsights } from "./app-insights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export const logger = {
    exception(exception: Error & { silent?: boolean }, message: string): void {
        if (exception?.silent) return;

        writeToConsole(message, exception);

        if (process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY) {
            try {
                appInsights.trackException({ exception, severityLevel: SeverityLevel.Error }, { message: addFePrefix(message) });
            } catch (e) {
                console.log(e);
            }
        }
    },
    trace(message: string, payload?: any): void {
        writeToConsole(message, payload);

        if (process.env.REACT_APP_APP_INSIGHTS_TRACE_ENABLED &&
            process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY) {
            try {
                appInsights.trackTrace(
                    { message: addFePrefix(message), severityLevel: SeverityLevel.Information },
                    payload && { payload }
                );
            } catch (e) {
                console.log(e);
            }
        }
    }
}

function writeToConsole(message: string, payload?: any) {
    if (process.env.REACT_APP_CONSOLE_LOGGING_ENABLED) {
        console.log(message, payload || '');
    }
}

function addFePrefix(message: string) {
    return 'FE: ' + message;
}
