import { getType } from 'typesafe-actions';
import { dealersActions as actionTypes } from '../constants';
import { dealersActions } from '../actions';
import { ActionType } from '../types/ActionType';
import { DealersState } from '../types/state/DealersState';
import { dealerListFilterReducer, initialDealerListFilter } from './dealer-list-filter.reducer';

const initialState: DealersState = {
    requestToBidRequestState: false,
    filter: { ...initialDealerListFilter },
    confirmRequestToBidPopup: {
        visible: false,
        dealerId: 0
    }
};

export const dealers = (state = initialState, action: ActionType<any>): DealersState => {
    switch (action.type) {
        case actionTypes.DEALERS_SHOW_CONFIRM_BID_REQUEST_POPUP:
            return {
                ...state,
                confirmRequestToBidPopup: {
                    visible: true,
                    dealerId: action.payload.dealerId || 0
                }
            };
        case actionTypes.DEALERS_HIDE_CONFIRM_BID_REQUEST_POPUP:
            return {
                ...state,
                confirmRequestToBidPopup: { ...initialState.confirmRequestToBidPopup }
            };
        case actionTypes.DEALERS_REQUEST_TO_BID_SET_REQUEST_STATUS:
            return { ...state, requestToBidRequestState: action.payload.status };

        case actionTypes.DEALERS_RESET_STATE:
            return { ...initialState };
        case getType(dealersActions.setSearchTerm):
        case getType(dealersActions.updateFilterOptions):
        case getType(dealersActions.clearFilterOptions):
            return {
                ...state,
                filter: dealerListFilterReducer(state.filter, action)
            };
        default:
            return state;
    }
};
