import { AnyAction } from "redux";
import { ImDetailsDealsState } from "../types/state/ImDetailsDealsState";
import { imDetailsDealsActions as actionTypes } from "../constants";
import { AmrClass } from "../types/amr-pipeline/models/AmrClass";

const initialState: ImDetailsDealsState = {
    loadedDealReferenceNames: [],
    isDealsRequesting: false,
    isDealDetailsRequesting: false,
    isTrancheDetailsRequesting: false
}

export const imDetailsDeals = (state: ImDetailsDealsState = initialState, action: AnyAction): ImDetailsDealsState => {
    switch (action.type) {
        case actionTypes.DEALS_RESET:
            return initialState;
        case actionTypes.DEALS_REQUESTING_STATE:
            return {
                ...state,
                isDealsRequesting: action.isRequesting
            };
        case actionTypes.DEAL_DETAILS_REQUESTING_STATE:
            return {
                ...state,
                isDealDetailsRequesting: action.isRequesting
            };
        case actionTypes.DEALS_TRANCHE_DETAILS_REQUESTUNG_STATE:
            return {
                ...state,
                isTrancheDetailsRequesting: action.isRequesting
            };
        case actionTypes.DEALS_STORE_DEALS:
            return {
                ...state,
                deals: action.deals
            };
        case actionTypes.DEALS_STORE_ACTUAL_AMR_CLASSES:
            return {
                ...state,
                loadedDealReferenceNames: state.loadedDealReferenceNames.concat(action.dealReferenceName),
                deals: state.deals?.map(d => d.referenceName === action.dealReferenceName
                    ? {
                        ...d,
                        classes: d.classes.map(c => ({
                            ...c,
                            actualAuction: action.amrClasses.find((a: AmrClass) => a.referenceName === c.referenceName)
                        })),
                    }
                    : d)
            };
        case actionTypes.DEALS_STORE_AMR_CLASSES:
            return {
                ...state,
                deals: state.deals?.map(d => d.referenceName === action.dealReferenceName
                    ? {
                        ...d,
                        classes: d.classes.map(c => c.referenceName === action.classReferenceName
                            ? { ...c, auctions: action.amrClasses } : c)
                    }
                    : d)
            };
        case actionTypes.DEALS_STORE_DOCUMENTS:
            return {
                ...state,
                deals: state.deals?.map(d => d.referenceName === action.dealReferenceName
                    ? {
                        ...d,
                        documents: action.documents
                    }
                    : d)
            };
        case actionTypes.DEALS_STORE_DOCUMENTS_SEARCH_RESULTS:
            return {
                ...state,
                deals: state.deals?.map(d => d.referenceName === action.dealReferenceName
                    ? {
                        ...d,
                        filteredDocuments: action.filteredDocuments
                    }
                    : d)
            };
        case actionTypes.DEALS_RESET_DOCUMENTS_SEARCH:
            return {
                ...state,
                deals: state.deals?.map(d =>
                    ({
                        ...d,
                        filteredDocuments: undefined
                    }))
            };
        default:
            return state;
    }
}
