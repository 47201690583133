import { ActionType, getType } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { EntityListState } from '../../types/state/entities/EntityListState';
import { cloSystemInfrastructureActions } from '../../actions/entities/cloSystemInfrastructure';
import { CloSystemInfrastructures } from '../../types/clo-managers/CloSystemInfrastructures';

export const cloSystemInfrastructureDefaultState: EntityListState<CloSystemInfrastructures> = {
    items: [],
    requestState: RequestState.none,
};

export type cloSystemInfrastructureActionTypes = ActionType<
    | typeof cloSystemInfrastructureActions.requestData
    | typeof cloSystemInfrastructureActions.responseData
>;

export function cloSystemInfrastructureReducer(
    state = cloSystemInfrastructureDefaultState,
    action: cloSystemInfrastructureActionTypes,
): EntityListState<CloSystemInfrastructures> {
    switch (action.type) {
        case getType(cloSystemInfrastructureActions.requestData):
            return {
                ...state,
                requestState: action.payload.requestState,
            };
        case getType(cloSystemInfrastructureActions.responseData):
            return {
                ...state,
                items: action.payload.cloSystemInfrastructure,
                requestState: action.payload.requestState,
            };
        default:
            return state;
    }
}
