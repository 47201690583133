import { ActionType, getType } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { bwicLogActions } from '../actions/bwic-log.actions';
import { BwicLogState } from '../types/state/BwicLogState';

const initialState: BwicLogState = {
    security: null,
    initRequestState: RequestState.none,
    exportDataRequestState: RequestState.none,
    securityBwicStatistics: { count: 0, securities: [] }
};

export const bwicLogReducer = (
    state = initialState,
    action: ActionType<typeof bwicLogActions[keyof typeof bwicLogActions]>): BwicLogState => {
    switch (action.type) {
        case getType(bwicLogActions.bwicLogInitRequest):
            return {
                ...initialState,
                initRequestState: RequestState.request,
                security: action.payload.security
            };
        case getType(bwicLogActions.bwicLogInitSuccess):
            return {
                ...state,
                initRequestState: RequestState.success,
                securityBwicStatistics: action.payload.statistics,
            };
        case getType(bwicLogActions.bwicLogInitFailure):
            return {
                ...state,
                initRequestState: RequestState.failure
            };
        case getType(bwicLogActions.bwicLogExportDataRequest):
            return {
                ...state,
                exportDataRequestState: RequestState.request
            };
        case getType(bwicLogActions.bwicLogExportDataSuccess):
            return {
                ...state,
                exportDataRequestState: RequestState.success
            };
        case getType(bwicLogActions.bwicLogExportDataFailure):
            return {
                ...state,
                exportDataRequestState: RequestState.failure
            };
        case getType(bwicLogActions.bwicLogReset):
            return { ...initialState };
        default:
            return state;
    }
};
