import { AnyAction } from 'redux';
import { CompanyDetailsState } from '../types/state/CompanyDetailsState';
import { companyDetailsActions } from '../actions/company-details.actions';
import { getType } from 'typesafe-actions';

const initialState: CompanyDetailsState = {
    isPanelVisible: false,
    isBrokerDealer: false,
    rowKey: 0,
    currentCompanyId: 0,
    companies: {},
    isRequestingCompany: {},
};

export const companyDetails = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case getType(companyDetailsActions.show):
            return {
                ...state,
                isPanelVisible: !!action.payload.company,
                currentCompanyId: action.payload.company.id,
                isBrokerDealer: action.payload.isBrokerDealer,
                rowKey: action.payload.rowKey
            };
        case getType(companyDetailsActions.hide):
            return {
                ...state,
                isPanelVisible: false,
                currentCompanyId: 0,
                isBrokerDealer: false,
                rowKey: 0
            };
        case getType(companyDetailsActions.fetchCompanyDetailsRequest):
            return {
                ...state,
                isRequestingCompany: {
                    ...state.isRequestingCompany,
                    [action.payload.companyId]: true
                }
            };
        case getType(companyDetailsActions.fetchCompanyDetailsResponse):
            const isRequestingCompany = { ...state.isRequestingCompany };
            delete isRequestingCompany[action.payload.companyId];
            return {
                ...state,
                isRequestingCompany
            };
        case getType(companyDetailsActions.setCompanyDetails):
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.payload.companyId]: action.payload.company
                }
            };
        case getType(companyDetailsActions.reset):
            return { ...initialState };
        default:
            return state;
    }
};
