import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { ClientsCompaniesState } from '../types/state/ClientsCompaniesState';
import { clientsCompaniesActions } from '../actions/clients-companies.actions';
import { settlementAgentAgeementPushActions } from '../actions/settlement-agent-agreement-push.actions';
import { SettlementAgentClientAgreement } from '../types/bid-as-dealer/SettlementAgentClientAgreement';
import { user } from '../user';

const initialState: ClientsCompaniesState = {
    selectedCompanyForAffirm: null,
    companiesList: [],
    requestStateFetchClientsList: RequestState.none,
    requestStatusUpdateRequest: {},
    searchString: '',
    pendingAgreementCount: 0,
    requestStateFetchPendingAgreementCount: RequestState.none,
    currencyRate: [],
    requestStateGetCurrencyRate: RequestState.none,
    requestStateUpdateCurrencyRate: RequestState.none,
    visibleSetRatePopup: false,
};

export const clientsCompanies = (state = initialState, action: AnyAction): ClientsCompaniesState => {
    switch (action.type) {
        case getType(clientsCompaniesActions.fetchListRequest):
            return { ...state, requestStateFetchClientsList: RequestState.request };
        case getType(clientsCompaniesActions.fetchListSuccess):
            return { ...state, requestStateFetchClientsList: RequestState.success, companiesList: action.payload.data };
        case getType(clientsCompaniesActions.fetchListFailure):
            return { ...state, requestStateFetchClientsList: RequestState.failure };
        case getType(clientsCompaniesActions.fetchPendingAgreementCountRequest):
            return { ...state, requestStateFetchPendingAgreementCount: RequestState.request };
        case getType(clientsCompaniesActions.fetchPendingAgreementsCountSuccess):
            return {
                ...state,
                pendingAgreementCount: action.payload.count,
                requestStateFetchPendingAgreementCount: RequestState.success
            };
        case getType(clientsCompaniesActions.fetchPendingAgreementsCountFailure):
            return { ...state, requestStateFetchPendingAgreementCount: RequestState.failure };
        case getType(clientsCompaniesActions.setSelectedAgreement):
            return { ...state, selectedCompanyForAffirm: action.payload.companyId };
        case getType(clientsCompaniesActions.resetSelectedAgreement):
            return { ...state, selectedCompanyForAffirm: null };
        case getType(clientsCompaniesActions.updateAgreementRequest):
            return { ...state, requestStatusUpdateRequest: { ...state.requestStatusUpdateRequest, [action.payload.requestData.assetManagerId]: RequestState.request }};
        case getType(clientsCompaniesActions.updateAgreementSuccess):
            return {
                ...state,
                requestStatusUpdateRequest: { ...state.requestStatusUpdateRequest, [action.payload.assetManagerId]: RequestState.success},
                selectedCompanyForAffirm: null
            };
        case getType(clientsCompaniesActions.updateAgreementFailure):
            return { ...state, requestStatusUpdateRequest: { ...state.requestStatusUpdateRequest, [action.payload.assetManagerId]: RequestState.failure} };
        case getType(settlementAgentAgeementPushActions.settlementAgentAgreementPushReceived):
            return { ...state, companiesList: updateCompaniesList([...state.companiesList], action.payload.data) };
        case getType(clientsCompaniesActions.setSearchString):
            return { ...state, searchString: action.payload.searchString };
        case getType(clientsCompaniesActions.reset):
            return {
                ...initialState,
                searchString: state.searchString
            };
        case getType(clientsCompaniesActions.setVisibleUpdateRatePopup):
            return { ...state, visibleSetRatePopup: action.payload.visible };

        case getType(clientsCompaniesActions.getCurrencyRate):
            return { ...state, requestStateGetCurrencyRate: RequestState.request, };
        case getType(clientsCompaniesActions.setResultGetCurrencyRate):
            return {
                ...state,
                requestStateGetCurrencyRate: action.payload.requestState,
                currencyRate: action.payload.rate
            };
        case getType(clientsCompaniesActions.updateCurrencyRate):
            return { ...state, requestStateUpdateCurrencyRate: RequestState.request };
        case getType(clientsCompaniesActions.setResultUpdateCurrencyRate):
            return {
                ...state,
                requestStateUpdateCurrencyRate: action.payload.requestState,
                currencyRate: action.payload.rate
                    ? state.currencyRate.map((rate) =>
                        rate.code === action.payload.rate.code
                            ? action.payload.rate
                            : rate
                    )
                    : state.currencyRate
            };
        default:
            return state;
    }
};

function updateCompaniesList(companiesList: SettlementAgentClientAgreement[], data: SettlementAgentClientAgreement) {
    if (!user.current()?.isClearingBank) {
        return companiesList;
    }
    if (companiesList.some((c) => c.signatory.id === data.signatory.id)) {
        return companiesList.map(c => c.signatory.id === data.signatory.id ? {
            ...data,
            contacts: c.contacts
        } : c)
    }

    return companiesList.concat(data);
}
